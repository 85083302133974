export class ProcessingRegion { 
  RegionName: string;
  RegionDescription: string;
  DefaultSearchRegions: ProcessingRegion[]
  IsSelected: boolean;

  constructor(obj: Object){
    this.RegionName = obj['regionName'];
    this.RegionDescription = obj['regionDescription'];
    this.DefaultSearchRegions = obj['defaultSearchRegions'].map(def => new ProcessingRegion(def)) as Array<ProcessingRegion>;
  }

  static fromJSONArray(array: Array<ProcessingRegion>) : Array<ProcessingRegion> {
    let result =  array.map(obj => new ProcessingRegion(obj)) as Array<ProcessingRegion>;
    return result ? result : []
  }
}
