<!DOCTYPE html>
<div class="router-outlet">
  <h1>Welcome to the Compliance Portal</h1>
  <p class="lead">
    <span *ngIf="!getIsUserAuthenticated()">Log in using the header (i.e. 
      <mat-icon mat-list-icon style="font-size:18px; height:18px; width: 18px;">person</mat-icon>) and then click
    </span>
    <span *ngIf="getIsUserAuthenticated()">
      Click
    </span> on a menu item to get started.
  </p>
</div>