<div *ngIf="locations" class="ip-details container">
    <mat-table id="ip-lookup-details-table" [dataSource]="locations">
        <ng-container matColumnDef="timestamp">
            <mat-header-cell *matHeaderCellDef> Timestamp </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-lookup-data"> {{ row.timestamp | date:'M/d/yyyy hh:mm a ZZ' }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="ip">
            <mat-header-cell *matHeaderCellDef> IP </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-lookup-data"> {{ row.ip }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="city">
            <mat-header-cell *matHeaderCellDef> City </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-lookup-data"> {{ row.city }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="state">
            <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-lookup-data"> {{ row.state }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="country">
            <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-lookup-data"> {{ row.country }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="connectionType">
            <mat-header-cell *matHeaderCellDef> Connection Type </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-lookup-data"> {{ row.connectionType }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="organization">
            <mat-header-cell *matHeaderCellDef> Organization </mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-lookup-data"> {{ row.organization }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'highlight-ip-row': row.highlightIp}"></mat-row>
    </mat-table>
</div>
<mat-progress-spinner *ngIf="!locations" mode="indeterminate">
</mat-progress-spinner>