import { Injectable, Injector, NgModule} from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AiLoggingService } from '../services/ai-logging.service';

@Injectable()
export class AiLoggingInterceptor implements HttpInterceptor {

    constructor (private injector: Injector) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const logger = this.injector.get(AiLoggingService);
        let request = req.clone();

        if (logger.operationId != null)
        {
            request = req.clone({
                headers: req.headers.append('operation-id', logger.operationId)
            });
        }

        logger.operationName = request.method + " " + request.url;

        return next.handle(request);
    }
}
