<div class="collection-point-page">
    <div class="grid-container">
        <div class="item-label">CRM Request:</div>
        <div class="item-content">{{requestId}}</div>

        <div class="item-spinner" *ngIf="isLoading | async">
            <div class="spinner">
                <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
            </div>
            <div class="spinner-text">Fetching collection point info ...</div>
        </div>

        <div class="item-error" *ngIf="isError | async">
            <div class="error-message">{{errorMessage}}</div>
        </div>
    </div>
    <div class="padding-top">
        <ng-container *ngIf="isDataReady | async">
            <mat-table [dataSource]="collectionPoints">
                <ng-container matColumnDef="Region">
                    <mat-header-cell *matHeaderCellDef> Region </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.Region }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="DataExists">
                    <mat-header-cell *matHeaderCellDef> Data Exists (Yes/No) </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.DataExists ? 'Yes' : 'No' }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="LocationUri">
                    <mat-header-cell *matHeaderCellDef> Collection Point </mat-header-cell>
                    <mat-cell *matCellDef="let row"> {{ row.LocationUri }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-raised-button color="primary" (click)="copySasUrl(row)" [disabled]="!(isDataReady | async) || (isLoading | async)">Copy SAS Token</button>
                        <button mat-button color="primary" (click)="showSasTokenDialog(row)" [disabled]="!(isDataReady | async) || (isLoading | async)">View Token</button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="collectionPointDisplayColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: collectionPointDisplayColumns;"></mat-row>
            </mat-table>
            <div class="no-results" *ngIf="collectionPoints == null || collectionPoints.length === 0">No collection points found for this CRM request</div>

            <div class="notice" *ngIf="collectionPoints && collectionPoints.length > 0">Tokens will expire in 48 hours</div>

            <div class="grid-container padding-top">     
                <div class="item-info">
                    See <a
                            href="https://github.com/MicrosoftDocs/azure-docs/blob/master/articles/vs-azure-tools-storage-manage-with-storage-explorer.md#attach-a-service-by-using-a-shared-access-signature-sas"
                            target="_blank">this link</a> for information on how to connect to the Collection Point using Azure
                        Storage Explorer with the generated SAS token.
                </div>
            </div>
        </ng-container>
    </div>
</div>