import { AbstractControl, ValidationErrors } from '@angular/forms';

import { Moment } from 'moment-timezone';
import * as moment from 'moment-timezone'

export class MomentValidators {
    static validTime(control: AbstractControl) : ValidationErrors | null {           
        if ( !moment(control.value as string, "HH:mm:ss").isValid()) {
            return { validTime: false }
        }

        return null
    }
}