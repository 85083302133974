<!DOCTYPE html>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="side" opened="true">
      <mat-nav-list>
        <app-nav-item routerLink="/" icon="home" hint="">
          Home
        </app-nav-item>
        <div *ngFor="let menuItem of menuItems">
          <app-nav-item 
          class="isAuthAndInternal" 
          *ngIf="userIsAuthenticated && !menuItem.isExternalSite && showInternalLink(menuItem)" 
          icon="{{menuItem.icon}}"
          routerLink="{{menuItem.route}}" [routerLinkActive]="['link-active']">
            {{ menuItem.title }}
          </app-nav-item>
        </div>
        <mat-divider></mat-divider>
        <h3 matSubheader>External Websites</h3>
        <app-nav-item 
        *ngIf="!userIsAuthenticated" 
        routerLink="" icon="block" hint="There are no external websites">
          None
        </app-nav-item>
        <div *ngFor="let menuItem of menuItems">
          <app-nav-item 
          class="isAuthAndExternal" 
          *ngIf="userIsAuthenticated && menuItem.isExternalSite" 
          icon="{{menuItem.icon}}"
            externalLink="{{menuItem.route}}">
            {{ menuItem.title }}
          </app-nav-item>
        </div>
      </mat-nav-list>
    </mat-sidenav>
      <ng-content></ng-content>
  </mat-sidenav-container>