<form [formGroup]="form">

    <h2>Date Range</h2>
    <div class="datetimes">

        <div class="datetime" *ngIf="hasStart()">
            <mat-form-field class="datetime-input-width">
                <input matInput [matDatepicker]="startDateControl" placeholder="Start date" formControlName="startDate" [min]="minDate" [max]="maxDate" (dateChange)="mergeStartDatetime()">
                <mat-datepicker-toggle matSuffix [for]="startDateControl"></mat-datepicker-toggle>
                <mat-datepicker #startDateControl></mat-datepicker>
            </mat-form-field>

            <button class="app-icon-button" title="Reset" (click)="resetStartDate()">
        <i class="material-icons">refresh</i>
      </button>
        </div>

        <div class="datetime" *ngIf="hasEnd()">
            <mat-form-field class="datetime-input-width">
                <input matInput [matDatepicker]="endDateControl" placeholder="End date" formControlName="endDate" [min]="minDate" [max]="maxDate" (dateChange)="mergeEndDatetime()">
                <mat-datepicker-toggle matSuffix [for]="endDateControl"></mat-datepicker-toggle>
                <mat-datepicker #endDateControl></mat-datepicker>
            </mat-form-field>

            <button class="app-icon-button" title="Reset" (click)="resetEndDate()">
        <i class="material-icons">refresh</i>
      </button>
        </div>
    </div>

    <div class="datetimes">
        <div class="datetime" *ngIf="hasStart()">
            <mat-form-field class="datetime-input-width">
                <input matInput placeholder="Start time" formControlName="startTime" (change)="mergeStartDatetime()">
            </mat-form-field>

            <button class="app-icon-button" title="Reset" (click)="resetStartTime()">
        <i class="material-icons">refresh</i>
      </button>
        </div>

        <div class="datetime" *ngIf="hasEnd()">
            <mat-form-field class="datetime-input-width">
                <input matInput placeholder="End time" formControlName="endTime" (change)="mergeEndDatetime()">
            </mat-form-field>

            <button class="app-icon-button" title="Reset" (click)="resetEndTime()">
        <i class="material-icons">refresh</i>
      </button>
        </div>
    </div>

    <mat-form-field class="datetime-input-width">
        <mat-select disabled="true" value="GMT (Greenwich) UTC" name="timezone" placeholder="Timezone">
            <mat-option value="GMT (Greenwich) UTC">GMT (Greenwich) UTC</mat-option>
        </mat-select>
    </mat-form-field>

    <div class="error-block">
        <mat-error *ngIf="form.get('startDate').touched && form.get('startDate').hasError('required')">Start date is required.</mat-error>
        <mat-error *ngIf="form.get('endDate').touched && form.get('endDate').hasError('required')">End date is required.</mat-error>
        <mat-error *ngIf="form.hasError('invalidDates')">Please select valid dates. The start date must be before the end date.</mat-error>
    </div>
</form>