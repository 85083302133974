import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, takeUntil, tap } from 'rxjs/operators';
import { CollectionPointWithSas } from 'src/app/models/collection-point.model';
import { CollectionPointService } from 'src/app/services/collection-point.service';
import { DialogComponent } from '../../components/dialog/dialog.component';

@Component({
  selector: 'collection-point',
  templateUrl: './collection-point.component.html',
  styleUrls: ['./collection-point.component.scss']
})
export class CollectionPointComponent implements OnInit, OnDestroy {
    public loadingState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public dataReadyState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public errorState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public isLoading: Observable<boolean> = this.loadingState.asObservable();
    public isDataReady: Observable<boolean> = this.dataReadyState.asObservable();
    public isError: Observable<boolean> = this.errorState.asObservable();

    public collectionPointDisplayColumns = ['Region', 'DataExists', 'LocationUri', 'Action'];

    public errorMessage: string;
    public requestId: string;
    public collectionPoints: CollectionPointWithSas[];
    private ngUnsub: Subject<void> = new Subject<void>();

    constructor(
        private router: ActivatedRoute,
        private collectionPointService: CollectionPointService,
        private cbService: ClipboardService,
        private dialog: MatDialog) {
    }

    ngOnInit() {
        this.requestId = this.router.snapshot.queryParamMap.get('requestId');

        if (!this.requestId || this.requestId === '') {
            this.errorState.next(true);
            this.errorMessage = 'CRM Request Id is not valid';
        } else {
            this.getCollectionPointInfo();
        }
    }

    ngOnDestroy() {
        this.ngUnsub.next();
        this.ngUnsub.complete();
    }

    getCollectionPointInfo() {
        this.loadingState.next(true);

        this.collectionPointService
            .getCollectionPointInfo(this.requestId)
            .pipe(
                takeUntil(this.ngUnsub),
                tap(cpt => {
                    this.collectionPoints = cpt;
                    this.dataReadyState.next(true);
                }),
                catchError(err =>  {
                    this.errorMessage = `Oh oh ... we\'re unable to create SAS Tokens`
                    console.log(err);
                    this.errorState.next(true);
                    return of([]);
                }),
                finalize(() => {
                    this.loadingState.next(false);
                })
            )
            .subscribe();
    }

    copySasUrl(collectionPoint: CollectionPointWithSas) {
        this.cbService.copyFromContent(collectionPoint.LocationUri + collectionPoint.SasToken);
    }

    showSasTokenDialog(collectionPoint: CollectionPointWithSas) {
        this.dialog.open(DialogComponent, {
            width: 'flex',
            data: `${collectionPoint.LocationUri}${collectionPoint.SasToken}`
        })
    }
}
