import { Injectable } from '@angular/core';
import { IpLocation } from '../models/ip-location.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { catchError, map } from 'rxjs/operators';
import { IpLogin } from 'src/app/models/ip-login.model';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
@Injectable()
export class IpLookupService {

  constructor(protected baseService: BaseService) {}

  GetIpLocations(ips: IpLogin[]): Observable<IpLocation[]> {
    return this.baseService
      .post(environment.webApiConfig.serverUrl + environment.ipLookupUrl, ips)
      .pipe(
        map(response => IpLocation.fromJSONArray(response)),
        catchError(error => this.baseService.handleError(error))
      );
  }
}
