import { DataField } from './data-field.model';

export class Capability { 
  displayName: string;
  serverName: string;
  isSelected: boolean;
  isDisabled: boolean;
  children: Capability[];

  constructor(obj: Object){
    this.displayName = obj['DisplayName'];
    this.serverName = obj['FieldName'];
    this.isSelected = obj['IsSelected'];
    this.isDisabled = obj['IsDisabled'];
    this.children = obj['Children'];
  }

  static fromJSONArray(array: Array<Capability>) : Array<Capability> {
    let result =  array.map(obj => new Capability(obj)) as Array<Capability>;
    return result ? result : []
  }
}
