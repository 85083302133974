<!DOCTYPE html>
<app-layout>
  <div fxLayout="row">
    <mat-toolbar color="primary" layout="row">
      <div fxFlex="33.33%" fxLayoutAlign="start center">
        <img class="center-block" src="../assets/images/microsoft.png" />
      </div>

      <div fxFlex="33.33%" fxLayoutAlign="center center">
        <span>Compliance Portal</span>
      </div>

      <div fxFlex="33.33%" fxLayoutAlign="end center">
        <mat-menu #alertMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">
          <button mat-menu-item *ngIf="!getIsUserAuthenticated()">
            <span>No alerts</span>
          </button>
          <button mat-menu-item *ngIf="getIsUserAuthenticated()">
            <span>No alerts</span>
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="alertMenu">
          <mat-icon>notifications_none</mat-icon>
        </button>

        <mat-menu #loginMenu="matMenu" xPosition="before" [overlapTrigger]="false">
          <button mat-menu-item class="login-menu" *ngIf="getIsUserAuthenticated()" (click)="logOut()">
            <mat-icon>exit_to_app</mat-icon>
            <span>Sign out</span>
          </button>
        </mat-menu>

        <!--<button mat-icon-button [matMenuTriggerFor]="loginMenu">-->
        <button mat-button class="current-user-button" [matMenuTriggerFor]="loginMenu">
          {{fullName$ | async}} <mat-icon>person</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </div>
  <app-sidenav [menuItems]="menuItems$ | async" [userIsAuthenticated]="getIsUserAuthenticated()">
    <router-outlet></router-outlet>
  </app-sidenav>
</app-layout>
