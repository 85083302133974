export const emailIdentifier = (identifier: string): boolean => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(identifier);
}

export const skypeLiveIdentifier = (identifier: string): boolean => {
    return /^live\:\S+$/.test(identifier);
}

export const phoneNumberIdentifier = (identifier: string): boolean => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(identifier);
}