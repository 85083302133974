<mat-dialog-content id="review">
    <ng-container *ngIf="!isDataLoaded">
        <div class="loadingMessage">Please stand by, loading data...<span class="material-icons spin" *ngIf="!isDataLoaded">autorenew</span></div>
    </ng-container>
    <ng-container *ngIf="isDataLoaded">
        <div id="review-header">
            <fa-icon id="close-box" matDialogClose [icon]="faWindowClose" size="2x" pull="right" (click)="closeDialog()"></fa-icon>
            <h2>ID: {{reqStatusSummary.id}}</h2>
            <h4>Delivered: {{preservationReviewData?.countTotalOutputBlobs | number:'1.0-2'}} individual files for a total of {{preservationReviewData?.validationContainerSizeInBytes | number:'1.0-2'}} bytes (prior to compression)</h4>
        </div>
        <div>
            <div class="mat-table">
                <div class="mat-header-row">
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">Preservation Region</div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Requested</div>
                    <div class="mat-cell">
                        {{processingRegionEnum[preservationReviewData?.requestedProcessingRegion]}}
                    </div>
                </div>
            </div>
            Cells with a <span class="region-mismatch">yellow background</span> indicate a disparity between the requested and delivered region(s). <br/> This does not necessarily indicate an error - for example, one or more preservation regions may contain
            no data for a given identifier.
        </div>
        <br />
        <br />
        <div>
            <div class="mat-table">
                <div class="mat-header-row">
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell lens-date">Start Date (UTC)</div>
                    <div class="mat-header-cell lens-date">End Date (UTC)</div>
                    <div class="mat-header-cell">Full Content</div>
                    <div class="mat-header-cell">Calendars</div>
                    <div class="mat-header-cell">Contacts</div>
                    <div class="mat-header-cell">Mailbox IP</div>
                    <div class="mat-header-cell">Mobile Device</div>
                    <div class="mat-header-cell">Registration</div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Requested</div>
                    <div class="mat-cell lens-date">
                        {{preservationReviewData?.requestedTimestampStart | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <div class="mat-cell lens-date">
                        {{preservationReviewData?.requestedTimeStampEnd | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedEmailContentLevel == emailContentType.FullMessageContent">done</span>
                        <span class="material-icons not-requested" *ngIf="preservationReviewData?.requestedEmailContentLevel != emailContentType.FullMessageContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedCalendarContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedCalendarContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedContactContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedContactContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedMailboxLoginContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedMailboxLoginContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedMobileDeviceContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedMobileDeviceContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedRegistrationContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedRegistrationContent">highlight_off</span>
                    </div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Delivered</div>
                    <div class="mat-cell lens-date" [ngClass]="{'date-mismatch': preservationReviewData?.requestedTimestampStart !== preservationReviewData?.outputTimestampEarliest}">
                        {{preservationReviewData?.outputTimestampEarliest | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <div class="mat-cell lens-date" [ngClass]="{'date-mismatch': preservationReviewData?.requestedTimeStampEnd !== preservationReviewData?.outputTimestampLatest}">
                        {{preservationReviewData?.outputTimestampLatest | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <ng-container *ngIf="!preservationReviewData?.outputHasMismatchedEmailContentLevels">
                        <div class="mat-cell">
                            <span class="requested-delivered" *ngIf="preservationReviewData?.requestedEmailContentLevel == emailContentType.FullMessageContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Messages]]}}</span>
                            <span class="material-icons not-requested-not-delivered" *ngIf="preservationReviewData?.requestedEmailContentLevel != emailContentType.FullMessageContent">not_interested</span>
                        </div>
                    </ng-container>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasCalendarContent && preservationReviewData.requestedCalendarContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Calendar]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasCalendarContent && preservationReviewData.requestedCalendarContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasCalendarContent && !preservationReviewData.requestedCalendarContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasCalendarContent && !preservationReviewData.requestedCalendarContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Calendar]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Calendar]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasContactContent && preservationReviewData.requestedContactContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Contacts]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasContactContent && preservationReviewData.requestedContactContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasContactContent && !preservationReviewData.requestedContactContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasContactContent && !preservationReviewData.requestedContactContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Contacts]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Contacts]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasMailboxLoginContent && preservationReviewData.requestedMailboxLoginContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MailboxLogin]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasMailboxLoginContent && preservationReviewData.requestedMailboxLoginContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasMailboxLoginContent && !preservationReviewData.requestedMailboxLoginContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasMailboxLoginContent && !preservationReviewData.requestedMailboxLoginContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MailboxLogin]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MailboxLogin]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasMobileDeviceContent && preservationReviewData.requestedMobileDeviceContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MobileDevice]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasMobileDeviceContent && preservationReviewData.requestedMobileDeviceContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasMobileDeviceContent && !preservationReviewData.requestedMobileDeviceContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasMobileDeviceContent && !preservationReviewData.requestedMobileDeviceContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MobileDevice]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MobileDevice]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasRegistrationContent && preservationReviewData.requestedRegistrationContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Registration]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasRegistrationContent && preservationReviewData.requestedRegistrationContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasRegistrationContent && !preservationReviewData.requestedRegistrationContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasRegistrationContent && !preservationReviewData.requestedRegistrationContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Registration]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Registration]]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div>
            <div class="mat-table">
                <div class="mat-header-row">
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">Conversation History</div>
                    <div class="mat-header-cell">Journal</div>
                    <div class="mat-header-cell">Notes</div>
                    <div class="mat-header-cell">Tasks</div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Requested</div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedConversationHistoryContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedConversationHistoryContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedJournalContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedJournalContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedNotesContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedNotesContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="preservationReviewData?.requestedTasksContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!preservationReviewData?.requestedTasksContent">highlight_off</span>
                    </div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Delivered</div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasConversationHistoryContent && preservationReviewData.requestedConversationHistoryContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.IM]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasConversationHistoryContent && preservationReviewData.requestedConversationHistoryContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasConversationHistoryContent && !preservationReviewData.requestedConversationHistoryContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasConversationHistoryContent && !preservationReviewData.requestedConversationHistoryContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.IM]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.IM]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasJournalContent && preservationReviewData.requestedJournalContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Journal]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasJournalContent && preservationReviewData.requestedJournalContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasJournalContent && !preservationReviewData.requestedJournalContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasJournalContent && !preservationReviewData.requestedJournalContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Journal]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Journal]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasNotesContent && preservationReviewData.requestedNotesContent">{{(preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Posts]]*1) + (preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Notes]]*1)}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasNotesContent && preservationReviewData.requestedNotesContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasNotesContent && !preservationReviewData.requestedNotesContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasNotesContent && !preservationReviewData.requestedNotesContent && (preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Posts]] > 0 || preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Notes]] > 0)">{{(preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Posts]]*1) + (preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Notes]]*1)}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="preservationReviewData?.outputHasTasksContent && preservationReviewData.requestedTasksContent">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Tasks]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!preservationReviewData?.outputHasTasksContent && preservationReviewData.requestedTasksContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!preservationReviewData?.outputHasTasksContent && !preservationReviewData.requestedTasksContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="preservationReviewData?.outputHasTasksContent && !preservationReviewData.requestedTasksContent && preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Tasks]] > 0">{{preservationReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Tasks]]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Table Legend
                    </mat-panel-title>
                    <mat-panel-description>
                        View a list of symbols, colors, highlights, and their meaning
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-grid-list cols="4" rowHeight="75px">
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons">done</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability was requested in the Search Request.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons not-requested">highlight_off</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability was not requested in the Search Request.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="date-mismatch">MM/DD/YY</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Delivered date does not match the requested date. This may not be an error - for example, no content may exist before or after a subset of the requested
                        date range.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="email-level-mismatch">10</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>At least one delivered message does not match the requested level of detail (e.g., full content). Number indicates the total number of delivered
                        messages.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span>MM/DD/YY</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Delivered date matches the requested date.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons not-requested-not-delivered">not_interested</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>This capability or level of message detail was not requested and was not delivered.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons requested-not-delivered">warning</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>At least one delivered message does not match the requested level of detail (e.g., full content).
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="requested-delivered">10</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability or level of message detail was requested and delivered. Number indicates the total number of delivered items for the capability or level
                        of message detail.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons requested-not-delivered">error_outline</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability was requested but not delivered.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="delivered-not-requested">10</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>A non-zero number of items from this capability were delivered, but they were not requested and should not have been delivered.
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-expansion-panel>
        </mat-accordion>
        <br/>
        <button mat-button color="primary" (click)="generateSasUrl()" [disabled]="isGenerating || !isDataLoaded || reqStatusSummary?.currentStatus == 'Published'">Click to generate SAS token (valid for 1h)</button>
        <span class="material-icons spin" *ngIf="isGenerating">autorenew</span>
        <span class="material-icons" *ngIf="isGenerated">done</span>
        <br/>
        <button mat-button color="primary" (click)="copySasUrl()" [disabled]="!isGenerated || isGenerating || reqStatusSummary?.currentStatus == 'Published'">Click to copy the SAS token to your clipboard</button>
        <h5>See <a href="https://github.com/MicrosoftDocs/azure-docs/blob/master/articles/vs-azure-tools-storage-manage-with-storage-explorer.md#attach-a-service-by-using-a-shared-access-signature-sas" target="_blank">this link</a> for information on how
            to connect to the Validation Container using Azure Storage Explorer with the generated SAS token.</h5>
    </ng-container>
</mat-dialog-content>
