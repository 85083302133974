<mat-progress-spinner *ngIf="_capabilities?.length < 1" mode="indeterminate">
</mat-progress-spinner>
<div *ngIf="_capabilities?.length > 0">

    <capabilities [parent]="form" [capabilities]="_capabilities" [nestedCapabilities]="hasFilters" [selectedMsProperty]="_selectedMsProperty"
    [selectedRequestType]="selectedRequestType">
    </capabilities>
    <div *ngIf="hasFilters">
        <mat-tab-group>       
            <div *ngFor="let capability of capabilitiesInForm">
                <mat-tab class="PascalCaseSpace" label="{{getCapabilityCaption(capability) | pascalCaseSpace}}">
                    <data-fields [parent]="capabilitityControlInForm(capability)" [dataFields]="getDataFields(capability) | async">
                    </data-fields>
                </mat-tab>
            </div>
        </mat-tab-group>      
        <div *ngFor="let capability of capabilitiesInForm">
            <mat-error *ngIf="!capabilitityControlInForm(capability).valid && capabilitityControlInForm(capability).dirty">You must select at least one data-field for {{capability}}.</mat-error>
        </div>
    </div>
</div>