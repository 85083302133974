<form [formGroup]="form">
    <h2>Identifiers</h2>
    <div *ngIf="isExchange" class="info-line">Note: Only valid email addresses are selectable for Exchange requests.</div>
    <div *ngIf="isSkype" class="info-line">Note: Only Skype Id's can be submitted for this request type.</div>
    <div *ngIf="isTeams" class="info-line">Note: This section is for Enterprise Teams real time intercepts only.</div>
    <mat-progress-spinner *ngIf="crmLoading" mode="indeterminate">
    </mat-progress-spinner>
    <div *ngIf="crmLoaded" class="crm-identifiers" layout="row" flex>
        <div *ngIf="loaded" layout="row" flex>
            <div *ngFor="let identifierType of keys">
                <div class="identifier-header">
                    <div class="identifier-header__type">
                        {{identifierType | pascalCaseSpace}}
                    </div>
                    <button class="identifier-header__select-all" *ngIf="!isGroupSelected(identifierType) && !consumerEnterpriseOptions" (click)="toggleSelectionForAllIdentifiers(identifierType)">Select
                        All</button>
                    <button class="identifier-header__select-all" *ngIf="isGroupSelected(identifierType) && !consumerEnterpriseOptions" (click)="toggleSelectionForAllIdentifiers(identifierType)">Select
                        None</button>
                    <button class="identifier-header__select-all account-lookup" *ngIf="showAccountLookupButton()" (click)="sendAccountLookup(identifierType)" [disabled]="disableAccountLookupButton(identifierType)">Account Lookup</button>
                </div>
                <div *ngIf="consumerEnterpriseOptions" class="identifier-selection-header">
                    <div>
                        <div class="identifier"></div>
                        <button class="identifier-header__select-all identifier-checkbox__select-all" *ngIf="!isConsumerGroupSelected(identifierType)" (click)="toggleDataSourceSelectionForAllIdentifiers(identifierType,1)" [disabled]="!consumerOptionsEnabled || disableSelectAllNoneButton(identifierType)"><fa-icon [icon]="faArrowDown"></fa-icon>&nbsp;&nbsp;Select All</button>
                        <button class="identifier-header__select-all identifier-checkbox__select-all" *ngIf="isConsumerGroupSelected(identifierType)" (click)="toggleDataSourceSelectionForAllIdentifiers(identifierType,1)" [disabled]="!consumerOptionsEnabled || disableSelectAllNoneButton(identifierType)"><fa-icon [icon]="faArrowDown"></fa-icon>&nbsp;&nbsp;Select None</button>
                        <button class="identifier-header__select-all identifier-checkbox__select-all" *ngIf="!isEnterpriseGroupSelected(identifierType)" (click)="toggleDataSourceSelectionForAllIdentifiers(identifierType,2)" [disabled]="!enterpriseOptionsEnabled || disableSelectAllNoneButton(identifierType)"><fa-icon [icon]="faArrowDown"></fa-icon>&nbsp;&nbsp;Select All</button>
                        <button class="identifier-header__select-all identifier-checkbox__select-all" *ngIf="isEnterpriseGroupSelected(identifierType)" (click)="toggleDataSourceSelectionForAllIdentifiers(identifierType,2)" [disabled]="!enterpriseOptionsEnabled || disableSelectAllNoneButton(identifierType)"><fa-icon [icon]="faArrowDown"></fa-icon>&nbsp;&nbsp;Select None</button>
                        <button class="identifier-header__select-all identifier-checkbox__clear-all" *ngIf="consumerEnterpriseOptions && mutuallyExclusiveOptionsEnabled" (click)="clearAllIdentifiers(identifierType)"><fa-icon [icon]="faTimes"></fa-icon>&nbsp;&nbsp;Clear</button>
                    </div>
                </div>
                <mat-list class="identifiers-selector">
                    <mat-list-item class="identifiers-selector__identifier" *ngFor="let identifier of _crmIdentifiers[identifierType]" [class.active]="isSelected(identifierType, identifier)">
                        <span class="identifier" [ngClass]="{'strike-out-identifier': !identifierIsSelectable(identifier.id)}" (click)="toggleSelectionForIdentifier(identifierType, identifier)">{{ identifier.id }}</span>
                        <ng-container *ngIf="consumerEnterpriseOptions">
                            <span *ngIf="!mutuallyExclusiveOptionsEnabled" class="identifier-checkbox" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 1)}"><mat-checkbox [(ngModel)]="identifier.isConsumerSelected" [ngModelOptions]="{standalone: true}" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 1)}" (change)="toggleDataSourceSelectionForIdentifier(identifierType,identifier,1)" [disabled]="!consumerOptionsEnabled || !identifierIsSelectable(identifier.id, 1)">Consumer</mat-checkbox></span>
                            <span *ngIf="!mutuallyExclusiveOptionsEnabled" class="identifier-checkbox" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 2)}"><mat-checkbox [(ngModel)]="identifier.isEnterpriseSelected" [ngModelOptions]="{standalone: true}" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 2)}" (change)="toggleDataSourceSelectionForIdentifier(identifierType,identifier,2)" [disabled]="!enterpriseOptionsEnabled || !identifierIsSelectable(identifier.id, 2)">Enterprise</mat-checkbox></span>
                            <mat-radio-group *ngIf="mutuallyExclusiveOptionsEnabled" [(ngModel)]="identifier.customerTenantType" [ngModelOptions]="{standalone: true}">
                                <span class="identifier-checkbox" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 1)}"><mat-radio-button [value]="1" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 1)}" (change)="toggleDataSourceSelectionForIdentifier(identifierType,identifier,1)" [disabled]="!consumerOptionsEnabled || !identifierIsSelectable(identifier.id, 1)">Consumer</mat-radio-button></span>
                                <span class="identifier-checkbox" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 2)}"><mat-radio-button [value]="2" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id, 2)}" (change)="toggleDataSourceSelectionForIdentifier(identifierType,identifier,2)" [disabled]="!enterpriseOptionsEnabled || !identifierIsSelectable(identifier.id, 2)">Enterprise</mat-radio-button></span>
                                <span class="identifier-checkbox__none" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id)}"><mat-radio-button [value]="0" [ngClass]="{'strike-out-opt': !identifierIsSelectable(identifier.id)}" (change)="toggleDataSourceSelectionForIdentifier(identifierType,identifier,0)" [disabled]="!identifierIsSelectable(identifier.id)">None</mat-radio-button></span>
                            </mat-radio-group>
                        </ng-container>
                    </mat-list-item>
                </mat-list>
            </div>
        </div>
        <mat-checkbox *ngIf="showSeparator()" id="separate-file-label" labelPosition='before' formControlName="separateFilePerIdentifier">
            Create separate file per identifier </mat-checkbox>
        <mat-error *ngIf="identifierErrors.noneSelected && dirty">You must select at least one identifier.</mat-error>
        <mat-error *ngIf="isSkype && identifierErrors.hasEmail && dirty">Only Skype Id's can be submitted for this request type.</mat-error>
    </div>
    <br />
</form>