import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as requestStatusSummary from 'src/app/actions/request-status-summary';
import { RequestStatusSummaryService } from 'src/app/services/request-status-summary.service';
import { Observable, of } from 'rxjs';

import { RequestStatusSummary } from '../models/request-status-summary.model';
import { switchMap, map, catchError } from 'rxjs/operators';

@Injectable()
export class RequestStatusSummaryEffects {
  constructor(
    private actions$: Actions,
    private requestStatusSummaryService: RequestStatusSummaryService
  ) {}

  @Effect() addRequestStatus$: Observable<Action> = this.actions$.pipe(
    ofType(requestStatusSummary.ADD),
    switchMap(() =>
      this.requestStatusSummaryService.GetRequestStatusSummaryData()
    ),
    map(
      (data: RequestStatusSummary[]) =>
        new requestStatusSummary.AddedAction(data)
    ),
    catchError(() => of(new requestStatusSummary.AddedAction([])))
  );
  // TODO: Does this catch() handler do enough? - robernst, 06/02/2017.

  @Effect() refreshRequestStatus$: Observable<Action> = this.actions$.pipe(
    ofType(requestStatusSummary.REFRESH),
    switchMap(() =>
      this.requestStatusSummaryService.GetRequestStatusSummaryData()
    ),
    map(
      (data: RequestStatusSummary[]) =>
        new requestStatusSummary.RefreshedAction(data)
    ),
    catchError(() => of(new requestStatusSummary.RefreshedAction([])))
  );

  @Effect() deleteRequestStatus$: Observable<Action> = this.actions$.pipe(
    ofType(requestStatusSummary.DELETE),
    switchMap(() =>
      this.requestStatusSummaryService.GetRequestStatusSummaryData()
    ),
    map(data => new requestStatusSummary.DeletedAction()),
    catchError(() => of(new requestStatusSummary.DeletedAction()))
  );
}
