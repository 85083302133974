export class ExchangeTorusReadyStatus {
    accountIdentifier: string;
    stagingLocationUri: string;
    shadowAccount: string;  //This will only be populated if this is an RT request
    torusPayloadHash: string;

    static fromJSONArray(array: Array<any>): ExchangeTorusReadyStatus[] {
      return array.map(obj => new ExchangeTorusReadyStatus(obj));
    }

    constructor(obj: Object) {
      this.accountIdentifier = obj['AccountIdentifier'];
      this.stagingLocationUri = obj['StagingLocationUri'];
      this.shadowAccount = obj['ShadowAccount'];  //This will only be populated if this is an RT request
      this.torusPayloadHash = obj['TorusPayloadHash'];
    }
  }
