<mat-dialog-content id="details">
    <div id="details-header">
        <fa-icon id="close-box" matDialogClose [icon]="faWindowClose" size="2x" pull="right" (click)="closeDialog()"></fa-icon>
        <h2>ID: {{reqStatusSummary.id}}</h2>
        <h4>Status: {{reqStatusSummary.currentStatus}}</h4>
        <h4>Details: {{statusDetails}}
            <ng-container *ngIf="!statusDetails">No details provided.</ng-container>
        </h4>
        <ng-container *ngIf="msProperty === 'Exchange' && (detailDataSubject$ | async)?.targets.length == 0 && (hasError$ | async)">
            <h4>Error: {{(detailDataSubject$ | async)?.errorMessage}}</h4>
        </ng-container>
        <ng-container *ngIf="msProperty === 'Exchange' && (detailDataSubject$ | async)?.targets.length == 1 && (hasError$ | async)">
            <h4>Error: {{(detailDataSubject$ | async)?.targets[0].error}}</h4>
        </ng-container>
        <ng-container *ngIf="msProperty === 'Exchange' && (detailDataSubject$ | async)?.targets.length > 1 && (hasError$ | async)">
            <h4>Error: Multi-identifier request - see the identifier table below for error details</h4>
        </ng-container>
    </div>
    <form [formGroup]="detailsForm">
        <mat-grid-list cols="2" gutterSize="5px" rowHeight="75px">
            <mat-grid-tile>
                <mat-form-field class="full-width">
                    <mat-label>LNS Number</mat-label>
                    <input matInput formControlName="crmNumber">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field class="full-width">
                    <mat-label>Microsoft Property</mat-label>
                    <input matInput formControlName="msProperty">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field class="full-width">
                    <mat-label>Request Type</mat-label>
                    <input matInput formControlName="requestType">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field class="full-width">
                    <mat-label>Capabilities</mat-label>
                    <input matInput formControlName="capabilities">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field class="full-width">
                    <mat-label>Submitted By</mat-label>
                    <input matInput formControlName="submittedBy">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile>
                <mat-form-field class="full-width">
                    <mat-label>Submitted On</mat-label>
                    <input matInput formControlName="submittedOn">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="isSkypeLiRequest">
                <mat-form-field class="full-width">
                    <mat-label>Expiration Date</mat-label>
                    <input matInput formControlName="endDate">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="msProperty === 'Exchange'">
                <mat-form-field class="full-width">
                    <mat-label>Operation</mat-label>
                    <input matInput formControlName="operation">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="msProperty === 'Exchange' && !showHistoricalDetails" [ngClass]="{'backgroundAnimated': endDateControl.enabled}">
                <mat-form-field class="full-width">
                    <mat-label>Expires</mat-label>
                    <input matInput formControlName="endDate">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="msProperty === 'Exchange' && showHistoricalDetails">
                <mat-form-field class="full-width">
                    <mat-label>Email Filter</mat-label>
                    <input matInput formControlName="emailFilter">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="msProperty === 'Exchange' && showHistoricalDetails">
                <mat-form-field class="full-width">
                    <mat-label>Start Date</mat-label>
                    <input matInput formControlName="captureFrom">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="msProperty === 'Exchange' && showHistoricalDetails">
                <mat-form-field class="full-width">
                    <mat-label>End Date</mat-label>
                    <input matInput formControlName="captureThru">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="exchStatusDetails && exchStatusDetails.target">
                <mat-form-field class="full-width">
                    <mat-label>Target</mat-label>
                    <input matInput formControlName="target">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="msProperty === 'Exchange'">
                <mat-form-field class="full-width">
                    <mat-label>Processing Region</mat-label>
                    <input matInput formControlName="location">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="hasConsumerAndEnterprise">
                <mat-form-field class="full-width">
                    <mat-label>Customer Tenant Type</mat-label>
                    <input matInput formControlName="customerTenantType">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile *ngIf="msProperty === 'Exchange' && showHistoricalDetails && requestType != 'Preservation'">
                <mat-form-field class="full-width">
                    <mat-label>Preservation Search Region(s)</mat-label>
                    <input matInput formControlName="preservationSearchRegions">
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan=2 *ngIf="msProperty === 'Exchange'  && destination ">
                <mat-form-field class="full-width">
                    <mat-label *ngIf="requestType === 'Realtime'">Shadow Account</mat-label>
                    <mat-label *ngIf="requestType === 'Preservation'">Preservation Storage Location</mat-label>
                    <mat-label *ngIf="requestType === 'Historical'">Historical Storage Location</mat-label>
                    <input matInput formControlName="destination">
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-table id="targetGrid" class='full-width' *ngIf="msProperty === 'Exchange' && exchStatusDetails.targets && exchStatusDetails.targets.length > 1" #exchangeDetailsChildrenTable table [dataSource]="exchStatusDetails.targets" matSort matSortActive="target"
            matSortDirection="desc">
            <ng-container matColumnDef="target">
                <mat-header-cell *matHeaderCellDef> Target </mat-header-cell>
                <mat-cell *matCellDef="let detail" [style.color]="detail.color"> {{detail.target}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                <mat-cell *matCellDef="let detail" [style.color]="detail.color"> {{detail.status}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="customerTenantType">
                <mat-header-cell *matHeaderCellDef><span *ngIf="hasConsumerAndEnterprise"> Customer Tenant Type </span></mat-header-cell>
                <mat-cell *matCellDef="let detail" [style.color]="detail.color"><span *ngIf="hasConsumerAndEnterprise"> {{detail.customerTenantType}} </span></mat-cell>
            </ng-container>
            <ng-container matColumnDef="error">
                <mat-header-cell *matHeaderCellDef> Error </mat-header-cell>
                <mat-cell *matCellDef="let detail" [style.color]="detail.color"> {{detail.error}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="targetColumns"></mat-header-row>
            <mat-row *matRowDef="let detail; columns: targetColumns;"></mat-row>
        </mat-table>

        <mat-table class='full-width' *ngIf="msProperty === 'Exchange' && exchStatusDetails.children && exchStatusDetails.children.length > 0" #exchangeDetailsChildrenTable table [dataSource]="exchStatusDetails.children" matSort matSortActive="requestId" matSortDirection="desc">
            <ng-container matColumnDef="requestId">
                <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.id}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="requestType">
                <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.requestType}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="operation">
                <mat-header-cell *matHeaderCellDef> Operation </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.operation}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="crmRequestId">
                <mat-header-cell *matHeaderCellDef> LNS Number </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.crmRequestId}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="submittedDateTime">
                <mat-header-cell *matHeaderCellDef> Submitted </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.submittedDateTime | date:'M/d/yyyy hh:mm a ZZ'}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="endDate">
                <mat-header-cell *matHeaderCellDef> Expiration </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.endDate | date:'M/d/yyyy hh:mm a ZZ':'GMT+0'}}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="detailColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: detailColumns;"></mat-row>
        </mat-table>
        <mat-form-field *ngIf="deleteRequest || extendRequest">
            <mat-label>Enter GCC Number</mat-label>
            <input matInput formControlName="modifyCrmNumber">
        </mat-form-field>
        <mat-form-field class="left-margin" *ngIf="extendRequest && msProperty === 'Exchange'">
            <mat-label>Order Start Date</mat-label>
            <input matInput [matDatepicker]="newStartDate" formControlName="newStartDate">
            <mat-datepicker-toggle matSuffix [for]="newStartDate"></mat-datepicker-toggle>
            <mat-datepicker #newStartDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="left-margin" *ngIf="extendRequest && msProperty === 'Exchange'">
            <mat-label>Duration (days)</mat-label>
            <input matInput mat-autofocus formControlName="orderDuration" [min]="0">
        </mat-form-field>
        <mat-form-field class="left-margin new-expiration" *ngIf="extendRequest && msProperty === 'Exchange'">
            <mat-label>New Expiration</mat-label>
            <input matInput formControlName="newEndDate">
        </mat-form-field>
        <button *ngIf="extendRequest && msProperty === 'Exchange'" class="left-margin" mat-raised-button color="accent" (click)="calcExpirationDate()" [disabled]="newStartDateControl.invalid || orderDurationControl.invalid || submittingModification">Calculate</button>
    </form>
    <div>
        <div *ngIf="shouldShowActions()" class="top-margin">
            <div *ngIf="submittingModification">
                <mat-progress-spinner mode="indeterminate"> </mat-progress-spinner>
            </div>
            <button mat-raised-button color="accent" (click)="extend()" [disabled]="submittingModification || tooLateToExtend">Extend</button>
            <button class="left-margin" *ngIf="operation === 'Subscribe'" mat-raised-button color="warn" (click)="detask()" [disabled]="submittingModification">Stop</button>
            <button class="left-margin" *ngIf="deleteRequest || extendRequest" mat-raised-button color="primary" (click)="submit()" [disabled]="detailsForm.invalid || submittingModification || (!deleteRequest && newEndDateControl.value === '')">Submit</button>
            <span class="cant-message" *ngIf="tooLateToExtend">This request cannot be extended due to the {{minExtendDuration}} hour cache risk.  A new monitor must be created to prevent data loss.</span>
        </div>
    </div>
</mat-dialog-content>
