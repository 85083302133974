<!DOCTYPE html>
<div class="router-outlet">
    <div class="request-status-page container mat-elevation-z8" style="padding-top:5px">
        <div class="request-status-page">
            <form [formGroup]="searchForm">
                <mat-grid-list class="filter-grid" cols="5" rowHeight="60px">
                    <mat-grid-tile class="filter-grid-tile">
                        <mat-form-field class="filter-field">
                            <mat-select (selectionChange)="submitSearch()" formControlName="microsoftProperty" placeholder="Microsoft Property" ngDefaultControl>
                                <mat-option *ngFor="let microsoftProperty of microsoftProperties" [value]="microsoftProperty" required>
                                    {{microsoftProperty}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="filter-grid-tile">
                        <mat-form-field class="filter-field">
                            <mat-select (selectionChange)="submitSearch()" formControlName="currentStatus" placeholder="Status" ngDefaultControl>
                                <mat-option *ngFor="let currentStatus of availableStatuses" [value]="currentStatus" required>
                                    {{currentStatus}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="filter-grid-tile">
                        <mat-form-field class="filter-field" [floatLabel]='filterFloatLabelType'>
                            <mat-label>Submitter</mat-label>
                            <input formControlName="submitter" matInput placeholder="username" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="filter-grid-tile">
                        <mat-form-field class="filter-field" [floatLabel]='filterFloatLabelType'>
                            <mat-label>LNS Number</mat-label>
                            <input formControlName="gccNumber" matInput placeholder="LNS-ABC123-DEF456" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="filter-grid-tile">
                        <mat-form-field class="filter-field" [floatLabel]='filterFloatLabelType'>
                            <mat-label>Account Identifiers</mat-label>
                            <input formControlName="identifiers" matInput placeholder="acct1,acct,acct2" />
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile class="filter-grid-tile" [hidden]='!showExchangeFilters' [colspan]="3">
                        <mat-form-field class="filter-field" [floatLabel]='filterFloatLabelType'>
                            <mat-label>Shadow Account</mat-label>
                            <input formControlName="shadowAccount" matInput placeholder="e5ff3749-2891-4d97-9b27-b747b978bac9@domain.com" />
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </form>
        </div>

        <mat-table class="request-status-page" #table [dataSource]="dataSource" matSort matSortActive="requestId" matSortDirection="desc">
            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->
            <ng-container matColumnDef="capability">
                <mat-header-cell *matHeaderCellDef> Capability </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.capability}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="accountIdentifiers">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Account Identifier </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.hasMultiIdentifiers ? "Multi-Identifier Search" : (row.target)}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="currentStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Current Status </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.currentStatus}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="currentStatusDetails">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Detailed Status </mat-header-cell>
                <mat-cell *matCellDef="let row"> {{row.currentStatusDetails}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="details">
                <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color">
                    <button *ngIf="row.capability != 'SkypeChatContent'" mat-button color="primary" (click)="openDetails(row)">Details
                    </button>
                    <button *ngIf="row.capability == 'SkypeChatContent' && row.currentStatus != 'Submitted'" mat-button color="primary" (click)="openDetails(row)">Details
                    </button>
                    <button *ngIf="row.operation == 'HistoricalSearch' && row.microsoftProperty == 'Exchange'" mat-button color="primary" (click)="openDeliveryReview(row)" [disabled]="row.currentStatus != 'ReadyForValidation' && row.currentStatus != 'Completed' && row.currentStatus != 'Published'">Review </button>
                    <button *ngIf="row.operation == 'Preservation' && row.microsoftProperty == 'Exchange'" mat-button color="primary" (click)="openPreservationReview(row)" [disabled]="row.currentStatus != 'Completed'">Review </button>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="microsoftProperty">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Microsoft Property </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.microsoftProperty}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="requestId">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Request ID </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.id}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="submittedBy">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Submitted By </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.submittedBy}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="submittedDateTime">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Submitted Date </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.submittedDateTime | date:'M/d/yyyy hh:mm a ZZ'}} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="crmRequestId">
                <mat-header-cell *matHeaderCellDef mat-sort-header> LNS Number </mat-header-cell>
                <mat-cell *matCellDef="let row" [style.color]="row.color"> {{row.crmRequestId}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <div class="no-results" *ngIf="(dataSource.statusCount == 0) && !(dataSource.loading$ | async)">No matching results found</div>

        <mat-progress-spinner class="center-spinner" *ngIf="(dataSource.loading$ | async)" mode="indeterminate">
        </mat-progress-spinner>

        <mat-paginator #paginator [length]="dataSource.statusCount" [pageIndex]="0" [pageSize]="25" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
    </div>
</div>
