import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';
import { MomentValidators } from '../../validators/moment.validators';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'

@Component({
  selector: 'datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input()
  set requestType(_: string) {
    this._requestType = _;
    switch (_.toLowerCase()) {
      case 'realtime':
      this.minDate = this.hasNoStartDateRestriction() ? moment.utc('1990-01-01') : moment.utc();
      this.defaultStartDate = moment.utc();
      this.maxDate = null;
      this.defaultEndDate = null;
        this.resetStartDate();
        this.resetEndDate();
        break;
      case 'historical':
        // Dates should only be from the past up to and including the current date.
        this.defaultStartDate = moment.utc(
          '1990-01-01 12:00:00 AM',
          'YYYY-MM-DD HH:mm:ss A'
        );
        this.defaultEndDate = moment.utc({
          hour: 23,
          minute: 59,
          second: 59
        });
        this.minDate = moment.utc('1990-01-01');
        this.maxDate = moment.utc();
        this.resetStartDate();
        this.resetEndDate();
        break;
      default:
        break;
    }
  }
  @Input() msProperty: string;

  form: FormGroup;
  _requestType: string;
  
  // Datepicker items - default to assume historical until a selection is made.
  defaultStartDate: Moment = moment.utc(
    '1990-01-01 12:00:00 AM',
    'YYYY-MM-DD HH:mm:ss A'
  );
  defaultEndDate: Moment = moment.utc({ hour: 23, minute: 59, second: 59 });
  // used on the date elements themselves to restrict selections
  minDate: Moment = moment.utc('1990-01-01');
  maxDate: Moment = moment.utc();
  minTime: Moment = moment('12:00:00 AM', 'HH:mm:ss A'); //.format('LTS');
  maxTime: Moment = moment('11:59:59 PM', 'HH:mm:ss A'); //.format('LTS');

  constructor(
    private aiLogService: AiLoggingService
  ) {
  }

  get startDateControl() {
    return this.form.get('startDate') || null;
  }

  get endDateControl() {
    return this.form.get('endDate') || null;
  }

  get startTimeControl() {
    return this.form.get('startTime') || null;
  }

  get endTimeControl() {
    return this.form.get('endTime') || null;
  }

  ngOnInit() {
    this.setupForm();
    if (this.parent) {
      this.parent.addControl('dates', this.form);
    }

    this.aiLogService.logEvent("Load DatePicker component");  
  }

  private setupForm() {
    this.form = new FormGroup({
      startDate: new FormControl(this.defaultStartDate),
      endDate: new FormControl(this.defaultEndDate, Validators.required),
      startTime: new FormControl(this.defaultStartDate.format('LTS'), {
      validators: [Validators.required, MomentValidators.validTime]
      }),
      endTime: new FormControl(this.defaultEndDate.format('LTS'), {
        validators: [Validators.required, MomentValidators.validTime]
      })
    });
  }

  hasStart() {
    if (!this.parent || !this._requestType) {
      return false;
    }

    return this._requestType !== 'realTime' || (this.msProperty == "Skype" || this.msProperty == "Teams");
  }

  hasNoStartDateRestriction() {
    if (!this.parent || !this._requestType) {
      return false;
    }

    return this._requestType == 'realTime' && (this.msProperty == "Skype" || this.msProperty == "Teams");
  }

  hasEnd() {
    // All types have end dates
    return true;
  }

  resetStartDate() {
    this.startDateControl.setValue(this.defaultStartDate);
  }

  resetEndDate() {
    this.endDateControl.setValue(this.defaultEndDate);
  }

  resetStartTime() {
    this.startTimeControl.setValue(this.defaultStartDate.format('LTS'));
  }

  resetEndTime() {
    this.endTimeControl.setValue(this.defaultEndDate.format('LTS'));
  }

  mergeStartDatetime() {
    const newStartDatetime = moment.utc(
      this.startDateControl.value.format('l') +
        ' ' +
        this.startTimeControl.value,
      'MM-DD-YYYY HH:mm:ss A'
    );
    this.startDateControl.setValue(newStartDatetime);
    this.startTimeControl.setValue(newStartDatetime.format('LTS'));
    this.validateDateTime();
  }

  mergeEndDatetime() {
    const newEndDatetime = moment.utc(
      this.endDateControl.value.format('l') + ' ' + this.endTimeControl.value,
      'MM-DD-YYYY HH:mm:ss A'
    );
    this.endDateControl.setValue(newEndDatetime);
    this.endTimeControl.setValue(newEndDatetime.format('LTS'));
    this.validateDateTime();
  }

  validateDateTime() {
    // we can't do this in a validator since we need two form controls, so we'll set it here
    if (this.startDateControl.valid && this.endDateControl.valid && this.startDateControl.value >= this.endDateControl.value) {
      this.form.setErrors({invalidDates: true});
    } else {
      this.form.setErrors(null);
    }
  }
}
