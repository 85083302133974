import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { RequestStatusSummary } from '../../models/request-status-summary.model';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeliveryReviewService, ProcessingRegionEnum } from 'src/app/services/delivery-review.service';
import { DeliveryReview } from 'src/app/models/delivery-review.model';
import { ClipboardService } from 'ngx-clipboard';
import { EmailContentType } from 'src/app/models/delivery-review.model';
import { MailboxItemType } from 'src/app/services/delivery-review.service';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faWindowClose, IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'delivery-review',
  templateUrl: './delivery-review.component.html',
  styleUrls: ['./delivery-review.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeliveryReviewComponent implements OnInit {

  public emailContentType = EmailContentType;
  public mailboxItemType = MailboxItemType;
  public processingRegionEnum = ProcessingRegionEnum;
  public outputSearchRegions: string;
  public requestedSearchRegions: string;
  public reqStatusSummary: RequestStatusSummary;
  public deliveryReviewData: DeliveryReview;
  public sasToken: string;
  public isGenerated: boolean = false;
  public isGenerating: boolean = false;
  public isDataLoaded: boolean = false;
  public isPublishing: boolean = false;
  public isPublished: boolean = false;
  faWindowClose: IconDefinition = faWindowClose;
  ngUnsub: Subject<void> = new Subject<void>();

  constructor(
    private cbService: ClipboardService,
    private dataService: DeliveryReviewService,
    private dialogRef: MatDialogRef<DeliveryReviewComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
    this.dataService.getReviewData(this.reqStatusSummary.id)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe({
        next: data => {
          this.deliveryReviewData = new DeliveryReview(data);
        },
        complete: () => {
          this.isDataLoaded = true;
          const uniqueOutputSearchRegions = _.uniqBy(this.deliveryReviewData.outputCriminalPreservations, function(x) {
            return x.processingRegion;
          }).map(x => this.processingRegionEnum[x.processingRegion]);
          this.outputSearchRegions = _.join(_.sortBy(uniqueOutputSearchRegions), ', ');
          this.requestedSearchRegions = _.join(_.sortBy(this.deliveryReviewData.requestedSearchRegions), ', ');
        }
      });
  }

  generateSasUrl() {
    this.isGenerating = true;
    this.isGenerated = false;
    this.dataService.getSasToken(this.reqStatusSummary.id).subscribe({
      next: (data: string) => {
        this.sasToken = data;
      },
      complete: () => {
        this.isGenerating = false;
        this.isGenerated = true;
        console.log(this.sasToken);
      },
      error: () => {
        this.isGenerating = false;
        this.isGenerated = false;
      }
    });
  }

  initiateDelivery() {
      this.isPublishing = true;
      this.dataService.initiateDelivery(this.reqStatusSummary.lensRequestId).subscribe({
        complete: () => {
          this.isPublishing = false;
          this.isPublished = true;
        },
        error: () => {
          this.isPublishing = false;
          this.isPublished = false;
        }
    });
  }

  copySasUrl() {
    this.cbService.copyFromContent(this.reqStatusSummary.destination + this.sasToken);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
