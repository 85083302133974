import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountLookupSubmitRequest } from 'src/app/models/account-lookup-submit-request.model';
import { SkypeAccountLookupDetails } from 'src/app/models/skype-account-lookup-details.model';
import { SkypeAccountLookupSubmitRequest } from 'src/app/models/skype-account-lookup-submit-request.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { AccountLookupDetails } from '../models/account-lookup-details.model';
import { AccountLookupStatusSearch } from '../models/account-lookup-status-search.model';
import { PagedAccountLookupStatusRequest } from '../models/paged-account-lookup-status-request.model';
import { PagedAccountLookupStatusSummary } from '../models/paged-account-lookup-summary.model';

@Injectable()
export class AccountLookupService {

    crmRequestId: string;
    identifiers: string;
    lookupType: string;

    constructor(protected baseService: BaseService) { }

    setParameters(crmRequestId, identifiers, lookupType) {
        this.crmRequestId = crmRequestId;
        this.identifiers = identifiers;
        this.lookupType = lookupType;
    }

    submitAccountLookup(newRequest: AccountLookupSubmitRequest, lookupType: string): Observable<number> {
        return this.baseService
            .post(environment.webApiConfig.serverUrl + environment.accountLookupUrl + '/' + lookupType.toLowerCase(), newRequest)
            .pipe(catchError(error => this.handleSubmissionError(error)));
    }

    submitSkypeAccountLookup(newRequest: SkypeAccountLookupSubmitRequest): Observable<SkypeAccountLookupDetails[]> {
        return this.baseService
            .post(environment.webApiConfig.serverUrl + environment.skypeAccountLookupUrl, newRequest)
            .pipe(catchError(error => this.handleSkypeSubmissionError(error)));
    }

    getAccountLookups(username: string, includeexpired: boolean, sort: string, page: number, size: number)
        : Observable<PagedAccountLookupStatusSummary> {
        const searchParams: AccountLookupStatusSearch = {
            submitter: username,
            includeExpired: includeexpired
        };

        const request: PagedAccountLookupStatusRequest = {
            search: searchParams,
            sort: sort,
            page: page,
            size: size
        };

      return this.baseService
            .post(environment.webApiConfig.serverUrl + environment.eventProcessor.accountStatusPath + '/paged', request)
            .pipe(catchError(error => this.baseService.handleError(error)));
    }

    getAccountLookup(id: number): Observable<AccountLookupDetails[]> {
        return this.baseService
            .get(environment.webApiConfig.serverUrl + environment.accountLookupUrl + '/' + id)
            .pipe(
                map(response => AccountLookupDetails.fromJSONArray(response)),
                catchError(error => this.baseService.handleError(error)));
    }

    getAccountLookupWithIps(id: number, index: number){
        return this.baseService
            .get(environment.webApiConfig.serverUrl + environment.accountLookupUrl + '/' + id + '/' + index)
            .pipe(
                map((response: AccountLookupDetails) => response),
                catchError(error => this.baseService.handleError(error)));
    }

    submitTorusFailure(id: number, wasCanceled: boolean, message: string) {
        const body = {
            wasCanceled: wasCanceled,
            message: message
        };

        return this.baseService
            .post(environment.webApiConfig.serverUrl + environment.accountLookupUrl + '/torus-failure/' + id, body)
            .pipe(catchError(error => this.baseService.handleError(error)));
    }

    handleSubmissionError(error: HttpErrorResponse) {
        if (error.status == 400) {
            return this.baseService.handleCustomError(error, 'At least one of the accounts entered does not exist on the specified CRM ticket. Please verify the account(s) and the LNS number and try again.');
        }
        else {
            return this.baseService.handleError(error);
        }
    }

    handleSkypeSubmissionError(error: HttpErrorResponse) {
        if (error.status == 400) {
            return this.baseService.handleCustomError(error, 'An error occurred while submitting Skype Accounts for lookup.  Error: ' + error.message);
        }
        else {
            return this.baseService.handleError(error);
        }
    }
}
