import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';

export function emailValidator(): ValidatorFn {
  // tslint:disable-next-line:max-line-length
  const validatorString: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return (control: AbstractControl): {[key: string]: any} | null => {
    const isInvalid = control.value.length > 0 && !validatorString.test(control.value);
    return isInvalid ? {'invalidEmailAddress': {value: control.value}} : null;
  }
}
