import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TorusErrorDialogComponent } from 'src/app/components/torus-error-dialog/torus-error-dialog.component';
import { TorusProxyScriptResponse } from 'src/app/models/torus-proxy-script-response.model';
import { AiLoggingService } from 'src/app/shared/services/ai-logging.service';
import { BaseService } from 'src/app/shared/services/base.service';
import { DAuthCredentialType, DAuthService, ProxyScriptRequest } from 'dauthservice-new';
import * as _ from 'lodash';
import { catchError, switchMap, take, concatMap, filter, timeout, map } from 'rxjs/operators';
import { TorusErrorDialogData } from 'src/app/models/torus-error-dialog-data.model';
import { ExchangeTorusReadyStatus } from 'src/app/models/exchange-torus-ready-status.model';
import { timer, Subject, Observable, from, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TorusSubmissionResponse } from 'src/app/models/torus-submission-response.model';
import { AccountLookupTorusReadyStatus } from 'src/app/models/account-lookup-torus-ready-status.model';

@Injectable()
export class TorusSubmissionService {
    private dAuthService: DAuthService = new DAuthService();
    private torusStatuses: ExchangeTorusReadyStatus[];

    public torusSource = new Subject<TorusSubmissionResponse>();
    public torusObservable$ = this.torusSource.asObservable();

    constructor(protected baseService: BaseService,
        private dialog: MatDialog,
        private aiLogService: AiLoggingService) { }


    //#region Begin Torus submission methods
    public accountLookup(accountLookupId: number, torusStatuses: ExchangeTorusReadyStatus[]) {
        const workflowParams = _.map(torusStatuses, (item: ExchangeTorusReadyStatus) => {
            return '{' +
                '"BlobPath": "' + item.stagingLocationUri + '",' +
                '"PayloadHash": "' + item.torusPayloadHash + '",' +
                '}';
        });
        const proxyScriptRequest = this.createProxyScriptRequest('AccountLookup', workflowParams);

        return this.submitProxyScriptRequest(proxyScriptRequest, 'AccountLookup', accountLookupId.toString());
    }

    public realTime(realTimeRequestId: string, torusStatuses: ExchangeTorusReadyStatus[], includeContent: boolean, includeHeaders: boolean,
        includeDrafts: boolean, includeIpLogins: boolean, disabled: boolean, expiry: Date) {
        const workflowParams = _.map(torusStatuses, (item: ExchangeTorusReadyStatus) => {
            return '{' +
                '"BlobPath": "' + item.stagingLocationUri + '",' +
                '"PayloadHash": "' + item.torusPayloadHash + '",' +
                '"SasTokenExpiryTime": "' + environment.exchangeTorusConfig.realtimeTorusSasTokenExpiration + '"' +
                '}';
        });
        const proxyScriptRequest = this.createProxyScriptRequest('Interception', workflowParams, true);

        return this.submitProxyScriptRequest(proxyScriptRequest, 'RealTime', realTimeRequestId);
    }

    public preservation(requestId: string, torusStatuses: ExchangeTorusReadyStatus[], includeContent: boolean,
      includeDeviceInfo: boolean, includeIpLogins: boolean, includeCalendar: boolean, includeContacts: boolean, isPreservation: boolean,
      startDate: Date, endDate: Date) {
        const workflowParams = _.map(torusStatuses, (item: ExchangeTorusReadyStatus) => {
          return `{
          "BlobPath": "${item.stagingLocationUri}",
          "PayloadHash": "${item.torusPayloadHash}",
          "SasTokenExpiryTime": "${environment.exchangeTorusConfig.preservationTorusSasTokenExpiration}"
          }`;
        });

        // Preservation uses the Historical Search Torus action
        const proxyScriptRequest = this.createProxyScriptRequest('HistoricalSearch', workflowParams, true);
        if (environment.envName === 'dev') {
          console.log(workflowParams);
        }
        return this.submitProxyScriptRequest(proxyScriptRequest, 'Preservation', requestId);
    }

    public historical(historicalSearchIdentifierId: string, torusStatuses: ExchangeTorusReadyStatus[], includeContent: boolean,
      includeDeviceInfo: boolean, includeIpLogins: boolean, includeCalendar: boolean, includeContacts: boolean, isPreservation: boolean,
      startDate: Date, endDate: Date) {
        const workflowParams = _.map(torusStatuses, (item: ExchangeTorusReadyStatus) => {
          return `{
          "BlobPath": "${item.stagingLocationUri}",
          "PayloadHash": "${item.torusPayloadHash}",
          "SasTokenExpiryTime": "${environment.exchangeTorusConfig.searchTorusSasTokenExpiration}"
          }`;
        });

        // Preservation uses the Historical Search Torus action
        const proxyScriptRequest = this.createProxyScriptRequest('HistoricalSearch', workflowParams, true);
        if (environment.envName === 'dev') {
          console.log(workflowParams);
        }
        return this.submitProxyScriptRequest(proxyScriptRequest, 'Historical', historicalSearchIdentifierId.toString());
    }

    public submitProxyScriptRequest(proxyScriptRequest: ProxyScriptRequest, requestType: string, requestId: string) {
        return this.submitProxyScriptRequestInternal(proxyScriptRequest)
            .pipe(take(1), switchMap((response: TorusProxyScriptResponse) => {
                // do good stuff
                // call post torus status

                if (requestType.toLowerCase() === 'accountlookup') {
                    // update with id
                    this.postAccountLookupSubmittedStatus(parseInt(requestId), response)
                    .pipe(take(1))
                    .subscribe();

                    this.aiLogService.logEvent('AccountLookupTorusSubmitted', {
                        AccountLookupId: parseInt(requestId),
                        OperationId: response.OperationId,
                        LockBoxRequestId: response.LockboxRequestId,
                        HostFqdn: response.HostFqdn,
                        Logs: response.Logs,
                        Result: response.Result
                    });
                } else if (requestType.toLowerCase() === 'realtime') {
                    this.aiLogService.logEvent('RealTimeTorusSubmitted', {
                        RealTimeRequestId: requestId,
                        OperationId: response.OperationId,
                        LockBoxRequestId: response.LockboxRequestId,
                        HostFqdn: response.HostFqdn,
                        Logs: response.Logs,
                        Result: response.Result
                    });
                } else if (requestType.toLowerCase() === 'preservation') {
                  this.aiLogService.logEvent('PreservationTorusSubmitted', {
                      RealTimeRequestId: requestId,
                      OperationId: response.OperationId,
                      LockBoxRequestId: response.LockboxRequestId,
                      HostFqdn: response.HostFqdn,
                      Logs: response.Logs,
                      Result: response.Result
                  });
                } else if (requestType.toLowerCase() === 'historical') {

                  this.aiLogService.logEvent('HistoricalTorusSubmitted', {
                      HistoricalSearchIdentifierId: requestId,
                      OperationId: response.OperationId,
                      LockBoxRequestId: response.LockboxRequestId,
                      HostFqdn: response.HostFqdn,
                      Logs: response.Logs,
                      Result: response.Result
                  });
                }

                const subjectModel = new TorusSubmissionResponse();
                subjectModel.error = false;

                this.torusSource.next(subjectModel);

                return of(response);
            }), catchError(val => this.handleError(val, this.dialog, proxyScriptRequest, requestType, requestId)));
    }
    //#endregion

    //#region Begin ready status methods
    public getExchangeReadyStatus(id: string): Observable<ExchangeTorusReadyStatus[]> {
        return this.baseService
        .get(environment.webApiConfig.serverUrl + environment.torusStatusUrl + '/exchange/' + id)
            .pipe(
                map(response => ExchangeTorusReadyStatus.fromJSONArray(response)),
                catchError(error => this.baseService.handleError(error)));
    }

    public postExchangeSubmittedStatus(id: string, torusSubmissionInfo: TorusProxyScriptResponse): Observable<boolean> {
        return this.baseService
        .post(environment.webApiConfig.serverUrl + environment.torusStatusUrl + '/exchange/' + id, JSON.stringify(torusSubmissionInfo))
            .pipe(
                map(response => response),
                catchError(error => this.baseService.handleError(error)));
    }

    public getAccountLookupReadyStatus(id: string): Observable<AccountLookupTorusReadyStatus[]> {
        return this.baseService
        .get(environment.webApiConfig.serverUrl + environment.torusStatusUrl + '/account-lookup/' + id)
            .pipe(
                map(response => AccountLookupTorusReadyStatus.fromJSONArray(response)),
                catchError(error => this.baseService.handleError(error)));
    }

    public postAccountLookupSubmittedStatus(id: number, torusSubmissionInfo: TorusProxyScriptResponse): Observable<boolean> {
        return this.baseService
            .post(environment.webApiConfig.serverUrl + environment.torusStatusUrl + '/account-lookup/' + id,
            JSON.stringify(torusSubmissionInfo))
            .pipe(
                map(response => response),
                catchError(error => this.baseService.handleError(error)));
    }
    //#endregion

    //#region Begin private helpers
    private createProxyScriptRequest(action: string, workflowParams: string[], requiresApproval: boolean = false)
        : ProxyScriptRequest {

        const proxyScriptName = requiresApproval ? 'Request-LensActionWithApproval.ps1' : 'Request-LensAction.ps1';
        const assemblyTypeNameType = requiresApproval ? 'LensActionWithApprovalWorkflow' : 'LensActionWithoutApprovalWorkflow';

        const request: ProxyScriptRequest = {
            ProxyScriptName: proxyScriptName,
            ParametersJson: {
              Action: action,
              WorkflowParams: workflowParams
            },
            WorkflowName: {
                AssemblyTypeName: {
                    Type: assemblyTypeNameType,
                    Namespace: 'Microsoft.Office.Datacenter.Workflows',
                    AssemblyName: 'Microsoft.Office.Datacenter.Workflows',
                },
                Tenant: 'DatacenterWorkflows',
                Timeout: 15
            },
            Workload: 'Exchange',
            ServiceInstanceType: 'MultiTenant'
        };

        if (environment.exchangeTorusConfig.workflowVersion !== "") {
          request.ParametersJson.WorkflowVersion = environment.exchangeTorusConfig.workflowVersion;
        }

        return request;
    }

    private submitProxyScriptRequestInternal(request: ProxyScriptRequest,
        credentialType: DAuthCredentialType = DAuthCredentialType.SmartCardAndPassword): Observable<TorusProxyScriptResponse> {
        return from(this.dAuthService.invokeProxyScript(request, credentialType));
    }

    private handleError(torusError, matDialog, proxyScriptRequest: ProxyScriptRequest, requestType: string, requestId: string): Observable<TorusProxyScriptResponse> {
        let errorMessage: string;
        let retryable: boolean;

        if (torusError.message.includes('User cancelled consent')) {
            errorMessage = 'Torus submission was cancelled.';
            retryable = true;
        }
        else if (torusError.message.includes('Please insert your Yubikey and light it up')) {
            errorMessage = 'YubiKey was not found. Insert your YubiKey to proceed.';
            retryable = true;
        }
        else if (torusError.message.includes('Failed to access Yubikey')) {
            errorMessage = 'PIN entry was cancelled.';
            retryable = true;
        }
        else if (torusError.message.includes('AcquireDebugUserPassword')) {
            errorMessage = 'Providing the password for your Torus account is mandatory.';
            retryable = true;
        }
        else if (torusError.message.includes('TorusClientHelperService is running')) {
            errorMessage = 'Please download the latest <a href="https://aka.ms/torusclient" target="_">Torus Client</a>'
                + ' and ensure the TorusClientHelperService Windows Service is running.'
                + ' You may resubmit once you have verified that it is running.';
            retryable = false;
        }
        else {
            errorMessage = 'A Torus error occurred: ' + torusError + '. If the error persists, please contact'
                + ' o365-service-auth-oc@microsoft.com.';
            retryable = false;
        }

        const subjectModel = new TorusSubmissionResponse();
        subjectModel.error = true;
        subjectModel.errorMessage = errorMessage;

        // Setting properties for retryable errors
        subjectModel.retryable = retryable;
        subjectModel.request = proxyScriptRequest;
        subjectModel.requestType = requestType;
        subjectModel.requestId = requestId;

        this.torusSource.next(subjectModel);

        return of(torusError);
    }
    //#endregion
}
