import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, sortBy: string, order?: string, caseSensitive = false): any[] {
     
    return orderBy(array, 
        [x => caseSensitive || !x[sortBy] ? x[sortBy] : x[sortBy].toLowerCase()], 
        order == 'desc' ? 'desc' : 'asc');
  }
}
