import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/shared/services/base.service';
import { DeliveryReview } from 'src/app/models/delivery-review.model';
import { environment } from 'src/environments/environment';
import { AiLoggingService } from 'src/app/shared/services/ai-logging.service';
import { catchError } from 'rxjs/operators';

export enum MailboxItemType {
  Calendar,
  Contacts,
  IM,
  Journal,
  MailboxLogin,
  Messages,
  MobileDevice,
  Other,
  Registration,
  Posts,
  Tasks,
  Unknown,
  Voice,
  Notes
}

export enum ProcessingRegionEnum {
  US,
  IE,
  JP,
  BR
}

export enum CustomerTenantTypeEnum {
  Unspecified,
  Consumer,
  Enterprise
}

@Injectable()
export class DeliveryReviewService {

  constructor(
    protected baseService: BaseService,
    private aiLogService: AiLoggingService
  ) {}

  getReviewData(searchId: string) {
    return this.baseService.get<DeliveryReview>(`${environment.webApiConfig.serverUrl}${environment.deliveryReviewUrl}/${searchId}`)
    .pipe(catchError(error => this.baseService.handleError(error)));
  }

  getSasToken(searchId: string) {
    return this.baseService.get<string>(`${environment.webApiConfig.serverUrl}${environment.deliveryReviewUrl}/GetSasToken/${searchId}`)
    .pipe(catchError(error => this.baseService.handleError(error)));
  }

  initiateDelivery(requestId: string) {
    return this.baseService.post(`${environment.webApiConfig.serverUrl}${environment.deliveryReviewUrl}/${requestId}`, null)
    .pipe(catchError(error => this.baseService.handleError(error)));
  }
}
