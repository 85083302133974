import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Capability } from '../../models/capability.model';
import { DataField } from '../../models/data-field.model';
import { CapabilitiesService } from '../../services/capabilities.service';
import { DataFieldsService } from '../../services/data-fields.service';
import { AtLeastOneInGroup } from '../../validators/lens-request.validators';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'
import * as _ from 'lodash';

@Component({
  selector: 'capabilities-selector',
  templateUrl: './capabilities-selector.component.html',
  styleUrls: ['./capabilities-selector.component.scss']
})
export class CapabilitiesSelectorComponent
  implements OnInit {
  @Input() parent: FormGroup;
  @Input() set selectedMsProperty(msProperty: string) {
    this._selectedMsProperty = msProperty;
    this.resetForm();
  }

  @Input() set selectedRequestType(requestType: string) {
    if (requestType) {
      this._selectedRequestType = requestType;
      this.resetForm();
      this.capabilitiesService.getCapabilitiesData(
        this._selectedMsProperty,
        this._selectedRequestType
      ).pipe(takeUntil(this.ngUnsub)).subscribe(values => {
        this._capabilities = values
        // when new capabilities come in, we want to force the parent to acknowledge this in the DOM
        this.formChanges.emit();
      });
    }
  }

  @Output() formChanges = new EventEmitter();

  get selectedRequestType() {
    return this._selectedRequestType;
  }
  _capabilities: Capability[];
  _capabilitiesWithoutDataFields = ['SkypeChatMessages', 'IncludeSkypeAttachments']
  _selectedMsProperty: string;
  _selectedRequestType: string;
  ngUnsub: Subject<void>;
  lastStatus: boolean = false;
  form: FormGroup;

  // ACCESSOR PROPERTIES
  // Some capabilities do not have nested data fields. We should not provide a tab or network call to show or fetch those data fields which do not exist.
  get capabilitiesInForm() {
    let keys = Object.keys(this.form.controls).filter(key => {
      let fetchDataFields = true;
      this._capabilitiesWithoutDataFields.forEach(capabilityToIgnore => {
        if(this.compareCaseInsensitive(key, capabilityToIgnore)){
          fetchDataFields = false;
        }
      })
      return fetchDataFields;
    });
    return keys;
  }

  capabilitityControlInForm(capability: string): AbstractControl {
    return this.form.get(capability);
  }

  getCapabilityCaption(capability: string): string {
    const c = _.find(this._capabilities,function(x){return x.serverName.toLowerCase() == capability.toLowerCase()})
    return c ? c.displayName : capability;
  }

  get isSkypeEurope(): boolean {
    return (
      this._selectedMsProperty.toLowerCase() == 'skype' &&
      this._selectedRequestType.toLowerCase() == 'realtime'
    );
  }

  get hasFilters() {
    return (
      this._selectedMsProperty.toLowerCase() == 'skype' || this._selectedMsProperty.toLowerCase() == "teams"
    );
  }

  constructor(
    private capabilitiesService: CapabilitiesService,
    private dataFieldsService: DataFieldsService,
    private aiLogService: AiLoggingService
  ) {
  }

  ngOnInit() {
    this.ngUnsub = new Subject<void>();
    this.setupForm();
    this.trackDataFieldsChanges();
    this.aiLogService.logEvent("Load CapabilitiesSelector component");
  }

  // When the status changes, we need to force the parent container to detect this.
  private trackDataFieldsChanges() {
    this.form.statusChanges
      .pipe(takeUntil(this.ngUnsub), tap(status => {
        let newStatus = status == 'VALID';
        // No need to spam the parent. If the status changes, we'll let it know.
        if (newStatus != this.lastStatus) {
          this.lastStatus = newStatus;
          this.formChanges.emit();
        }
      }))
      .subscribe();
  }

  private resetForm() {
    if (this.form) {
      const controls = Object.keys(this.form.controls);
      controls.forEach(control => {
        this.form.removeControl(control);
      });
    }
  }

  capabilitiesToIgnore(element, index, array){
    let ignore = false;
    this._capabilitiesWithoutDataFields.map(capabilityToIgnore => {
      if(this.compareCaseInsensitive(element, capabilityToIgnore)){
        ignore = true;
      }
    })
    return ignore;
  }

  compareCaseInsensitive(a, b) {
    return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b;
  }

  // ** START SETUP HELPERS**
  private setupForm() {
    this.form = new FormGroup({}, AtLeastOneInGroup);
    if (this.parent) {
      this.parent.addControl('capabilities', this.form);
    };
  }
  // ** END SETUP HELPERS**

  // ** FORM ACCESS HELPERS*
  getDataFields(capabilityName: string): Observable<DataField[]> {
    return this.dataFieldsService.getDataFields(capabilityName,this._selectedMsProperty,this._selectedRequestType);
  }
}
