import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RequestStatusSummary } from 'src/app/models/request-status-summary.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class RequestStatusSummaryService  {
  constructor(protected baseService: BaseService) {
  }

  public GetRequestStatusSummaryData(): Observable<{} | RequestStatusSummary[]> {
    return this.baseService
      .get(environment.webApiConfig.serverUrl + environment.eventProcessor.requestStatusPath).pipe(
        catchError(error => this.baseService.handleError(error))
      );
  }
}
