
import { takeUntil, debounceTime, tap, distinctUntilChanged } from 'rxjs/operators';


import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable, merge, Subject } from 'rxjs';

import { RequestStatusSummaryDataSource } from '../../classes/request-status-summary-data-source.class';
import { RequestStatusSearch } from '../../models/request-status-search.model';
import { RequestStatusSummary } from '../../models/request-status-summary.model';
import { RequestStatusService } from '../../services/request-status.service';
import { RequestStatusDetailsComponent } from '../request-status-details/request-status-details.component';
import { AiLoggingService } from '../../shared/services/ai-logging.service';
import { SkypeContentDetailsComponent } from '../lens-request/components/skype-content-details/skype-content-details.component';
import { DeliveryReviewComponent } from '../delivery-review/delivery-review.component';
import { PreservationReviewComponent } from '../preservation-review/preservation-review.component';
import * as _ from 'lodash';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';

@Component({
    selector: 'request-status-summary',
    templateUrl: './request-status-summary.component.html',
    styleUrls: ['./request-status-summary.component.scss']
})
export class RequestStatusSummaryComponent implements OnInit, AfterViewInit {
    dataSource: RequestStatusSummaryDataSource;
    displayedColumns = ['requestId', 'accountIdentifiers', 'microsoftProperty', 'capability', 'currentStatus', 'currentStatusDetails',
        'submittedBy', 'submittedDateTime', 'crmRequestId', 'details'];
    filterFloatLabelType: string = 'always';
    microsoftProperties: string[] = ['Skype', 'Exchange', 'Teams'];
    availableStatuses: string[] = ['Any', 'Submitted', 'InProgress', 'Completed', 'Error']
    ngUnsub: Subject<void> = new Subject<void>();
    requestStatusItems$: Observable<RequestStatusSummary[]>;
    searchForm: FormGroup;
    faWindowClose = faWindowClose;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    get microsoftPropertyFilterControl() { return this.searchForm.get('microsoftProperty') || null; }
    get currentStatusFilterControl() { return this.searchForm.get('currentStatus') || null; }
    get submitterFilterControl() { return this.searchForm.get('submitter') || null; }
    get gccNumberFilterControl() { return this.searchForm.get('gccNumber') || null; }
    get identifiersFilterControl() { return this.searchForm.get('identifiers') || null; }
    get shadowAccountFilterControl() { return this.searchForm.get('shadowAccount') || null; }
    get showExchangeFilters() { return this.microsoftPropertyFilterControl.value === 'Exchange'; }

    constructor(
        private statusService: RequestStatusService,
        private dialog: MatDialog,
        private msalService: MsalService,
        private route: ActivatedRoute,
        private aiLogService: AiLoggingService
    ) {
    }

    ngOnInit() {
        this.aiLogService.operationStart();
        this.aiLogService.logPageView('Request Status Summary');
        this.setupSearchForm();
        this.dataSource = new RequestStatusSummaryDataSource(this.statusService);
        this.dataSource.loadData('desc', this.buildSearchModel(), 0, 25);
        this.setupSearchDebounce(this.submitterFilterControl, this.gccNumberFilterControl, this.identifiersFilterControl,
            this.shadowAccountFilterControl);
        this.setupMicrosoftPropertyStatusChanges();
    }

    setupMicrosoftPropertyStatusChanges() {
        this.microsoftPropertyFilterControl.valueChanges
            .pipe(distinctUntilChanged())
            .pipe(takeUntil(this.ngUnsub))
            .pipe(tap(() => this.manageExchangeStatusOptions()))
            .subscribe();
    }

    manageExchangeStatusOptions() {
        if (this.showExchangeFilters) {
            this.availableStatuses = _.union(this.availableStatuses, ['ReadyForValidation', 'Publishing', 'Published'])
        } else {
            this.availableStatuses = _.without(this.availableStatuses, 'ReadyForValidation', 'Publishing', 'Published')
            this.currentStatusFilterControl.setValue('Any');
        }
    }
    ngAfterViewInit() {
        // load new page on sort or paginate
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(() => this.submitSearch())).pipe(
                takeUntil(this.ngUnsub))
            .subscribe();

        this.route.queryParamMap.subscribe((params: ParamMap) => {
            if (params.has('microsoftProperty')) {
                const routeMsProperty = params.get('microsoftProperty');

                const ndx = this.microsoftProperties.findIndex(element => {
                    return element.toLowerCase() === routeMsProperty.toLowerCase();
                });

                if (ndx != -1) {
                    this.microsoftPropertyFilterControl.setValue(this.microsoftProperties[ndx]);
                    this.submitSearch();
                }
            }
        });
    }

    setupSearchDebounce(...controls: AbstractControl[]) {
        controls.forEach(control => {
            control.valueChanges
                .pipe(debounceTime(300), tap(() => {
                    this.paginator.pageIndex = 0;
                    this.submitSearch();
                })).pipe(
                    takeUntil(this.ngUnsub))
                .subscribe();
        });
    }

    buildSearchModel(): RequestStatusSearch {
        const searchModel: RequestStatusSearch = {
            microsoftProperty: this.microsoftPropertyFilterControl.value,
            currentStatus: this.currentStatusFilterControl.value,
            submitter: this.submitterFilterControl.value.replace("@microsoft.com", ""),
            identifiers: String(this.identifiersFilterControl.value).replace(' ', '').split(','),
            gccNumber: this.gccNumberFilterControl.value,
            shadowAccount: this.shadowAccountFilterControl.value
        };

        return searchModel;
    }

    setupSearchForm() {
        this.searchForm = new FormGroup({
            microsoftProperty: new FormControl(this.microsoftProperties[0], Validators.required),
            currentStatus: new FormControl(this.availableStatuses[0], Validators.required),
            submitter: new FormControl(''),
            identifiers: new FormControl(''),
            gccNumber: new FormControl(''),
            shadowAccount: new FormControl('')
        });

        this.submitterFilterControl.setValue(this.msalService.instance.getActiveAccount().username.replace("@microsoft.com", ""));
    }

    openDetails(request: RequestStatusSummary) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;

        if (request.microsoftProperty == "Skype" && request.capability == "SkypeChatContent") {
            const dialogRef: MatDialogRef<SkypeContentDetailsComponent> = this.dialog.open(SkypeContentDetailsComponent, dialogConfig);
            const instance = dialogRef.componentInstance;
            instance.reqStatusSummary = request;
        }
        else {
            const dialogRef: MatDialogRef<RequestStatusDetailsComponent> = this.dialog.open(RequestStatusDetailsComponent, dialogConfig);
            const instance = dialogRef.componentInstance;
            instance.reqStatusSummary = request;
        }
    }

    openDeliveryReview(request: RequestStatusSummary) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        const dialogRef: MatDialogRef<DeliveryReviewComponent> = this.dialog.open(DeliveryReviewComponent, dialogConfig);
        const instance = dialogRef.componentInstance;
        instance.reqStatusSummary = request;
    }

    openPreservationReview(request: RequestStatusSummary) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        const dialogRef: MatDialogRef<PreservationReviewComponent> = this.dialog.open(PreservationReviewComponent, dialogConfig);
        const instance = dialogRef.componentInstance;
        instance.reqStatusSummary = request;
    }

    submitSearch() {
        // this.dataSource.loadData(this.sort.direction, this.microsoftPropertyFilterElement.nativeElement.value,
        this.dataSource.loadData(this.sort.direction, this.buildSearchModel(),
            this.paginator.pageIndex, this.paginator.pageSize);
    }
}
