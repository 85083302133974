
import { ProxyScriptRequest } from "dauthservice-new";

export class TorusSubmissionResponse {
    error: boolean;
    errorMessage: string;
    retryable: boolean;
    request: ProxyScriptRequest;
    requestType: string;
    requestId: string;
}
