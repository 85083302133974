import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { RegionsService } from 'src/app/lens-request/services/regions.service';
import { ProcessingRegion } from 'src/app/lens-request/models/processing-region.model';


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'region-picker',
  templateUrl: './region-picker.component.html',
  styleUrls: ['./region-picker.component.scss']
})
export class RegionPickerComponent implements OnInit, OnDestroy {
  @Input()
  parent: FormGroup;
  @Input()
  set requestType(type: string) {
    if (type) {
      this._requestType = type;
      this.resetForm();

      if (this.form) {
        this.shouldDisplayUpdate();
        this.stripIreland();
      }
    }
  }

  @Input()
  set msProperty(property: string) {
    this._msProperty = property;
    this.stripIreland();
  }


  @Input()
  set exchangeProcessingRegions(regions: ProcessingRegion[]) {
    if (regions) {
      this._exchangeProcessingRegions = regions;
      if (this.form) {
        this.shouldDisplayUpdate();
        this.stripIreland();
      }
    }
  };
  @Input() loading: boolean;

  form: FormGroup;
  processingRegion: string;
  ngUnsub = new Subject<void>();
  _requestType: string;
  _msProperty: string;
  _exchangeProcessingRegions: ProcessingRegion[];
  _displayExchangeProcessingRegions: ProcessingRegion[];
  @Input()
  skypeEuropeProcessingRegions: { [key: string]: string[] };

  get isExchange(): boolean {
    return this._msProperty.toLowerCase() == 'exchange';
  }

  get isHistorical(): boolean {
    return this._requestType && this._requestType.toLowerCase() == 'historical';
  }

  get processingRegionControl(): FormControl {
    return this.form.get("processingRegion") as FormControl;
  }

  get processingRegions() {
    return this.form.get("processingRegions").value;
  }

  get isSkypeEurope(): boolean {
    if(this._msProperty && this._requestType){
      if ((this._msProperty.toLowerCase() == 'skype' || this._msProperty.toLowerCase() == 'teams') && this._requestType.toLowerCase() == 'realtime') {
        return true;
      }
      return false;
    }
  }

  get showAltRegions(): boolean {
    if (this.isSkypeEurope) {
      return this.processingRegions.length > 0;
    }
    return false;
  }

  constructor(
    private aiLogService: AiLoggingService,
    private regionService: RegionsService
  ) { }

  ngOnInit() {
    this.setupForm();
    if (this.parent) {
      this.parent.addControl('regions', this.form);
    }
    this.aiLogService.logEvent("Load RegionPicker component");
  }

  captureSearchRegions(): boolean {
    return this.isExchange && this.isHistorical;
  }

  shouldDisplay() {
    return this.isExchange || this.isSkypeEurope;
  }

  ngOnDestroy() {
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }

  stripIreland() {
    if (this._exchangeProcessingRegions && this._msProperty && this._requestType) {
      if (this._msProperty.toLowerCase() !== 'exchange' ||
          (this._msProperty.toLowerCase() === 'exchange' &&
          (this._requestType.toLowerCase() !== 'historical' && this._requestType.toLowerCase() !== 'preservation'))) {
            this._displayExchangeProcessingRegions = _.filter(this._exchangeProcessingRegions, function(region) {
              return region.RegionName !== 'IE';
          });
      } else {
        this._displayExchangeProcessingRegions = this._exchangeProcessingRegions;
      }
    }
  }

  shouldDisplayUpdate() {
    if (this.shouldDisplay()) {
      // if it is an exchange historical search, add the preservation regions to search
      if (this.captureSearchRegions()) {
        if (this._displayExchangeProcessingRegions) {
          this._displayExchangeProcessingRegions.map((o, i) => {
            const control = new FormControl(false);
            (this.form.controls.searchRegions as FormArray).push(control);
          });
        }
      }
      else if (this.isSkypeEurope) {
        // If Skype Europe, we set Skype Europe regions as a form array and display the options that correlate with the selected jurisdiction.
        const control = this.parent.get("jurisdiction").get("jurisdiction");
        if (control) {
          // We subscribe to the jurisdiction so they can pick different ones and we display the correlated values 
          control.valueChanges.pipe(takeUntil(this.ngUnsub)).subscribe((jurisdiction: string) => {
            // If the user goes back and chooses exchange, we don't want to go removing their control and adding our own. 
            if (this.isSkypeEurope) {
              this.setupSkypeEuropeForm(jurisdiction);
            }
          });
          this.setupSkypeEuropeForm(control.value);
        }
      }
    } else {
      // If the user navigates back to a form combination that does not require a region, remove any prior selection.
      this.form.reset();
    }
  }

  isSelected(region: ProcessingRegion) {
    const keys = _.keys(this.form.value);
    return keys.indexOf(region.RegionName) > -1;
  }

  regionExistsInForm(regionName: string): boolean {
    const selectedRegions = _.keys(this.form.value);
    return selectedRegions.indexOf(regionName) > -1;
  }

  addControl(key: string, value: string) {
    this.form.addControl(key, new FormArray([new FormControl(value)]));
  }

  updateFormRegion(region: ProcessingRegion) {
    if (!this.regionExistsInForm(region.RegionName)) {
      this.addControl(region.RegionName, region.RegionDescription);
    } else {
      this.form.removeControl(region.RegionName);
    }
  }

  setDefaultSearchRegions($event: any) {
    if (this.captureSearchRegions()) {
      const selectedRegionIndex = _.findIndex(this._displayExchangeProcessingRegions, function (r) { return r.RegionName == $event; });

      // remove all previous selections
      this.resetForm();

      if (selectedRegionIndex > -1) {
        // add the defaults for the selected processing region
        this._displayExchangeProcessingRegions[selectedRegionIndex].DefaultSearchRegions.map((o, i) => {
          this.addControl(o.RegionName, o.RegionDescription);
        });
      } 
    }
  }

  resetForm() {
    if (this._displayExchangeProcessingRegions && this.form) {
      this._displayExchangeProcessingRegions.map((o, i) => {
        if (this.regionExistsInForm(o.RegionName)) {
          this.form.removeControl(o.RegionName);
        }
      });
    }
  }

  private setupSkypeEuropeForm(jurisdiction: string) {
    // If we were using a different jurisdiction, let's yank those corresponding regions out.
    if (this.form.get("processingRegions")) {
      this.form.removeControl("processingRegions");
    }
    // Add a new Form Array
    this.form.addControl("processingRegions", new FormArray([]));

    if (this.skypeEuropeProcessingRegions[jurisdiction]) {
      // Push the values into that array. 
      this.skypeEuropeProcessingRegions[jurisdiction].map(region => {
        (this.form.get("processingRegions") as FormArray).push(new FormControl(region))
      });
    }
  }

  private setupForm() {
    this.form = new FormGroup({
      processingRegion: new FormControl(
        this.processingRegion,
        Validators.required
      ),
      searchRegions: new FormArray([])
    }, this.AtLeastOneSearchRegionSelected);
  }

  AtLeastOneSearchRegionSelected = (formToValidate: FormGroup): { [key: string]: boolean } => {
    let atLeastOneSelected = false;
    if (!this.captureSearchRegions()) {
      atLeastOneSelected = true;
    } else {
      var keys = Object.keys(formToValidate.controls);
      keys.map((o, i) => {
        if (o != 'processingRegion' && o != 'searchRegions') {
          atLeastOneSelected = true;
        }
      });
    }

    return atLeastOneSelected ? null : { noneSelected: true };
  }
}
