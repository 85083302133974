import { CustomerTenantTypeEnum } from 'src/app/services/delivery-review.service';

export class IdentifierInfo {
    id: string;
    isSelected: boolean;
    isConsumerSelected: boolean;
    isEnterpriseSelected: boolean;
    customerTenantType: CustomerTenantTypeEnum;
 
    constructor(id: string, isSelected: boolean, isConsumerSelected: boolean, isEnterpriseSelected: boolean, customerTenantType: CustomerTenantTypeEnum){
      this.id = id;
      this.isSelected = isSelected;
      this.isConsumerSelected = isConsumerSelected;
      this.isEnterpriseSelected = isEnterpriseSelected;
      this.customerTenantType = customerTenantType;
    }
}
  