<!DOCTYPE html>
<div class="router-outlet">
  <mat-tab-group (selectedTabChange)='selectedTabChange($event)'>
    <mat-tab label="Submit">
      <form [formGroup]="searchForm">
        <div class="account-lookup-search-form">
          <mat-list>
            <mat-list-item>
              <mat-radio-group class="lookup-radio-group" formControlName="lookupType" ngDefaultControl
                (change)="setLookupTypeDisableFlag()" [(ngModel)]="defaultLookupType">
                <mat-radio-button class="lookup-radio-button" *ngFor="let lookupTypeItem of lookupTypeList"
                  [value]="lookupTypeItem">{{lookupTypeItem}}</mat-radio-button>
              </mat-radio-group>
            </mat-list-item>
          </mat-list>
          <mat-form-field id="search-box-container">
            <textarea id="search-box" matInput placeholder="Search for Account(s)" matTextareaAutosize 
              matAutosizeMaxRows="10" formControlName="identifiers">
              </textarea>
          </mat-form-field>
          <mat-list>
            <mat-list-item *ngIf="!searchForm.controls.crmRequestId.disabled">
              <mat-form-field>
                <mat-label>LNS Number</mat-label>
                <input matInput formControlName="crmRequestId">
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <button mat-raised-button (click)="submitData()" color='primary'
                [disabled]="searchForm?.invalid || isSubmitting">
                <span>Submit</span>
                <mat-icon *ngIf="isSubmitting" style="margin-left:5px;">
                  <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
                </mat-icon>
              </button>
            </mat-list-item>
          </mat-list>
          <div class="skype-account-lookup-results">
            <div *ngIf="isMaxSkypeResults">
              <p style="padding-bottom:10px; color: rgba(100, 13, 13, 0.788)">
                A maximum of {{ maxSkypeResults }} identifiers may be submitted for a single lookup. Please reduce the
                number of entries.
              </p>
            </div>
            <mat-table *ngIf="isSkypeAccountListPopulated()" [dataSource]="skypeAccountDetails | async"
              id="skype-account-lookup-table" matSort matSortActive="Identifier" matSortDirection="asc">
              <ng-container matColumnDef="identifier">
                <mat-header-cell *matHeaderCellDef> Identifier </mat-header-cell>
                <mat-cell class="skype-lookup-details" *matCellDef="let row"> {{ row.Identifier }} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="identifierType">
                <mat-header-cell *matHeaderCellDef> Identifier Type </mat-header-cell>
                <mat-cell class="skype-lookup-details" *matCellDef="let row"> {{ row.IdentifierType }} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="skypeId">
                <mat-header-cell *matHeaderCellDef> Skype ID </mat-header-cell>
                <mat-cell class="skype-lookup-details" *matCellDef="let row"> {{ row.SkypeId }} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="msaLinkedAccount">
                <mat-header-cell *matHeaderCellDef> MSA Linked Account </mat-header-cell>
                <mat-cell class="skype-lookup-details" *matCellDef="let row"> {{ row.MsaLinkedAccount }} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="puid">
                <mat-header-cell *matHeaderCellDef> Passport User ID </mat-header-cell>
                <mat-cell class="skype-lookup-details" *matCellDef="let row"> {{ row.Puid }} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="netId">
                <mat-header-cell *matHeaderCellDef> Net ID </mat-header-cell>
                <mat-cell class="skype-lookup-details" *matCellDef="let row"> {{ row.NetId }} </mat-cell>
              </ng-container>
              <ng-container matColumnDef="skypeLookupStatus">
                <mat-header-cell *matHeaderCellDef class="mat-cell-offset-header"> Status </mat-header-cell>
                <mat-cell class="skype-lookup-details" *matCellDef="let row">
                  <button *ngIf="isSkypeLookupError(row)" (click)="openSkypeErrorDialog(row)" mat-button
                    color="primary">
                    {{ row.Status }}
                  </button>
                  <div *ngIf="!isSkypeLookupError(row)"> {{ row.Status }} </div>
                </mat-cell>
              </ng-container>
              <mat-header-row *matHeaderRowDef="skypeAccountDetailsDisplayColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: skypeAccountDetailsDisplayColumns;" class="details-row"></mat-row>
            </mat-table>
          </div>
        </div>
      </form>
    </mat-tab>
    <mat-tab label="Results">
      <form [formGroup]="filterForm" class="request-status-page">
        <mat-grid-list class="filter-grid" cols="5" rowHeight="60px">
          <mat-grid-tile class="filter-grid-tile">
            <mat-form-field class="filter-field" [floatLabel]='filterFloatLabelType'>
              <mat-label>Submitter</mat-label>
              <input formControlName="submittedBy" matInput placeholder="username" />
            </mat-form-field>
          </mat-grid-tile>
          <!-- have to set the style with the attribute since the css classs is overwritten by the mat library -->
          <mat-grid-tile class="filter-grid-tile" style="margin-left:15px; padding-top:0;">
            <div>
              <p
                style="font-size:12px; font-weight:200; margin:-10px 0 10px 0; padding:0; line-height: 8px; color: rgba(0, 0, 0, 0.54)">
                Expired</p>
              <mat-checkbox (change)="updateIncludeExpired()" style="margin-top:0; padding-top:0; line-height: 12px;">
                Include Expired Lookups</mat-checkbox>
            </div>
          </mat-grid-tile>
          <mat-grid-tile class="filter-grid-tile">
            <div>
              <button mat-raised-button (click)="refresh()" [disabled]="(loadingSubject | async)">
                <span>Refresh</span>
                <mat-icon *ngIf="(loadingSubject | async)" style="margin-left: 5px;">
                  <mat-progress-spinner mode="indeterminate" diameter="20"></mat-progress-spinner>
                </mat-icon>
              </button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </form>
      <mat-table [dataSource]="statusList | async" id="account-lookup-table" matSort matSortActive="accountLookupId"
        matSortDirection="desc">
        <ng-container matColumnDef="accountLookupId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> ID </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.accountLookupId }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="submittedBy">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Submitter </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.submittedBy }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="crmRequestId">
          <mat-header-cell *matHeaderCellDef mat-sort-header> LNS Number </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.crmRequestId }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="submittedOn">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Submitted Date </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.submittedOn | date:'M/d/yyyy hh:mm a ZZ' }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.status }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef class="mat-cell-offset-header">Details </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-button color="primary" *ngIf="isDetailsVisible(row)" (click)="openDetails(row)"
              [disabled]="isDetailsDisabled(row)">
              Details
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="accountLookupRequestsDisplayColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: accountLookupRequestsDisplayColumns;"></mat-row>
      </mat-table>
      <div class="no-results" *ngIf="resultsSize === 0">No matching results found for submitter [{{
        submittedByFilterControl.value }}]</div>
      <mat-paginator #paginator [length]="resultsSize" [pageIndex]="0" [pageSize]="25"
        [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </mat-tab>
    <mat-tab label="Details" *ngIf='selectedRequest'>
      <mat-table [dataSource]="selectedRequest.details" id="account-lookup-details-table">
        <ng-container matColumnDef="flag">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row"><i *ngIf="row.flagBothConsumerAndEnterprise"
              class="material-icons both-flag">flag</i></mat-cell>
        </ng-container>
        <ng-container matColumnDef="account">
          <mat-header-cell *matHeaderCellDef> Account </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-list>
              <mat-list-item class="datacenter-item">{{ row.account }} <span
                  *ngIf="row.isPrimary && row.aliases.length > 0" class="primary-alias"> (primary identifier)</span>
              </mat-list-item>
              <mat-list-item *ngFor="let alias of row.aliases" class="aka-identifier">AKA: {{ alias.aliasName }} <span
                  *ngIf="alias.isPrimary" class="primary-alias"> (primary identifier)</span></mat-list-item>
            </mat-list>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="forceEnterprise">
          <mat-header-cell *matHeaderCellDef> Account Type </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.forceEnterprise ? "Enterprise" : "Consumer" }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.status }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="isCustomerLockbox">
          <mat-header-cell *matHeaderCellDef> Customer Lockbox </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.isComplete ? row.lockboxStatus : '' }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="exists">
          <mat-header-cell *matHeaderCellDef> Details </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.statusMessage }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="dataCenterLocations">
          <mat-header-cell *matHeaderCellDef class="mat-cell-offset-header">Data Centers </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-list *ngIf="row.isComplete">
              <mat-list-item class="datacenter-item" *ngFor="let dataCenter of row.dataCenterLocations.split('|')">
                {{ dataCenter }}
              </mat-list-item>
            </mat-list>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hasPreservations">
          <mat-header-cell *matHeaderCellDef class="mat-cell-offset-header">Has Preservations</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.hasPreservations}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="isArchiveProvisioned">
          <mat-header-cell *matHeaderCellDef class="mat-cell-offset-header">Archived Content</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.isArchiveProvisioned}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="hasSoftDeletedMailbox">
          <mat-header-cell *matHeaderCellDef class="mat-cell-offset-header">Soft-Deleted Content</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.hasSoftDeletedMailbox}} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="accountLookupRequestDetailsDisplayColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: accountLookupRequestDetailsDisplayColumns; when: accountExists"
          class="details-row"></mat-row>
        <mat-row *matRowDef="let row; columns: accountLookupRequestDetailsNonExistDisplayColumns; when: !accountExists"
          class="details-row"></mat-row>
      </mat-table>
    </mat-tab>
  </mat-tab-group>
</div>
