import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class NavItemComponent {

  @Input() icon = '';
  @Input() hint = '';
  @Input() externalLink: string = '';
  @Input() routerLink: string | any[] = '/';

  constructor() { }
}
