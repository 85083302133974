import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { DataField } from '../../models/data-field.model';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'


@Component({
  selector: "data-fields",
  templateUrl: "./data-fields.component.html",
  styleUrls: ["./data-fields.component.scss"]
})
export class DataFieldsComponent implements OnInit {
  @Input()
  set parent(parent: FormArray) {
    this.form = parent;
  }

  @Input()
  set dataFields(dataFields: DataField[]) {
    if (dataFields) {
      this._dataFields = dataFields;
      this.formName = dataFields[0].type;
      this.updateFormPreSelectedDataField(dataFields);            
    }
  }

  form: FormArray;
  formName: string;
  _dataFields: DataField[];

  constructor(
    private aiLogService: AiLoggingService
  ) {
  }

  ngOnInit() {
    this.setupForm();  
    this.aiLogService.logEvent("Load DataFields component");  
  }

  private setupForm() {
    // In case this isn't being given a parent.
    if (!this.form) {
      this.form = new FormArray([]);
    }
  }

  updateFormPreSelectedDataField(dataFields: DataField[]) {
    if (dataFields) {
      dataFields.map(dataField => {
        if (dataField.isSelected) {
          this.updateFormDataField(dataField);
        }
      });
    }
  }

  dataFieldExistsInForm(dataField: DataField) {
    const index = this.form.value.indexOf(dataField.serverName);
    return index > -1;
  }

  updateFormDataField(dataField: DataField) {
    const index = this.form.value.indexOf(dataField.serverName);

    if (index > -1) {
      this.form.removeAt(index);
    } else {
      this.form.push(new FormControl(dataField.serverName));
    }
    if(this.form.pristine){
      // We don't want the "you must select a datafield!" warning to pop up for this tab until we've at least loaded some fields
      this.form.markAsDirty();
    }
  }
  
  updateFormDataFieldAll(selectAll: boolean) {
    if (selectAll) {
      this._dataFields.map(dataField => {
        // if it's not disabled and already in the form
        if (!dataField.isDisabled && !this.dataFieldExistsInForm(dataField)) {
          this.updateFormDataField(dataField);
        }
      });
    } else {
      this._dataFields.map(dataField => {
        // if it's not disabled and is in the form
        if (!dataField.isDisabled && this.dataFieldExistsInForm(dataField)) {
          this.updateFormDataField(dataField);
        }
      });
    }
  }
}
