import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { faLinkedin, faMicrosoft, faSkype } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faCloud, faCoffee, faDollarSign, faGamepad, faSignInAlt, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { AiLoggingService } from '../../../shared/services/ai-logging.service';
import { MicrosoftProperty } from "../../models/microsoft-property.model";

@Component({
  selector: "microsoft-properties",
  templateUrl: "./microsoft-properties.component.html",
  styleUrls: ["./microsoft-properties.component.scss"]
})
export class MicrosoftPropertiesComponent
  implements OnInit {
  @Input() msProperties: MicrosoftProperty[];
  @Input() loaded: boolean;
  @Input() loading: boolean;
  @Input() parent: FormGroup;

  @Output() selectionMade = new EventEmitter()

  get filteredMsProperties(): MicrosoftProperty[] {
    return this.msProperties ? this.msProperties.filter(x => !x.disabled) : this.msProperties;
  }

  icons: { [key: string]: IconDefinition } = 
        { 
          'coffee': faCoffee, 
          'Skype': faSkype, 
          'Exchange': faEnvelope, 
          'OneDrive': faCloud, 
          'MSA': faSignInAlt, 
          'Xbox': faGamepad, 
          'Commerce': faDollarSign,
          'LinkedIn': faLinkedin,
          'Teams': faMicrosoft,
        };  

  form: FormGroup;
  controlName = "msProperty";
  constructor(
    private aiLogService: AiLoggingService
  ) {
  }

  ngOnInit() {
    this.form = new FormGroup({
      msProperty: new FormControl("", Validators.required)
    });
    // if we're given a parent, let's attach our form to it.
    if (this.parent) {
      this.parent.addControl(this.controlName, this.form.get(this.controlName));
    }

    this.onChanges();
    this.aiLogService.logEvent("Load MicrosoftProperties component");
  }

  onChanges(){
    this.form.get(this.controlName).statusChanges.subscribe(status => {
      if(status == "VALID"){
        this.selectionMade.emit();
      }
    })
  }

  tooltipDisplay(property: MicrosoftProperty) {
    return property.disabled
      ? "Querying this property is not yet available"
      : "";
  }
}
