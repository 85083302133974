import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Moment } from 'moment';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'
import { CustomerTenantTypeEnum } from 'src/app/services/delivery-review.service';
import { CustomerTenantType, IdentifierModel } from '../../models/new-request.model';
import { emailIdentifier, phoneNumberIdentifier } from '../../validators/identifier.regex';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'request-review',
  templateUrl: './request-review.component.html',
  styleUrls: ['./request-review.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestReviewComponent implements OnInit {
  @Input() parent: FormGroup;
  @Output() submitForm = new EventEmitter();

  getKeys = _.keys;
  getValues = _.values;
  form: FormGroup;
  hasWarnings: boolean = false;

  get capabilities() {
    const control = this.form.get('capabilities');
    return control ? control.value : null;
  }
  get crmIdentifier() {
    const control = this.parent.get('crmIdentifier');
    return control ? control.value : null;
  }

  get crmIdentifiers() {
    const identifiersControl = this.form.get('identifiers');
    if (identifiersControl) {
      return identifiersControl.get('crmIdentifiers').value;
    }
    return null;
  }

  get isExchange() {
    return this.msProperty.toLowerCase() == 'exchange';
  }

  get isSkypeLiRequest() {
    const msp = this.msProperty.toLowerCase();
    return msp === 'skype' || msp === 'teams';
  }

  get isHistorical() {
    return this.requestType.toLowerCase() == 'historical';
  }

  hasConsumerAndEnterprise(identifier) {
    return !!identifier.customerTenantType
  }

  get isSkypeEurope() {
    return (
      (this.msProperty.toLowerCase() == 'skype' || this.msProperty.toLowerCase() == 'teams') &&
      this.requestType.toLowerCase() == 'realtime'
    );
  }

  get startDate() {
    const control = this.getDate('startDate');
    return control ? control.format('lll') : null;
  }

  get endDate() {
    const control = this.getDate('endDate');
    return control ? control.format('lll') : null;
  }

  get dates() {
    return this.form.get('dates') as FormGroup;
  }

  get requestType() {
    const control = this.form.get('requestType');
    return control ? control.value : false;
  }

  get msProperty() {
    const control = this.form.get('msProperty');
    return control ? control.value : null;
  }

  get realTimeRequest() {
    const control = this.form.get('requestType');
    if (control) {
      return control.value.toLowerCase() === 'realtime';
    } else {
      return false;
    }
  }

  get proccessingRegion() {
    const control = this.form.get('regions').get('processingRegion');
    const controlValue = control ? control.value : null;

    return controlValue ? controlValue : null;
  }

  get showSearchRegions(): boolean {
    return this.isExchange && this.isHistorical;
  }

  get searchRegions() {
    let result: string = '';
    const regionForm = (this.form.get('regions') as FormGroup);
    var keys = Object.keys(regionForm.controls);
    keys.map((o, i) => {
      if (o != 'processingRegion' && o != 'searchRegions') {
        result = result + (result.length > 0 ? ', ' : '') + regionForm.get(o).value;
      }
    });

    return result;
  }

  get emailFilter() {
    const control = this.form.get('emailFilter');
    return control ? control.get('emailFilter').value : null;
  }
  get jurisdiction() {
    const control = this.form.get('jurisdiction');
    return control ? control.get('jurisdiction').value : null;
  }

  constructor(
    private aiLogService: AiLoggingService
  ) {
  }

  ngOnInit() {
    if (this.parent) {
      this.form = this.parent;
    } else {
      this.form = new FormGroup({});
    }
  }

  getDate(name: string): Moment {
    if (name && this.dates) {
      const control = this.dates.get(name);
      return control ? (control.value as Moment) : null;
    } else {
      return null;
    }
  }

  getFormValues(controlName: string) {
    if (controlName) {
      return this.form.get(controlName).value;
    }
  }

  getDataFields(capability: string) {
    if (this.isExchange || capability === 'SkypeChatMessages' || capability === 'IncludeSkypeAttachments') {
      return '';
    }
    const dataFields = this.getValues(this.capabilities[capability]);
    const cleanFields = _.reject(dataFields, function(c){return c.toLowerCase() == capability.toLowerCase()})
    return cleanFields.join(', ');
  }

  hasDataFields(capability: string) {
    return this.getDataFields(capability).length > 0;
  }

  getTenantTypeName(customerTenantType: number) {
    return CustomerTenantTypeEnum[customerTenantType];
  }

  getCapabilityCaption(capability: string): string {
    const c = undefined;
    return c ? c.displayName : capability;
  }

  containsSkypeIdWarning(identifier): boolean {
    const id = typeof(identifier) === 'string' ? identifier : identifier.identifier;
    const consumer = typeof(identifier) === 'string' ? true : identifier.customerTenantType == CustomerTenantType.Consumer;

    const hasWarning = this.isSkypeLiRequest && consumer && (emailIdentifier(id) || phoneNumberIdentifier(id));
     
    if(hasWarning)
      this.hasWarnings = true;

    return hasWarning;
  }
}
