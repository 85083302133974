import { FormArray, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { CustomerTenantType } from '../models/new-request.model';
import { emailIdentifier } from './identifier.regex';

export const AtLeastOneBoxChecked = (control: FormArray): { [key: string]: boolean } => {
    return control.value.length ? null : { noneSelected: true };
}

export const AtLeastOneBoxCheckedInGroup = (control: FormGroup): { [key: string]: boolean } => {    
    return Object.keys(control.value).length ? null : { noneSelected: true };
}

export const AtLeastOneInGroup = (control: FormGroup): { [key: string]: boolean } => {
    const keys = _.keysIn(control.value);
    return keys.length > 0 ? null : { noneSelected: true };
}

export const AtLeastOneIdentifier = (control: FormGroup): { [key: string]: boolean } => {
    const controls = _.valuesIn(control.controls);
    let result = { noneSelected: true }
    // if there's no length, then we don't even have a type yet
    if (controls.length < 1) {
        return result;
    }
    else {
        for (let control of controls) {
            let checkboxes = AtLeastOneBoxChecked(control as FormArray);
            // if any of these pass the validator, then we're good
            if (checkboxes == null)
                return null
        }
        // if not one can pass AtLeastOneBoxChecked, then there's no boxes checked - return our error
        return result;
    }
}

export const ContainsEmailIdentifier = (control: FormGroup): { [key: string]: boolean } => {
    const controls = _.valuesIn(control.controls);
    
    const emailCtrls = controls.filter(c => c.value.filter(x => typeof(x) === 'string' 
            ? emailIdentifier(x)
            : x.customerTenantType == CustomerTenantType.Consumer && emailIdentifier(x.identifier)
        ).length > 0)

    // At least one identifier contains an email address:
    return emailCtrls.length > 0 ? { hasEmail: true } : null;
}