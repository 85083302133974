<!-- Status results: 0 Draft, 1 submitting, 2 submitted -->

<div class="result-page" *ngIf="(submissionStatus | async) as _submissionStatus">
    <div *ngIf="_submissionStatus == 1" >
        <h2>Submitting your request, please wait...</h2>
        <mat-progress-spinner      
        mode="indeterminate"> 
      </mat-progress-spinner>
    </div>
      
    <div *ngIf="_submissionStatus == 2">  
      <h2>
        Your request for CRM Ticket Number &quot;{{ crmTicketNumber }}&quot; was successfully submitted! 
      </h2>  
      <h3>
        Choose an option below to <strong>Submit Another Request</strong> for the same CRM Ticket or to <strong>View Request Status</strong>.
      </h3>
      <button mat-raised-button color="primary" (click)=refreshPage()>Submit Another Request</button>
      <button mat-raised-button color="primary" (click)=redirect()>View Request Status</button>
    </div>
  </div>