import { Routes } from '@angular/router';
import { RequireAuthGuard } from 'src/app/authentication/require-auth.guard';
import { AccountLookupComponent } from 'src/app/components/account-lookup/account-lookup.component';
import { HomeComponent } from 'src/app/components/home/home.component';
import { LensRequestCreatorComponent } from './lens-request/containers/lens-request-creator/lens-request-creator.component';
import { RequestStatusSummaryComponent } from 'src/app/components/request-status-summary/request-status-summary.component';
import { CollectionPointComponent } from './components/collection-point/collection-point.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';

export const routes: Routes = [
  { path: 'auth', component: MsalRedirectComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'account-lookup', component: AccountLookupComponent, canActivate: [RequireAuthGuard] },
  { path: 'new-request', component: LensRequestCreatorComponent, canActivate: [RequireAuthGuard] },
  { path: 'request-status-summary', component: RequestStatusSummaryComponent, canActivate: [RequireAuthGuard] },
  { path: 'collection-point', component: CollectionPointComponent, canActivate: [RequireAuthGuard] },
  { path: 'publish', loadChildren: () => import('./publish/publish.module').then(m => m.PublishModule) },
  { path: '**', redirectTo: 'home' }
];
