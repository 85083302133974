import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BaseService } from '../../shared/services/base.service';
import { DataField } from '../models/data-field.model';

@Injectable()
export class DataFieldsService {
  dataFields: { [key: string]: DataField[] } = {};
  observables: { [key: string]: Observable<any> } = {};

  constructor(protected baseService: BaseService) {}

  public getDataFields(forCapability: string, forMsProperty: string, forRequestType: string): Observable<DataField[]> {
    // Check if it's already cached here
    if (this.dataFields[forCapability]) {
      return of(this.dataFields[forCapability]);
    } else if (this.observables[forCapability]) {
      // Check if we're already fetching this data
      return this.observables[forCapability];
    } else {
      this.observables[forCapability] = this.baseService
        .get(
          environment.webApiConfig.serverUrl +
            environment.dataFieldsUrl +
            "/" +
            forCapability +
            (forMsProperty ? "/" + forMsProperty : "") +
            (forRequestType ? "/" + forRequestType : "")
        )
        .pipe(
          map(response => {
            // reset the "we're fetching" for this capability
            this.observables[forCapability] = null;
            const parsedResponse = DataField.fromJSONArray(
              response,
              forCapability
            );
            this.dataFields[forCapability] = parsedResponse;
            return of(parsedResponse);
          }),
          catchError(error => this.baseService.handleError(error))
        );
    }
  }
}
