import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmailFilterComponent } from '../lens-request/components/email-filter/email-filter.component';
import { CrmIdentifierService } from '../lens-request/services/crmidentifier.service';
import { SharedModule } from '../shared/shared.module';
import { CapabilitiesComponent } from './components/capabilities/capabilities.component';
import { DataFieldsComponent } from './components/data-fields/data-fields.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { IdentifiersComponent } from './components/identifiers/identifiers.component';
import { JurisdictionPickerComponent } from './components/jurisdiction-picker/jurisdiction-picker.component';
import { MicrosoftPropertiesComponent } from './components/microsoft-properties/microsoft-properties.component';
import { RegionPickerComponent } from './components/region-picker/region-picker.component';
import { RequestResultsComponent } from './components/request-results/request-results.component';
import { RequestReviewComponent } from './components/request-review/request-review.component';
import { TypeSelectionComponent } from './components/type-selection/type-selection.component';
import { CapabilitiesSelectorComponent } from './containers/capabilities-selector/capabilities-selector.component';
import { LensRequestCreatorComponent } from './containers/lens-request-creator/lens-request-creator.component';
import { CapabilitiesService } from './services/capabilities.service';
import { DataFieldsService } from './services/data-fields.service';
import { MicrosoftPropertiesService } from './services/microsoft-properties.service';
import { RegionsService } from './services/regions.service';
import { RequestSubmitterService } from './services/request-submitter.service';
import { AiLoggingService } from '.././shared/services/ai-logging.service'

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    CapabilitiesComponent,
    CapabilitiesSelectorComponent,
    DataFieldsComponent,
    DatepickerComponent,
    EmailFilterComponent,
    IdentifiersComponent,
    JurisdictionPickerComponent,
    MicrosoftPropertiesComponent,
    LensRequestCreatorComponent,
    RegionPickerComponent,
    RequestResultsComponent,
    RequestReviewComponent,
    TypeSelectionComponent
  ],
  providers: [
    CapabilitiesService,
    CrmIdentifierService,
    DataFieldsService,
    MicrosoftPropertiesService,
    RequestSubmitterService,
    RegionsService,
    AiLoggingService
  ],
  exports: [LensRequestCreatorComponent]
})
export class LensRequestModule {}
