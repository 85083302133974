import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IpLookupService } from '../../services/ip-lookup.service';
import { IpLocation } from '../../models/ip-location.model';
import { IpLogin } from 'src/app/models/ip-login.model';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ip-lookup-details',
  templateUrl: './ip-lookup-details.component.html',
  styleUrls: ['./ip-lookup-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IpLookupDetailsComponent implements OnInit {
  private ipAddresses: IpLogin[];
  public locations: IpLocation[];
  public displayedColumns = ['timestamp', 'ip', 'city', 'state', 'country', 'organization', 'connectionType'];

  public set IpAddresses(_) {
    this.ipAddresses = _;

    if (this.ipAddresses) {
      this.ipLookupService.GetIpLocations(this.ipAddresses)
        .subscribe(response => {
          this.locations = response;
        });
    }
    else{
      this.locations = [];
    }
  }

  constructor(private ipLookupService: IpLookupService, private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    if (this.dialogRef) {
      this.dialogRef.updateSize('90%', '80%');
    }
  }
}
