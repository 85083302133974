export class NewRequest {
    startDate: Date = new Date('1/1/1990');
    endDate: Date = new Date();
    crmTicketNumber: string;
    microsoftProperty: string;
    requestType: string;
    identifiers: { [id: string]: IdentifierModel[] } = {};
    capabilities: { [id: string]: string[] } = {};
    separateOutputPerIdentifier: boolean;
    jurisdiction: string;
    processingRegion: string;
    searchRegions: string[] = [];
    emailFilter: string;
    isDetask: boolean;
    isExtend: boolean;
    overrideJurisdictionRestriction: boolean;
  }

export interface IdentifierModel {
  identifier: string;
  customerTenantType: CustomerTenantType
}

export enum CustomerTenantType {
  Unspecified,
  Consumer,
  Enterprise,
  Multiple
}