import { createSelector } from 'reselect';
import { MenuItem } from 'src/app/models/menu-item.model';
import * as menuItem from 'src/app/actions/menu-item';

export interface State {
  menuItemsArray: MenuItem[];
}

export const initialState: State = {
  menuItemsArray: []
};

export function reducer(state = initialState, action: menuItem.Actions): State {
  switch (action.type) {

    // Add a list of menu items to the existing list of menu items.
    case menuItem.ADDED:    
      return Object.assign({}, state, {
        menuItemsArray: [...state.menuItemsArray, ...action.payload]
      } as State);

    default:
      return state;
  }
}

export const getMenuItems = (state: State) => state.menuItemsArray;
