import { ProcessingRegionEnum } from 'src/app/services/delivery-review.service';

export class PreservationRequest {
  calendar: boolean;
  contacts: boolean;
  conversationHistory: boolean;
  createdBy: string;
  crmTicketNumber: string;
  dataCollectionEndDateTime: Date;
  dataCollectionStartDateTime: Date;
  emailContent: boolean;
  emailFilter: string;
  identifier: string;
  identifierType: string;
  isApproved: boolean;
  journal: boolean;
  mailboxIpLogin: boolean;
  mobileDevice: boolean;
  notes: boolean;
  preservationId: number;
  processingRegion: ProcessingRegionEnum;
  registration: boolean;
  submissionDate: Date;
  tasks: boolean;

  constructor(obj: Object){
    this.calendar = obj['Calendar'];
    this.contacts = obj['Contacts'];
    this.conversationHistory = obj['ConversationHistory'];
    this.createdBy = obj['CreatedBy'];
    this.crmTicketNumber = obj['CrmTicketNumber'];
    this.dataCollectionEndDateTime = obj['DataCollectionEndDateTime'];
    this.dataCollectionStartDateTime = obj['DataCollectionStartDateTime'];
    this.emailContent = obj['EmailContent'];
    this.emailFilter = obj['EmailFilter'];
    this.identifier = obj['Identifier'];
    this.identifierType = obj['IdentifierType'];
    this.isApproved = obj['IsApproved'];
    this.journal = obj['Journal'];
    this.mailboxIpLogin = obj['MailboxIpLogin'];
    this.mobileDevice = obj['MobileDevice'];
    this.notes = obj['Notes'];
    this.preservationId = obj['PreservationId'];
    this.processingRegion = obj['ProcessingRegion'];
    this.registration = obj['Registration'];
    this.submissionDate = obj['SubmissionDate'];
    this.tasks = obj['Tasks'];
  }
}