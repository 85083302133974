export class AccountLookupTorusReadyStatus {
    accountIdentifier: string;
    stagingLocationUri: string;
    torusPayloadHash: string;

    static fromJSONArray(array: Array<any>): AccountLookupTorusReadyStatus[] {
      return array.map(obj => new AccountLookupTorusReadyStatus(obj));
    }

    constructor(obj: Object) {
      this.accountIdentifier = obj['AccountIdentifier'];
      this.stagingLocationUri = obj['StagingLocationUri'];
      this.torusPayloadHash = obj['TorusPayloadHash'];
    }
  }
