import { Injectable } from '@angular/core';
import { pluck, share, distinctUntilChanged, catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from "rxjs";
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from "src/environments/environment";

import { ProcessingRegion } from '../models/processing-region.model';

export enum StateSelectors {
  MICROSOFTPROCESSINGREGIONS = "processingRegions",
  LOADING = "loading",
  LOADED = "loaded"
}

export interface State {
  processingRegions: ProcessingRegion[];
  loaded: boolean;
  loading: boolean;
}

const state: State = {
  processingRegions: [],
  loaded: false,
  loading: false
};

@Injectable({ providedIn: 'root' })
export class RegionsService {
  private subject = new BehaviorSubject<State>(state);
  store = this.subject
    .asObservable()
    .pipe(distinctUntilChanged(),
    share());

  constructor(protected baseService: BaseService) { }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  getRegions(): Observable<{ [key: string]: string[] }> {
    return this.baseService.get(environment.webApiConfig.serverUrl + "api/jurisdiction/getmapping");
  }

  public getExchangeRegions(): Observable<ProcessingRegion[]> {
    if (state.processingRegions.length > 0) {
      return this.store.pipe(pluck(StateSelectors.MICROSOFTPROCESSINGREGIONS));
    } else {
      // update the state to loading
      const value = this.subject.value;
      this.subject.next({ ...value, loading: true });
      return this.baseService
        .get(environment.webApiConfig.serverUrl + environment.processingRegionsUrl)
        .pipe(
          map(response => {
            // update the state to loaded, store regions for later to reduce future calls this session
            const value = this.subject.value;
            const newValues = ProcessingRegion.fromJSONArray(response);
            this.subject.next({
              ...value,
              processingRegions: newValues,
              loading: false,
              loaded: true
            });
            return newValues;
          }),
          catchError(error => this.baseService.handleError(error))
        );
    }
  }
}
