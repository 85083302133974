
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { faCalendarAlt, faClock, faSave } from '@fortawesome/free-regular-svg-icons';

// List of request types
const requestTypes = {
    historical: {
        displayName: 'Historical',
        serverName: 'historical',
        icon: faCalendarAlt
    },
    realTime: {
        displayName: 'Real Time',
        serverName: 'realTime',
        icon: faClock,
    },
    preservation: {
        displayName: 'Preservation',
        serverName: 'preservation',
        icon: faSave,
    }
}

export class RequestType {
  displayName: string;
  icon: IconDefinition;  

  static getIcon(name: string): RequestType {
      switch (name.toLowerCase()) {
          case 'historical': {
              return requestTypes.historical;
          }
          case 'realtime': {
              return requestTypes.realTime;
          }
          case 'preservation': {
              return requestTypes.preservation;
          }
          default: {
              return null;
          }
      }
  }
}
