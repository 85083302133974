<div *ngIf="loaded">
  <h2>Microsoft Properties</h2>
  <form [formGroup]="form">
    <mat-radio-group formControlName="msProperty">
      <mat-grid-list cols="1" rowHeight="35px">
        <mat-grid-tile *ngFor="let property of filteredMsProperties" [colspan]="1" [rowspan]="1">
          <div *ngIf="!property.disabled">
            <mat-radio-button [value]="property.name">
              <!-- <fa-icon [icon]="property.icon"></fa-icon> -->
              <fa-icon [icon]='icons[property.name]'></fa-icon>
              {{property.name}}
            </mat-radio-button>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-radio-group>
  </form>
</div>
<mat-progress-spinner *ngIf="loading" mode="indeterminate">
</mat-progress-spinner>