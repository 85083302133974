import { AfterViewInit, Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MsalService } from '@azure/msal-angular';
import { AccountLookupRequest } from 'src/app/models/account-lookup-request.model';
import { AccountLookupSubmitRequest } from 'src/app/models/account-lookup-submit-request.model';
import { SkypeAccountLookupSubmitRequest } from 'src/app/models/skype-account-lookup-submit-request.model';
import { SkypeAccountLookupDetails } from 'src/app/models/skype-account-lookup-details.model';
import { TorusSubmissionService } from 'src/app/services/torus-submission.service';
import * as _ from 'lodash';
import { merge, BehaviorSubject, Observable, Subject, of, pipe } from 'rxjs';
import { debounceTime, finalize, takeUntil, tap } from 'rxjs/operators';
import { AccountLookupDetails } from '../../models/account-lookup-details.model';
import { PagedAccountLookupStatusSummary } from '../../models/paged-account-lookup-summary.model';
import { AccountLookupService } from '../../services/account-lookup.service';
import { AiLoggingService } from '../../shared/services/ai-logging.service';
import { IpLookupDetailsComponent } from '../ip-lookup-details/ip-lookup-details.component';
import { TorusSubmittingDialogComponent } from '../torus-submitting-dialog/torus-submitting-dialog.component';
import { TorusSubmittingDialogData } from 'src/app/models/torus-submitting-dialog-data.model';
import { TorusSubmissionResponse } from 'src/app/models/torus-submission-response.model';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { catchError } from 'rxjs/operators';

@Component({
    selector: 'app-account-lookup',
    templateUrl: './account-lookup.component.html',
    styleUrls: ['./account-lookup.component.scss']
})
export class AccountLookupComponent implements OnInit, AfterViewInit {
    ngUnsub: Subject<void> = new Subject<void>();
    searchForm: FormGroup;
    filterForm: FormGroup;

    public accountLookupRequestsDisplayColumns = ['accountLookupId', 'crmRequestId', 'submittedBy', 'submittedOn', 'status', 'details'];
    public accountLookupRequestDetailsDisplayColumns = ['flag', 'account', 'forceEnterprise', 'status', 'isCustomerLockbox', 'dataCenterLocations', 'hasPreservations', 'isArchiveProvisioned', 'hasSoftDeletedMailbox'];
    public accountLookupRequestDetailsNonExistDisplayColumns = ['flag', 'account', 'forceEnterprise', 'exists'];
    public skypeAccountDetailsDisplayColumns = ['identifier', 'identifierType', 'skypeId', 'msaLinkedAccount', 'puid', 'netId', 'skypeLookupStatus'];
    public selectedSkypeAccountError: string;
    public accountLookupDetails$: Observable<AccountLookupDetails[]>;
    public noResults$: Observable<boolean>;
    public selectedRequest: AccountLookupRequest;
    public userName: string;
    public lookupId: number;
    public isSubmitting: boolean = false;
    public isMaxSkypeResults: boolean = false;
    public maxSkypeResults: number = 25;
    public resultsSize: number;
    public statusCount: Observable<number>;
    public includeExpiredLookups: boolean = false;
    public filterFloatLabelType: string = 'always';
    public loadingSubject = new BehaviorSubject<boolean>(false);
    public skypeAccountLookupDetails: SkypeAccountLookupDetails[];

    private _statusList: BehaviorSubject<AccountLookupRequest[]>;
    private dataStore: { statuses: AccountLookupRequest[] };

    private _skypeDetailsList: BehaviorSubject<SkypeAccountLookupDetails[]>;
    private skypeDataStore: { skypeDetails: SkypeAccountLookupDetails[] };

    private torusDialogRef: MatDialogRef<any>;
    isTorusSuccessful: boolean = false;
    isTorusSubmitting: boolean = false;
    torusSubmissionResponse: TorusSubmissionResponse;

    lookupTypeList: string[] = ['Exchange', 'Skype'];
    defaultLookupType: string;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

    get identifiers() { return this.identifiersControl ? this.identifiersControl.value : null; }
    get lookupType() { return this.lookupTypeControl ? this.lookupTypeControl.value : null; }
    get crmRequestId() { return this.crmRequestIdControl ? this.crmRequestIdControl.value : null; }

    get identifiersControl() { return this.searchForm.get('identifiers') || null; }
    get lookupTypeControl() { return this.searchForm.get('lookupType') || null; }
    get crmRequestIdControl() { return this.searchForm.get('crmRequestId') || null; }

    get submittedByFilterControl() { return this.filterForm.get('submittedBy') || null; }

    get statusList() { return this._statusList.asObservable(); }
    get skypeAccountDetails() { return this._skypeDetailsList.asObservable(); }

    accountExists = (index, item) => item.exists || item.status === 'In Progress';


    constructor(
        private fb: FormBuilder,
        private accountLookupService: AccountLookupService,
        private dialog: MatDialog,
        private msalService: MsalService,
        private aiLogService: AiLoggingService,
        private torusSubmissionService: TorusSubmissionService,
        private changeDetector: ChangeDetectorRef
    ) {
        this.dataStore = { statuses: [] };
        this.skypeDataStore = { skypeDetails: [] };
        this._statusList = <BehaviorSubject<AccountLookupRequest[]>>new BehaviorSubject([]);
        this._skypeDetailsList = <BehaviorSubject<SkypeAccountLookupDetails[]>>new BehaviorSubject([]);
    }

    ngOnInit() {
        this.aiLogService.operationStart();
        this.aiLogService.logPageView('Account Lookup');
        this.userName = this.msalService.instance.getActiveAccount().username;

        this.setupForms();
        this.setupSearchDebounce(this.submittedByFilterControl);

        this.defaultLookupType = this.lookupTypeList[0];

        this.accountLookupService.identifiers = '';
        this.accountLookupService.lookupType = '';
        this.accountLookupService.crmRequestId = '';

        // Listen for events from the Torus service
        this.torusSubmissionService.torusObservable$.pipe(takeUntil(this.ngUnsub)).subscribe((model: TorusSubmissionResponse) => {
            // Grab the published model and set some properties to control the UI
            this.torusSubmissionResponse = model;
            this.isTorusSuccessful = !this.torusSubmissionResponse.error;
            this.isTorusSubmitting = false;

            // If successful just close the dialog
            if (this.isTorusSuccessful) {
                this.torusDialogRef.close();

                this.identifiersControl.reset();
                this.crmRequestIdControl.reset();
                this.lookupTypeControl.reset();
                this.tabGroup.selectedIndex = 1;
            }
            else {
                // An error occurred before Torus submission (e.g. YubiKey not inserted, PIN dialog)
                // if it is not retryable, update the status so the UI shows "error" rather than "in progress"
                if (!model.retryable) {
                    this.accountLookupService.submitTorusFailure(this.lookupId, false, model.errorMessage).subscribe();
                }
            }
            this.isSubmitting = false;
            // Due to some UI weirdness, detect changes again to ensure the UI appropriately updates
            this.detectChanges();
        });
    }

    ngAfterViewInit() {
        // load new page on sort or paginate
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(() => this.getAccountLookupStatus())).pipe(
                takeUntil(this.ngUnsub))
            .subscribe();
    }

    setupForms() {
        this.searchForm = this.fb.group({
            identifiers: [this.accountLookupService.identifiers, Validators.required],
            lookupType: [this.accountLookupService.lookupType, Validators.required],
            crmRequestId: [this.accountLookupService.crmRequestId]
        });

        this.filterForm = this.fb.group({
            submittedBy: [this.userName],
            includeExpired: [this.includeExpiredLookups]
        });
    }

    setupSearchDebounce(...controls: AbstractControl[]) {
        controls.forEach(control => {
            control.valueChanges
                .pipe(debounceTime(300), tap(() => {
                    this.getAccountLookupStatus();
                })).pipe(
                    takeUntil(this.ngUnsub))
                .subscribe();
        });
    }

    updateIncludeExpired() {
        this.includeExpiredLookups = !this.includeExpiredLookups;
        this.getAccountLookupStatus();
    }

    refresh() {
        this.getAccountLookupStatus();
    }

    submitData() {
        this.isSubmitting = true;

        let ids: string[] = this.identifiers.split(/[\s,]+/).filter(i => i && !/^\s*$/.test(i));

        switch (this.lookupType) {
            case 'Skype':
                this.submitSkypeData(ids);
                break;
            case 'Exchange':
                this.submitExchangeData(ids);
                break;
            default:
                break;
        }
    }

    submitExchangeData(ids: string[]) {
        const requestToSubmit = this.prepareRequest();
        this.accountLookupService
            .submitAccountLookup(requestToSubmit, this.lookupType)
            .pipe(takeUntil(this.ngUnsub))
            .subscribe(accountLookupId => {
                this.lookupId = accountLookupId;
                this.submitTorus(accountLookupId.toString(), ids.length)
            }, (error) => {
                // set this back to false so the user can re-submit without having to refresh the page
                this.isSubmitting = false;
                this.detectChanges();
            });
    }

    submitSkypeData(ids: string[]) {
        // Clear previous search records
        this.skypeDataStore = { skypeDetails: [] };

        // Validate the number of identifiers submitted
        if (this.validateSkypeRequestLength(ids)) {
            const requestToSubmit = this.prepareSkypeRequest(ids);
            this.accountLookupService
                .submitSkypeAccountLookup(requestToSubmit)
                .pipe(
                    takeUntil(this.ngUnsub),
                    tap(results => {
                        this.skypeDataStore.skypeDetails = results;
                        this._skypeDetailsList.next(Object.assign({}, this.skypeDataStore).skypeDetails);
                        this.isSubmitting = false;
                        this.detectChanges();
                    }),
                    catchError(error => {
                        this.isSubmitting = false;
                        this.detectChanges();
                        return of(error);
                    })
                )
                .subscribe();
        }
    }

    getAccountLookupStatus() {
        this.loadingSubject.next(true);

        const accountLookupRequests$ = this.accountLookupService.getAccountLookups(
            this.submittedByFilterControl.value,
            this.includeExpiredLookups,
            this.sort.active + ' ' + this.sort.direction,
            this.paginator.pageIndex,
            this.paginator.pageSize);

        accountLookupRequests$
            .pipe(takeUntil(this.ngUnsub))
            .pipe(finalize(() => this.loadingSubject.next(false)))
            .subscribe((results: PagedAccountLookupStatusSummary) => {
                this.resultsSize = results.totalItems;
                this.dataStore.statuses = results.accountLookupSummaries;
                this._statusList.next(Object.assign({}, this.dataStore).statuses);
            });
    }

    getIpLookup(id: number, index: number) {
        this.loadingSubject.next(true);

        const dialogConfig = new MatDialogConfig();
        const dialogRef: MatDialogRef<IpLookupDetailsComponent> = this.dialog.open(
            IpLookupDetailsComponent,
            dialogConfig
        );
        const instance = dialogRef.componentInstance;

        this.accountLookupService
            .getAccountLookupWithIps(id, index)
            .pipe(takeUntil(this.ngUnsub))
            .subscribe(result => {
                instance.IpAddresses = result.ipLogins;
                this.loadingSubject.next(false);
            });
    }

    openDetails(row: AccountLookupRequest) {
        this.loadingSubject.next(true);
        this.accountLookupService
            .getAccountLookup(row.accountLookupId)
            .pipe(takeUntil(this.ngUnsub))
            .subscribe(d => {
                this.selectedRequest = row;
                this.selectedRequest.details = d;
                this.loadingSubject.next(false);
                this.tabGroup.selectedIndex = 2;
            });
    }

    openSkypeErrorDialog(row: SkypeAccountLookupDetails) {
        this.dialog.open(DialogComponent, {
            width: 'flex',
            data: row.ErrorMessage
        });
    }

    isDetailsDisabled(row: AccountLookupRequest): boolean {
        return row.status !== 'Completed' && row.status !== 'Partially Complete' && row.status !== 'Error';
    }

    isDetailsVisible(row: AccountLookupRequest): boolean {
        return row.status !== 'Expired';
    }

    isSkypeLookupError(row: SkypeAccountLookupDetails): boolean {
        return row.Status === 'Error';
    }

    selectedTabChange($event: MatTabChangeEvent) {
        switch ($event.index) {
            case 0:
                this.selectedRequest = undefined;
                break;
            case 1:
                this.selectedRequest = undefined;
                this.getAccountLookupStatus();
                break;
        }
    }

    prepareRequest(): AccountLookupSubmitRequest {
        const newRequest = new AccountLookupSubmitRequest();
        newRequest.identifiers = this.identifiers;
        newRequest.crmRequestId = this.crmRequestId;
        return newRequest;
    }

    validateSkypeRequestLength(ids: string[]): boolean {
        if (ids.length > this.maxSkypeResults) {
            this.isMaxSkypeResults = true
            this.isSubmitting = false;
            return false;
        }
        else {
            this.isMaxSkypeResults = false;
            return true;
        }
    }

    prepareSkypeRequest(ids: string[]): SkypeAccountLookupSubmitRequest {
        var identifierArray: { [id: string]: string[] };
        identifierArray = this.identifiers;
        var newRequest = <SkypeAccountLookupSubmitRequest>{ identifiers: identifierArray };
        return newRequest;
    }

    private submitTorus(lensRequestId: string, identifierCount: number) {
        // Set Torus submitting flag for UI use
        this.isTorusSubmitting = true;

        // Configure dialog with the appropriate options and data. Don't let users close as this will
        // be showing incrementing counters for staging container (and sometimes shadow accounts)
        const dialogConfig = new MatDialogConfig();
        let dialogData = new TorusSubmittingDialogData();

        dialogData.lensRequestId = lensRequestId;
        dialogData.identifierCount = identifierCount;

        dialogConfig.width = 'flex';
        dialogConfig.autoFocus = true;
        dialogConfig.data = dialogData;
        dialogConfig.disableClose = true;

        // All of the UI logic is contained within the TorusSubmittingDialogComponent
        this.torusDialogRef = this.dialog.open(TorusSubmittingDialogComponent, dialogConfig);
        this.torusDialogRef.afterClosed().subscribe((canceled: boolean) => {
            if (canceled) {
                this.accountLookupService.submitTorusFailure(this.lookupId, true, 'User canceled Torus request.').subscribe();
            }
        });
    }

    // Forces change detected at this container level after a very short delay.
    // This is used for edge cases resulting in components updated after async events complete &&
    // after Angular's change detection runs.
    detectChanges() {
        setTimeout(() => { this.changeDetector.detectChanges(); }, 100);
    }

    setLookupTypeDisableFlag() {
        this.resetLookupTypeControls();

        if (this.lookupType == "Skype") {
            this.crmRequestIdControl.disable();
        }
        else {
            this.crmRequestIdControl.enable();
        }
    }

    isSkypeAccountListPopulated() {
        return this.skypeDataStore.skypeDetails.length > 0;
    }

    resetLookupTypeControls() {
        this.identifiersControl.reset();
        this.crmRequestIdControl.reset();
        this.skypeDataStore = { skypeDetails: [] };
        this.isMaxSkypeResults = false;
    }
}
