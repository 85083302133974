import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from 'src/app/shared/services/base.service';
import { PreservationReview } from 'src/app/models/preservation-review.model';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PreservationReviewService {

  constructor(
    private httpClient: HttpClient,
    protected baseService: BaseService,
  ) {}

  getReviewData(searchId: string) {
    return this.baseService.get<PreservationReview>(
      `${environment.webApiConfig.serverUrl}${environment.deliveryReviewUrl}/Preservation/${searchId}`
      ).pipe(catchError(error => this.baseService.handleError(error)));
  }

  getSasToken(searchId: string) {
    return this.baseService.get<string>(
      `${environment.webApiConfig.serverUrl}${environment.deliveryReviewUrl}/Preservation/GetSasToken/${searchId}`
      ).pipe(catchError(error => this.baseService.handleError(error)));
  }

}
