<mat-grid-list cols="3" rowHeight="35px">
  <mat-grid-tile *ngFor="let dataField of _dataFields" [colspan]="1" [rowspan]="1">
    <div class="text-inside-grid">
      <mat-checkbox
      [checked]=  "dataFieldExistsInForm(dataField)" 
      [disabled]= "dataField.isDisabled"         
      (change)=   "updateFormDataField(dataField)">
      {{dataField.displayName | pascalCaseSpace}}
      </mat-checkbox>
    </div>
  </mat-grid-tile>
</mat-grid-list>

<div class="button button-bottom">
  <button mat-raised-button color="primary" (click)="updateFormDataFieldAll(true)">Select All</button>
  <button mat-raised-button color="primary" (click)="updateFormDataFieldAll(false)">Select None</button>
</div>