<div *ngIf='polling && totalItemCount && !message'>
    <div>Creating Azure storage containers ({{ stagingContainersReadyCount }} of {{ totalItemCount }})</div>
    <div *ngIf='showShadowAccountMessage'>Creating shadow accounts ({{ shadowAccountsReadyCount }} of {{ totalItemCount }})</div>
    <div *ngIf='showShadowAccountMessage' class="submitting-note">(note: this will take a couple minutes)</div>
    <div *ngIf='isAccountLookup' class="submitting-note">(note: two containers are created for each identifier)</div>
</div>
<div *ngIf='submitting'>
    <p>Submitting Torus request. Please enter YubiKey credentials, confirm the dialog, and wait for submission.</p>
</div>
<div *ngIf='message'>
    <div>{{ message }}</div>
    <button mat-stroked-button mat-dialog-close>Ok</button>
</div>
