import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MsalService } from '@azure/msal-angular';
import { PascalCaseSpacePipe } from '../lens-request/pipes/pascal-case-space.pipe';
import { MaterialModule } from '../materials/materials.module';
import { AiLoggingService } from './services/ai-logging.service';
import { BaseService } from './services/base.service';
import { BrowserModule } from '@angular/platform-browser';
import { OrderByPipe } from '../pipes/order-by.pipe';

const SHARED_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  FontAwesomeModule,
  FormsModule,
  HttpClientModule,
  MaterialModule,
  ReactiveFormsModule,
];

const SHARED_PIPES = [
  PascalCaseSpacePipe,
  OrderByPipe
]

@NgModule({
  imports: [SHARED_MODULES],
  declarations: [SHARED_PIPES],
  exports: [SHARED_MODULES, SHARED_PIPES]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        BaseService,
        MsalService,
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE]
        },
        AiLoggingService,
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
      ],
    }
  }
}
