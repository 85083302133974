export class AccountLookupAlias {
  aliasName: string;
  flags: string;
  aliasType: number;
  isAllowedToSignIn: boolean;
  isEvicted: boolean;
  isManaged: boolean;
  isPrimary: boolean;
  isVerified: boolean;

  constructor(obj: Object) {
    this.aliasName = obj['AliasName'];
    this.flags = obj['Flags'];
    this.aliasType = Number.parseInt(obj['AliasType']);
    this.isAllowedToSignIn = JSON.parse(obj['IsAllowedToSignIn']);
    this.isEvicted = JSON.parse(obj['IsEvicted']);
    this.isManaged = JSON.parse(obj['IsManaged']);
    this.isPrimary = JSON.parse(obj['IsPrimary']);
    this.isVerified = JSON.parse(obj['IsVerified']);
  }
}
