import { Component, OnInit, ViewEncapsulation, Inject, OnDestroy } from '@angular/core';
import { RequestStatusSummary } from '../../models/request-status-summary.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreservationReviewService } from 'src/app/services/preservation-review.service';
import { PreservationReview } from 'src/app/models/preservation-review.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClipboardService } from 'ngx-clipboard';
import { EmailContentType } from 'src/app/models/preservation-review.model';
import { MailboxItemType, ProcessingRegionEnum } from 'src/app/services/delivery-review.service';
import * as _ from 'lodash';
import { takeUntil, tap, catchError, finalize } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { faWindowClose, IconDefinition } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'preservation-review',
  templateUrl: './preservation-review.component.html',
  styleUrls: ['./preservation-review.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreservationReviewComponent implements OnInit, OnDestroy {

  public emailContentType = EmailContentType;
  public mailboxItemType = MailboxItemType;
  public processingRegionEnum = ProcessingRegionEnum;
  public outputSearchRegions: string;
  public requestedSearchRegions: string;
  public reqStatusSummary: RequestStatusSummary;
  public preservationReviewData: PreservationReview;
  public sasToken: string;
  public isGenerated: boolean = false;
  public isGenerating: boolean = false;
  public isDataLoaded: boolean = false;
  public isPublishing: boolean = false;
  public isPublished: boolean = false;
  faWindowClose: IconDefinition = faWindowClose;
  ngUnsub: Subject<void> = new Subject<void>();

  constructor(
    private cbService: ClipboardService,
    private dataService: PreservationReviewService,
    private dialogRef: MatDialogRef<PreservationReviewComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
    this.dataService.getReviewData(this.reqStatusSummary.id)
      .pipe(takeUntil(this.ngUnsub))
      .subscribe({
        next: data => {
          this.preservationReviewData = new PreservationReview(data);
        },
        complete: () => {
          this.isDataLoaded = true;
        }
      });
  }


  generateSasUrl() {
    this.isGenerating = true;
    this.isGenerated = false;
    this.dataService.getSasToken(this.reqStatusSummary.id)
      .pipe(
         takeUntil(this.ngUnsub),
         tap((data: string) =>
            {
                this.sasToken = data;
                this.isGenerated = true;
            }),
         catchError(async () => this.isGenerated = false),
         finalize(() => this.isGenerating = false)
       )
      .subscribe();
  }

  copySasUrl() {
    this.cbService.copyFromContent(this.reqStatusSummary.destination + this.sasToken);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    this.ngUnsub.next();
    this.ngUnsub.complete();
  }
}
