
<form [formGroup]="form" *ngIf="shouldDisplay()">
    <mat-form-field>
      <mat-select placeholder="Jurisdiction" [(value)]="jurisdiction" formControlName="jurisdiction">
        <mat-option *ngFor="let jurisdiction of _availableJurisdictions" [value]="jurisdiction">
          {{jurisdiction}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  