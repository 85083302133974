import { Action } from '@ngrx/store';

export interface State {
    currentMicrosoftPropertyId: number;
}

const initialState: State = {
    currentMicrosoftPropertyId: 3
};

export function reducer(state: State = initialState, action: Action): State {
    switch (action.type) {
        default:
            return state;
    }
}

export const getCurrentMicrosoftPropertyId = (state: State) => state.currentMicrosoftPropertyId;
