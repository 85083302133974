import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {

    @Input() menuItems;
    @Input() userIsAuthenticated: boolean;

    showInternalLink(menuItem) {
        return this.userIsAuthenticated && !menuItem.isExternalSite;
    }
}
