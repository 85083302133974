import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pascalCaseSpace'
})
export class PascalCaseSpacePipe implements PipeTransform {

  transform(value: string, args?: any): string {
    if(value){
      let s  = value.replace(/([a-z])([A-Z])/g, '$1 $2'); // SMSRecords => SMS Records
      s = s.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // BillingAddress => Billing Address
      var capitalize = /(\b[a-z](?!\s))/g;
      s = s.replace(capitalize, function(x){return x.toUpperCase();}); // real Time => Real Time
      return s;
    }else{
      return value;
    }
  }
}