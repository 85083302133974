import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BaseService } from '../../shared/services/base.service';
import { Capability } from '../models/capability.model';



@Injectable()
export class CapabilitiesService {
  capabilities: { [key: string]: Capability[] } = {};
  loadingCapability: { [key: string]: Observable<any> } = {};
  dataFieldLoading: { [key: string]: boolean } = {};

  constructor(protected baseService: BaseService) {}

  public getCapabilitiesData(forMsProperty: string, forRequestType: string): Observable<Capability[]> {
    const key = forMsProperty + forRequestType;

    if (key) {
      const capabilities = this.capabilities[key];
      if (capabilities && capabilities.length > 0) {
        // Return if it's already loaded here
        return of(capabilities);
      } else if (this.loadingCapability[key]) {

        // Return if we're already fetching this data
        return this.loadingCapability[key];
      } else {
        return this.loadingCapability[key] = this.baseService
          .get(
            environment.webApiConfig.serverUrl +
              environment.capabilitiesUrl +
              '/' +
              forMsProperty + '/' +
              forRequestType
          )
          .pipe(
            // switchMap in case they swap between properties while we're fetching
            switchMap(response => {
              this.loadingCapability[key] = null;
              const newValues = Capability.fromJSONArray(response);
              this.capabilities[key] = newValues;
              return of(newValues);
            }),
            catchError(error => {
              return this.baseService.handleError(error);
            })
          );
      }
    }
  }
}
