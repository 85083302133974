<h2>Summary of New Request</h2>
<mat-expansion-panel [expanded]="hasWarnings">
    <mat-expansion-panel-header>
        <mat-panel-title>
            CRM Ticket Number: {{ crmIdentifier }}
        </mat-panel-title>
        <mat-panel-description>
          <span>{{startDate}} through {{endDate}} : UTC</span>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div *ngIf="isSkypeEurope">Jurisdiction: {{jurisdiction}}</div>
    <div *ngIf="proccessingRegion">Processing Region: {{proccessingRegion}}</div>
    <div *ngIf="showSearchRegions">Preservation Search Region(s): {{searchRegions}}</div>
    <div *ngIf="emailFilter">Email Filter:  {{emailFilter}}</div>
    <mat-list *ngFor="let idType of getKeys(crmIdentifiers)">
        <h4 class="h4-review">{{idType}}</h4>
        <mat-list-item class="no-margin" *ngFor="let identifier of crmIdentifiers[idType] | orderBy: 'identifier'">
            <small [ngClass]="{ 'identifier-warning': containsSkypeIdWarning(identifier) }">
                <span *ngIf="!hasConsumerAndEnterprise(identifier)"> {{identifier}}</span>
                <span *ngIf="hasConsumerAndEnterprise(identifier)"> {{identifier.identifier}} ({{getTenantTypeName(identifier.customerTenantType)}})</span>
                <span *ngIf="containsSkypeIdWarning(identifier)"> - <mat-error style="display:inline">This identifier cannot be verified and may fail.</mat-error></span>
            </small>
        </mat-list-item>
    </mat-list>
</mat-expansion-panel>

<h3 matSubheader>{{msProperty }} {{requestType | pascalCaseSpace}} Request</h3>
<mat-list-item *ngFor="let capability of getKeys(capabilities)">
    <h4 matLine class="no-margin">{{requestType == 'preservation' && capability == 'fullMessageContent' ? "Messages" : (getCapabilityCaption(capability) | pascalCaseSpace)}}</h4>
    <p matLine *ngIf="hasDataFields(capability)" class="indent-children">=> {{getDataFields(capability) | pascalCaseSpace}} </p>
</mat-list-item>
