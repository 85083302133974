<mat-dialog-content id="review">
    <ng-container *ngIf="!isDataLoaded">
        <div class="loadingMessage">Please stand by, loading data...<span class="material-icons spin" *ngIf="!isDataLoaded">autorenew</span></div>
    </ng-container>
    <ng-container *ngIf="isDataLoaded">
        <div id="review-header">
            <fa-icon id="close-box" matDialogClose [icon]="faWindowClose" size="2x" pull="right" (click)="closeDialog()"></fa-icon>
            <h2>ID: {{reqStatusSummary.id}}</h2>
            <h4>Delivered: {{deliveryReviewData?.countTotalOutputBlobs | number:'1.0-2'}} individual files for a total of {{deliveryReviewData?.validationContainerSizeInBytes | number:'1.0-2'}} bytes (prior to compression)</h4>
        </div>
        <div>
            <div class="mat-table">
                <div class="mat-header-row">
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">Processing Region</div>
                    <div class="mat-header-cell">Preservation Region(s)</div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Requested</div>
                    <div class="mat-cell">
                        {{processingRegionEnum[deliveryReviewData?.requestedProcessingRegion]}}
                    </div>
                    <div class="mat-cell">
                        {{requestedSearchRegions}}
                    </div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Delivered</div>
                    <div class="mat-cell" [ngClass]="{'region-mismatch': processingRegionEnum[deliveryReviewData?.requestedProcessingRegion] !== processingRegionEnum[deliveryReviewData?.outputProcessingRegion]}">
                        {{processingRegionEnum[deliveryReviewData?.outputProcessingRegion]}}
                    </div>
                    <div class="mat-cell" [ngClass]="{'region-mismatch': requestedSearchRegions !== outputSearchRegions}">
                        {{outputSearchRegions}}
                    </div>
                </div>
            </div>
            Cells with a <span class="region-mismatch">yellow background</span> indicate a disparity between the requested and delivered region(s). <br/> This does not necessarily indicate an error - for example, one or more preservation regions may contain
            no data for a given identifier.
        </div>
        <br />
        <br />
        <div>
            <div class="mat-table">
                <div class="mat-header-row">
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">Email Content</div>
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">Other Content</div>
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">&nbsp;</div>
                </div>
                <div class="mat-header-row">
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell lens-date">Start Date (UTC)</div>
                    <div class="mat-header-cell lens-date">End Date (UTC)</div>
                    <div class="mat-header-cell">Full Content</div>
                    <div class="mat-header-cell">Full Header Only</div>
                    <div class="mat-header-cell">Partial Header Only</div>
                    <div class="mat-header-cell">Calendars</div>
                    <div class="mat-header-cell">Contacts</div>
                    <div class="mat-header-cell">Mailbox IP</div>
                    <div class="mat-header-cell">Mobile Device</div>
                    <div class="mat-header-cell">Registration</div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Requested</div>
                    <div class="mat-cell lens-date">
                        {{deliveryReviewData?.requestedTimestampStart | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <div class="mat-cell lens-date">
                        {{deliveryReviewData?.requestedTimeStampEnd | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.FullMessageContent">done</span>
                        <span class="material-icons not-requested" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.FullMessageContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.FullHeaderOnly">done</span>
                        <span class="material-icons not-requested" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.FullHeaderOnly">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.PartialHeaderOnly">done</span>
                        <span class="material-icons not-requested" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.PartialHeaderOnly">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedCalendarContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedCalendarContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedContactContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedContactContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedMailboxLoginContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedMailboxLoginContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedMobileDeviceContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedMobileDeviceContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedRegistrationContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedRegistrationContent">highlight_off</span>
                    </div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Delivered</div>
                    <div class="mat-cell lens-date" [ngClass]="{'date-mismatch': deliveryReviewData?.requestedTimestampStart !== deliveryReviewData?.outputTimestampEarliest}">
                        {{deliveryReviewData?.outputTimestampEarliest | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <div class="mat-cell lens-date" [ngClass]="{'date-mismatch': deliveryReviewData?.requestedTimeStampEnd !== deliveryReviewData?.outputTimestampLatest}">
                        {{deliveryReviewData?.outputTimestampLatest | date:'MM/dd/yyyy HH:mm:ss':'UTC'}}
                    </div>
                    <ng-container *ngIf="deliveryReviewData?.outputHasMismatchedEmailContentLevels">
                        <div class="mat-cell">
                            <span class="email-level-mismatch" *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.FullMessageContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Messages]]}}</span>
                            <span class="material-icons requested-not-delivered" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.FullMessageContent">warning</span>
                        </div>
                        <div class="mat-cell">
                            <span class="email-level-mismatch" *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.FullHeaderOnly">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Messages]]}}</span>
                            <span class="material-icons requested-not-delivered" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.FullHeaderOnly">warning</span>
                        </div>
                        <div class="mat-cell">
                            <span class="email-level-mismatch" *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.PartialHeaderOnly">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Messages]]}}</span>
                            <span class="material-icons requested-not-delivered" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.PartialHeaderOnly">warning</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!deliveryReviewData?.outputHasMismatchedEmailContentLevels">
                        <div class="mat-cell">
                            <span class="requested-delivered" *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.FullMessageContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Messages]]}}</span>
                            <span class="material-icons not-requested-not-delivered" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.FullMessageContent">not_interested</span>
                        </div>
                        <div class="mat-cell">
                            <span class="requested-delivered " *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.FullHeaderOnly">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Messages]]}}</span>
                            <span class="material-icons not-requested-not-delivered" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.FullHeaderOnly">not_interested</span>
                        </div>
                        <div class="mat-cell">
                            <span class="requested-delivered " *ngIf="deliveryReviewData?.requestedEmailContentLevel == emailContentType.PartialHeaderOnly">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Messages]]}}</span>
                            <span class="material-icons not-requested-not-delivered" *ngIf="deliveryReviewData?.requestedEmailContentLevel != emailContentType.PartialHeaderOnly">not_interested</span>
                        </div>
                    </ng-container>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasCalendarContent && deliveryReviewData.requestedCalendarContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Calendar]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasCalendarContent && deliveryReviewData.requestedCalendarContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasCalendarContent && !deliveryReviewData.requestedCalendarContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasCalendarContent && !deliveryReviewData.requestedCalendarContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Calendar]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Calendar]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasContactContent && deliveryReviewData.requestedContactContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Contacts]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasContactContent && deliveryReviewData.requestedContactContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasContactContent && !deliveryReviewData.requestedContactContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasContactContent && !deliveryReviewData.requestedContactContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Contacts]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Contacts]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasMailboxLoginContent && deliveryReviewData.requestedMailboxLoginContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MailboxLogin]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasMailboxLoginContent && deliveryReviewData.requestedMailboxLoginContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasMailboxLoginContent && !deliveryReviewData.requestedMailboxLoginContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasMailboxLoginContent && !deliveryReviewData.requestedMailboxLoginContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MailboxLogin]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MailboxLogin]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasMobileDeviceContent && deliveryReviewData.requestedMobileDeviceContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MobileDevice]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasMobileDeviceContent && deliveryReviewData.requestedMobileDeviceContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasMobileDeviceContent && !deliveryReviewData.requestedMobileDeviceContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasMobileDeviceContent && !deliveryReviewData.requestedMobileDeviceContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MobileDevice]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.MobileDevice]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasRegistrationContent && deliveryReviewData.requestedRegistrationContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Registration]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasRegistrationContent && deliveryReviewData.requestedRegistrationContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasRegistrationContent && !deliveryReviewData.requestedRegistrationContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasRegistrationContent && !deliveryReviewData.requestedRegistrationContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Registration]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Registration]]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div>
            <div class="mat-table">
                <div class="mat-header-row">
                    <div class="mat-header-cell">&nbsp;</div>
                    <div class="mat-header-cell">Conversation History</div>
                    <div class="mat-header-cell">Journal</div>
                    <div class="mat-header-cell">Notes</div>
                    <div class="mat-header-cell">Tasks</div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Requested</div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedConversationHistoryContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedConversationHistoryContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedJournalContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedJournalContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedNotesContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedNotesContent">highlight_off</span>
                    </div>
                    <div class="mat-cell">
                        <span class="material-icons" *ngIf="deliveryReviewData?.requestedTasksContent">done</span>
                        <span class="material-icons not-requested" *ngIf="!deliveryReviewData?.requestedTasksContent">highlight_off</span>
                    </div>
                </div>
                <div class="mat-row">
                    <div class="mat-header-cell">Delivered</div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasConversationHistoryContent && deliveryReviewData.requestedConversationHistoryContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.IM]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasConversationHistoryContent && deliveryReviewData.requestedConversationHistoryContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasConversationHistoryContent && !deliveryReviewData.requestedConversationHistoryContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasConversationHistoryContent && !deliveryReviewData.requestedConversationHistoryContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.IM]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.IM]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasJournalContent && deliveryReviewData.requestedJournalContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Journal]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasJournalContent && deliveryReviewData.requestedJournalContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasJournalContent && !deliveryReviewData.requestedJournalContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasJournalContent && !deliveryReviewData.requestedJournalContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Journal]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Journal]]}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasNotesContent && deliveryReviewData.requestedNotesContent">{{(deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Posts]]*1) + (deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Notes]]*1)}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasNotesContent && deliveryReviewData.requestedNotesContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasNotesContent && !deliveryReviewData.requestedNotesContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasNotesContent && !deliveryReviewData.requestedNotesContent && (deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Posts]] > 0 || deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Notes]] > 0)">{{(deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Posts]]*1) + (deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Notes]]*1)}}</span>
                    </div>
                    <div class="mat-cell">
                        <span class="requested-delivered" *ngIf="deliveryReviewData?.outputHasTasksContent && deliveryReviewData.requestedTasksContent">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Tasks]]}}</span>
                        <span class="material-icons requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasTasksContent && deliveryReviewData.requestedTasksContent">error_outline</span>
                        <span class="material-icons not-requested-not-delivered" *ngIf="!deliveryReviewData?.outputHasTasksContent && !deliveryReviewData.requestedTasksContent">not_interested</span>
                        <span class="delivered-not-requested" *ngIf="deliveryReviewData?.outputHasTasksContent && !deliveryReviewData.requestedTasksContent && deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Tasks]] > 0">{{deliveryReviewData?.countOutputMailboxItemTypes[mailboxItemType[mailboxItemType.Tasks]]}}</span>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Table Legend
                    </mat-panel-title>
                    <mat-panel-description>
                        View a list of symbols, colors, highlights, and their meaning
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <mat-grid-list cols="4" rowHeight="75px">
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons">done</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability was requested in the Search Request.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons not-requested">highlight_off</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability was not requested in the Search Request.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="date-mismatch">MM/DD/YY</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Delivered date does not match the requested date. This may not be an error - for example, no content may exist before or after a subset of the requested
                        date range.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="email-level-mismatch">10</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>At least one delivered message does not match the requested level of detail (e.g., full content). Number indicates the total number of delivered
                        messages.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span>MM/DD/YY</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Delivered date matches the requested date.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons not-requested-not-delivered">not_interested</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>This capability or level of message detail was not requested and was not delivered.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons requested-not-delivered">warning</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>At least one delivered message does not match the requested level of detail (e.g., full content).
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="requested-delivered">10</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability or level of message detail was requested and delivered. Number indicates the total number of delivered items for the capability or level
                        of message detail.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="material-icons requested-not-delivered">error_outline</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>Capability was requested but not delivered.
                    </mat-grid-tile>
                    <mat-grid-tile [colspan]="2" [rowspan]="1">
                        <span class="delivered-not-requested">10</span><span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>A non-zero number of items from this capability were delivered, but they were not requested and should not have been delivered.
                    </mat-grid-tile>
                </mat-grid-list>
            </mat-expansion-panel>
        </mat-accordion>
        <br/>
        <button mat-button color="primary" (click)="generateSasUrl()" [disabled]="isGenerating || !isDataLoaded || reqStatusSummary?.currentStatus == 'Published'">Click to generate SAS token (valid for 1h)</button>
        <span class="material-icons spin" *ngIf="isGenerating">autorenew</span>
        <span class="material-icons" *ngIf="isGenerated">done</span>
        <br/>
        <button mat-button color="primary" (click)="copySasUrl()" [disabled]="!isGenerated || isGenerating || reqStatusSummary?.currentStatus == 'Published'">Click to copy the SAS token to your clipboard</button>
        <h5>See <a href="https://github.com/MicrosoftDocs/azure-docs/blob/master/articles/vs-azure-tools-storage-manage-with-storage-explorer.md#attach-a-service-by-using-a-shared-access-signature-sas" target="_blank">this link</a> for information on how
            to connect to the Validation Container using Azure Storage Explorer with the generated SAS token.</h5>
        <br/>
        <button mat-raised-button color="primary" (click)="initiateDelivery()" [disabled]="!isDataLoaded || isPublishing || (reqStatusSummary?.currentStatus != 'ReadyForValidation' && reqStatusSummary?.currentStatus != 'Completed')">Publish to LE</button>
        <span class="material-icons spin" *ngIf="isPublishing">autorenew</span>
        <span class="material-icons" *ngIf="isPublished">done</span>
        <span *ngIf="isPublished || reqStatusSummary?.currentStatus === 'Published' || reqStatusSummary?.currentStatus === 'Completed'" class="published-note">NOTE: This content has already been published</span>
    </ng-container>
</mat-dialog-content>
