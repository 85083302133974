import { Component, Inject, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, RequiredValidator } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { RequestStatusSummary } from '../../models/request-status-summary.model';
import { ModifyRequest } from '../../models/modify-request.model';
import { RequestSubmitterService } from '../../lens-request/services/request-submitter.service';
import { ExchangeStatusDetails } from '../../models/exchange-status-details.model';
import { RequestStatusService } from '../../services/request-status.service';
import { SkypeRequestStatus } from '../../models/skype-request-status.model';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';
import { TorusSubmittingDialogComponent } from 'src/app/components/torus-submitting-dialog/torus-submitting-dialog.component';
import { TorusSubmissionResponse } from 'src/app/models/torus-submission-response.model';
import { CustomerTenantType, NewRequest } from 'src/app/lens-request/models/new-request.model';
import * as moment from 'moment';
import { faWindowClose, IconDefinition } from '@fortawesome/free-regular-svg-icons';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'request-status-details',
  templateUrl: './request-status-details.component.html',
  styleUrls: ['./request-status-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class RequestStatusDetailsComponent implements OnInit {
  @ViewChild('expires', {static: true}) endDate: ElementRef;
  public detailColumns = [ 'operation', 'crmRequestId', 'submittedDateTime', 'endDate'];
  public targetColumns = [];
  public extendRequest: boolean;
  public deleteRequest: boolean;
  public detailsForm: FormGroup;
  public reqStatusSummary: RequestStatusSummary;
  public exchStatusDetails: ExchangeStatusDetails;
  public skypeRequestStatus: SkypeRequestStatus;
  public submittingModification: boolean = false;
  public showHistoricalDetails: boolean = false;
  public tooLateToExtend: boolean = false;
  public minExtendDuration: number;
  faWindowClose: IconDefinition = faWindowClose;

  targetColumnDefinitions = [
    { def: 'target', showIfNoError: true },
    { def: 'status', showIfNoError: true },
    { def: 'customerTenantType', showIfNoError: true },
    { def: 'error', showIfNoError: false },
  ];

  detailDataSubject$: BehaviorSubject<ExchangeStatusDetails> = new BehaviorSubject(null);
  hasErrorSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  hasError$ = this.hasErrorSubject$.asObservable();
  torusDialogRef: MatDialogRef<any>;
  isTorusSuccessful: boolean = false;
  isTorusSubmitting: boolean = false;
  torusSubmissionResponse: TorusSubmissionResponse;

  dateTime = new Date();
  ngUnsub: Subject<void> = new Subject<void>();

  get endDateControl(): FormControl {
    return this.getFormControl('endDate');
  }

  get newEndDateControl(): FormControl {
    return this.getFormControl('newEndDate');
  }

  get orderDurationControl(): FormControl {
    return this.getFormControl('orderDuration');
  }

  get newStartDateControl(): FormControl {
    return this.getFormControl('newStartDate');
  }

  get msProperty(): string {
    return this.getFormControlValue('msProperty');
  }

  get requestType(): string {
    return this.getFormControlValue('requestType');
  }

  get destination(): string {
    return this.getFormControlValue('destination');
  }

  get operation(): string {
    return this.getFormControlValue('operation');
  }

  get hasConsumerAndEnterprise() {
    return this.msProperty.toLowerCase() == 'exchange' && (this.requestType.toLowerCase() == 'preservation' || this.requestType.toLowerCase() == 'historical');
  }

  get statusDetails(): string {
    if(this.isSkypeLiRequest)
      return this.skypeRequestStatus ? this.skypeRequestStatus.currentStatusDetails : '';
    else
      return this.exchStatusDetails ? this.exchStatusDetails.currentStatusDetails : '';
  }

  get isSkypeLiRequest() {
    return this.validateSkypeLiRequest(this.msProperty);
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<RequestStatusDetailsComponent>,
    private requestSubmitter: RequestSubmitterService,
    private requestService: RequestStatusService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {}

  ngOnInit() {
    this.minExtendDuration = environment.exchangeConfig.minimumTimespanFromExpirationForExtensionInHours;
    this.buildForm();
    if (this.reqStatusSummary.microsoftProperty === 'Exchange') {
      this.requestService.getExchangeDetails(this.reqStatusSummary.id).pipe(
      takeUntil(this.ngUnsub))
      .subscribe({
            next: data => { 
              this.exchStatusDetails = data;
              this.detailDataSubject$.next(data);
              this.showHistoricalDetails = data.operation === 'HistoricalSearch' || data.operation === 'Preservation';
              this.tooLateToExtend = data.operation === 'Subscribe' && data.requestType === 'Realtime' && (moment(data.finalEndDate).utc().diff(moment().utc(),'hours') < environment.exchangeConfig.minimumTimespanFromExpirationForExtensionInHours);
            },
            complete: () => { this.updateForm(); }
      });
    } else if (this.validateSkypeLiRequest(this.reqStatusSummary.microsoftProperty)) {
      this.requestService.getSkypeDetails(this.reqStatusSummary.id).subscribe({
        next: data => {
          this.skypeRequestStatus = data
        },
        complete: () => {
          this.updateForm();
        }
      });
    }

    this.extendRequest = false;
    this.deleteRequest = false;
    this.detailDataSubject$.subscribe({
      next: (details) => {
        if (details) {
          if (details.targets.length === 0 && details.errorMessage) {
            this.hasErrorSubject$.next(true);
            this.setDisplayedTargetColumns(true);
          } else if (details.targets.length === 1 && details.targets[0].error) {
            this.hasErrorSubject$.next(true);
            this.setDisplayedTargetColumns(true);
          } else if (details.targets.length > 1) {
            if (_.findIndex(details.targets, function(o) { if (o.error) { return true; } else { return false; } }) > -1) {
              this.hasErrorSubject$.next(true);
              this.setDisplayedTargetColumns(true);
            }
          } else {
            this.hasErrorSubject$.next(false);
            this.setDisplayedTargetColumns(false);
          }
        } else {
          this.hasErrorSubject$.next(false);
          this.setDisplayedTargetColumns(false);
        }
      }
    });
  }

  setDisplayedTargetColumns(isError: boolean) {
    const displayColumns = this.targetColumnDefinitions
      .filter(tcd => isError || tcd.showIfNoError)
      .map(tcd => tcd.def);
    this.targetColumns = displayColumns;
  }

  private buildForm() {
    if (this.reqStatusSummary) {
      // <-----Set up form ----->
      this.detailsForm = this.fb.group({
        crmNumber: [
          { value: '', disabled: true }
        ],
        msProperty: [
          {
            value: '',
            disabled: true
          }
        ],
        requestType: [{ value: '', disabled: true }],
        operation: [
          { value: '', disabled: true }
        ],
        submittedBy: [
          { value: '', disabled: true }
        ],
        submittedOn: [
          {
            value: '',
            disabled: true
          }
        ],
        capabilities: [
          { value: '', disabled: true }
        ],
        target: [{value: '', disabled: true }],
        destination: [
          { value: '', disabled: true }
        ],
        endDate: [{ value: '', disabled: true }],
        newEndDate: [{ value: '', disabled: true }],
        newStartDate: [null],
        orderDuration: [null],
        modifyCrmNumber: ['', Validators.required],
        location: [{ value: '', disabled: true }],
        captureFrom: [{ value: '', disabled: true }],
        captureThru: [{ value: '', disabled: true }],
        emailFilter: [{ value: '', disabled: true }],
        preservationSearchRegions: [{ value: '', disabled: true }],
        customerTenantType: [{ value: '', disabled: true }],
      });
    }
  }

  private updateForm() {
    if (this.reqStatusSummary.microsoftProperty === 'Exchange') {
      this.detailsForm.setValue({
        crmNumber: this.exchStatusDetails.crmRequestId,
        msProperty: this.exchStatusDetails.microsoftProperty,
        submittedBy: this.exchStatusDetails.submittedBy,
        submittedOn: moment(this.exchStatusDetails.submittedDateTime).format('M/D/YYYY hh:mm A ZZ'),
        capabilities: this.exchStatusDetails.capability,
        target: this.exchStatusDetails.target,
        requestType: this.exchStatusDetails.requestType,
        destination: this.exchStatusDetails.destination,
        operation: this.exchStatusDetails.operation,
        endDate: moment.utc(this.exchStatusDetails.endDate).format('M/D/YYYY hh:mm A ZZ'),
        location: this.exchStatusDetails.location,
        captureFrom: moment.utc(this.exchStatusDetails.captureFromDate).format('M/D/YYYY hh:mm A ZZ'),
        captureThru: moment.utc(this.exchStatusDetails.captureThruDate).format('M/D/YYYY hh:mm A ZZ'),
        emailFilter: this.exchStatusDetails.emailFilter == null ? ' ' : this.exchStatusDetails.emailFilter,
        preservationSearchRegions: this.exchStatusDetails.preservationSearchRegions,
        customerTenantType: this.exchStatusDetails.customerTenantType,
        newEndDate: '',
        newStartDate: '',
        orderDuration: '',
        modifyCrmNumber: '',
      });
    } else if (this.validateSkypeLiRequest(this.skypeRequestStatus.microsoftProperty)) {
      this.detailsForm.setValue({
        crmNumber: this.skypeRequestStatus.crmRequestId,
        msProperty: this.skypeRequestStatus.microsoftProperty,
        submittedBy: this.skypeRequestStatus.submittedBy,
        submittedOn: this.skypeRequestStatus.submittedDateTime,
        capabilities: this.skypeRequestStatus.capability,
        target: this.skypeRequestStatus.target,
        requestType: this.skypeRequestStatus.requestType,
        destination: '',
        operation: '',
        endDate: moment.utc(this.skypeRequestStatus.requestEndDate).format('M/D/YYYY hh:mm A ZZ'),
        newEndDate: '',
        newStartDate: '',
        orderDuration: '',
        modifyCrmNumber: '',
        location: '',
        captureFrom: '',
        captureThru: '',
        emailFilter: '',
        preservationSearchRegions: '',
        customerTenantType: '',
      });
    }
  }

  detask() {
    this.deleteRequest = true;
    this.extendRequest = false;
    this.newStartDateControl.setValue(null);
    this.newStartDateControl.clearValidators();
    this.newStartDateControl.updateValueAndValidity();
    this.orderDurationControl.setValue(null);
    this.orderDurationControl.clearValidators();
    this.orderDurationControl.updateValueAndValidity();
  }

  extend() {
    this.deleteRequest = false;
    this.extendRequest = true;
    this.newStartDateControl.setValidators(Validators.required);
    this.newStartDateControl.updateValueAndValidity();
    this.orderDurationControl.setValidators([
      Validators.min(0),
      Validators.required
    ]);
    this.orderDurationControl.updateValueAndValidity();
  }

  submit() {
      this.submittingModification = true;
      const request: ModifyRequest = new ModifyRequest();

      try {
        request.crmTicketNumber = this.detailsForm.get('modifyCrmNumber').value;
        request.endDate = this.detailsForm.get('newEndDate').value;
        request.subscriberStatusId = Number(this.reqStatusSummary.id);
        request.identifier = this.reqStatusSummary.target;
        request.msProperty = this.msProperty;
        request.requestType = this.requestType;
        request.destination = this.destination;

        if (this.deleteRequest) {
          this.requestSubmitter
            .unsubscribeRequest(request)
            .pipe(takeUntil(this.ngUnsub))
            .subscribe(response => this.submitExchangeModificationToTorus(request, response));
        } else {
          this.requestSubmitter
            .extendRequest(request)
            .pipe(takeUntil(this.ngUnsub))
            .subscribe(response => this.submitExchangeModificationToTorus(request, response));
        }
      } catch (e) {
        this.submittingModification = false;
      }
  }

  completeSubmitModification() {
    this.submittingModification = false;
    this.closeDialog();
  }

  calcExpirationDate() {
    const newEndDate = moment.utc(this.newStartDateControl.value).add(Number.parseInt(this.orderDurationControl.value),'d').hour(23).minute(59);
    this.newEndDateControl.setValue(moment(newEndDate).format('M/D/YYYY hh:mm A ZZ'));
    this.newEndDateControl.updateValueAndValidity();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  shouldShowActions() {
    return this.msProperty === 'Exchange'
      && ((this.exchStatusDetails.operation === 'Subscribe' && this.exchStatusDetails.currentStatus === 'Completed') ||
          (this.exchStatusDetails.operation === 'Preservation' && this.exchStatusDetails.currentStatus === 'Completed'));
  }

  private getFormControl(formControlName: string): FormControl {
      return (this.detailsForm.get(formControlName) as FormControl) || null;
  }

  private getFormControlValue(formControlName: string): string {
    return this.getFormControl(formControlName).value;
  }

  private submitExchangeModificationToTorus(request: ModifyRequest, lensRequestId: string, ) {
    // no torus for preservation extensions, so just return
    if (request.requestType === "Preservation") {
      this.submittingModification = false;

      var diagRef = this.dialog.open(DialogComponent, {
        width: 'flex',
        data: 'The preservation has been extended.'});
      
      setTimeout(() => { diagRef.close(); this.dialogRef.close()}, 3000);
    }
    else {
      // Create a NewRequest model for the torus submission
      let capabilities = this.exchStatusDetails.capability.split(",").map(x => x.trim()[0].toLowerCase() + x.trim().slice(1));
      let torusModel = new NewRequest();
      torusModel.endDate = this.deleteRequest ? new Date() : request.endDate;
      torusModel.microsoftProperty = this.exchStatusDetails.microsoftProperty;
      torusModel.requestType = this.exchStatusDetails.requestType;
      torusModel.identifiers = { ['AccountName']: [{identifier: this.exchStatusDetails.target, customerTenantType: CustomerTenantType.Unspecified} ] };
      torusModel.capabilities = {};
      capabilities.forEach(capability => {
        torusModel.capabilities[capability] = [capability];
      });
      torusModel.isDetask = this.deleteRequest;
      torusModel.isExtend = this.extendRequest;

      // Configure dialog with the appropriate options and data. Don't let users close as this will
      // be showing incrementing counters for staging container (and sometimes shadow accounts)
      const dialogConfig = new MatDialogConfig();
      dialogConfig.width = 'flex';
      dialogConfig.autoFocus = true;
      dialogConfig.data = {request: torusModel, lensRequestId: lensRequestId, historicalIdentifierId: 1};
      dialogConfig.disableClose = true;

      // All of the UI logic is contained within the TorusSubmittingDialogComponent
      this.torusDialogRef = this.dialog.open(TorusSubmittingDialogComponent, dialogConfig);

      this.torusDialogRef.afterClosed().subscribe(
        data => this.completeSubmitModification()
      );  
    }  
  }

  private validateSkypeLiRequest(msProperty: string): boolean {
    const msp = msProperty ? msProperty.toLowerCase() : null;
    return msp === 'skype' || msp === 'teams'; 
  }
}
