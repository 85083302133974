export class IpLocation {
  ip: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  organization: string;
  connectionType: string;
  timestamp: Date;
  highlightIp: boolean;

  static fromJSONArray(array: Array<any>): IpLocation[] {
    return array.map(obj => new IpLocation(obj));
  }

  constructor(obj: Object) {
    this.ip = obj['Ip'];
    this.city = obj['City'];
    this.state = obj['State'];
    this.country = obj['Country'];
    this.postalCode = obj['PostalCode'];
    this.organization = obj['Organization'];
    this.connectionType = obj['ConnectionType'];
    this.timestamp = obj['Timestamp'];
    this.highlightIp = obj['HighlightIp']
  }
}
