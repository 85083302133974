<!DOCTYPE html>
<ng-template #content><ng-content></ng-content></ng-template>
<a *ngIf="!externalLink" mat-list-item [routerLink]="routerLink">
    <mat-icon mat-list-icon>{{ icon }}</mat-icon>
    <span matLine><ng-container *ngTemplateOutlet="content"></ng-container></span>
    <span matLine class="secondary">{{ hint }}</span>
</a>
<a *ngIf="externalLink" mat-list-item target="_blank" [href]="externalLink">
    <mat-icon mat-list-icon>{{ icon }}</mat-icon>
    <span matLine><ng-container *ngTemplateOutlet="content"></ng-container></span>
    <span matLine class="secondary">{{ hint }}</span>
</a>