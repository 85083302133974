import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, Subject, timer, from } from 'rxjs';
import { first, takeUntil, concatMap, filter, take, timeout } from 'rxjs/operators';
import { IdentifierInfo } from '../../models/identifier-info.model';
import { MicrosoftProperty } from '../../models/microsoft-property.model';
import { CustomerTenantType, IdentifierModel, NewRequest } from '../../models/new-request.model';
import { CrmIdentifierService, StateSelectors as fromCrmIdentifier } from '../../services/crmidentifier.service';
import { MicrosoftPropertiesService, StateSelectors as fromMsProperties } from '../../services/microsoft-properties.service';
import { RequestSubmitterService, StateSelectors as fromRequestSubmitter, Status } from '../../services/request-submitter.service';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'
import { RegionsService } from 'src/app/lens-request/services/regions.service';
import { TorusSubmissionService } from 'src/app/services/torus-submission.service';
import { RequestStatusService } from 'src/app/services/request-status.service';
import { ExchangeTorusReadyStatus } from 'src/app/models/exchange-torus-ready-status.model';
import { TorusProxyScriptResponse } from 'src/app/models/torus-proxy-script-response.model';
import { TorusErrorDialogComponent } from 'src/app/components/torus-error-dialog/torus-error-dialog.component';
import * as _ from 'lodash';
import { TorusSubmittingDialogComponent } from 'src/app/components/torus-submitting-dialog/torus-submitting-dialog.component';
import { TorusSubmissionResponse } from 'src/app/models/torus-submission-response.model';
import { ProcessingRegion } from 'src/app/lens-request/models/processing-region.model';
import { environment } from 'src/environments/environment';
import { ConsumerEnterpriseOpts } from '../../models/consumer-enterprise-opts';
import { CustomerTenantTypeEnum } from 'src/app/services/delivery-review.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'lens-request-creator',
    templateUrl: './lens-request-creator.component.html',
    styleUrls: ['./lens-request-creator.component.scss'],
})
export class LensRequestCreatorComponent
    implements OnInit, OnDestroy {

    ngUnsub: Subject<void> = new Subject<void>();
    public lensRequestCreatorForm: FormGroup;
    public overrideJurisdictionRestriction: boolean = false;

    msProperties$: Observable<MicrosoftProperty[]>;
    msLoaded$: Observable<boolean>;
    msLoading$: Observable<boolean>;

    crmIdentifiers$: Observable<{ [key: string]: Array<IdentifierInfo> }>;
    crmLoaded$: Observable<boolean>;
    crmLoading$: Observable<boolean>;

    processingRegions$: Observable<ProcessingRegion[]>;
    processingRegionsLoaded$: Observable<boolean>;
    processingRegionsLoading$: Observable<boolean>;

    regions: { [key: string]: string[] };
    submissionStatus$: Observable<Status>;
    torusDialogRef: MatDialogRef<any>;

    isRequestSubmitting: boolean = false;
    isTorusSuccessful: boolean = false;
    isTorusSubmitting: boolean = false;
    torusSubmissionResponse: TorusSubmissionResponse;

    get datesControl() {
        const control = this.lensRequestCreatorForm.get('dates');
        return control || null;
    }
    get msProperty() {
        return this.msPropertyControl ? this.msPropertyControl.value : null;
    }

    get msPropertyControl() {
        const control = this.lensRequestCreatorForm.get('msProperty');
        return control || null;
    }

    get capabilitiesControl() {
        const control = this.lensRequestCreatorForm.get('capabilities');
        return control || null;
    }

    // LNS Request ID
    get crmIdentifier() {
        return this.crmIdentifierControl ? this.crmIdentifierControl.value : null;
    }

    get crmIdentifierControl() {
        const control = this.lensRequestCreatorForm.get('crmIdentifier');
        return control || null;
    }

    get identifiersControl() {
        const control = this.lensRequestCreatorForm.get('identifiers');
        return control || null;
    }
    get crmIdentifiers(): { [key: string]: Array<any> } {
        return this.crmIdentifiersControl ? this.crmIdentifiersControl.value : null;
    }

    get crmIdentifiersControl() {
        if (this.identifiersControl) {
            const control = this.identifiersControl.get('crmIdentifiers');
            return control ? control : null;
        } else {
            return null;
        }
    }

    get requestType() {
        return this.requestTypeControl ? this.requestTypeControl.value : null;
    }

    get requestTypeControl() {
        const control = this.lensRequestCreatorForm.get('requestType');
        return control ? control : null;
    }

    get regionControl() {
        const control = this.lensRequestCreatorForm.get('regions');
        return control ? control : null;
    }

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private crmIdentifierService: CrmIdentifierService,
        private msPropertiesService: MicrosoftPropertiesService,
        private submissionService: RequestSubmitterService,
        private ref: ChangeDetectorRef,
        private aiLogService: AiLoggingService,
        private regionService: RegionsService,
        private dialog: MatDialog,
        private torusService: TorusSubmissionService
    ) { }

    ngOnInit() {
        this.aiLogService.logPageView('New LENS Request');
        this.lensRequestCreatorForm = this.fb.group({
        });
        this.setupMsProperties();
        this.setupCrmIdentifiers();
        this.setupProcessingRegions();
        this.submissionStatus$ = this.submissionService.select(
            fromRequestSubmitter.STATUS
        );
        this.regionService.getRegions().pipe(takeUntil(this.ngUnsub)).subscribe(regionDict => {
            this.regions = regionDict;
        });

        // Listen for events from the Torus service
        this.torusService.torusObservable$.pipe(takeUntil(this.ngUnsub)).subscribe((model: TorusSubmissionResponse) => {
            // Grab the published model and set some properties to control the UI
            this.torusSubmissionResponse = model;
            this.isTorusSuccessful = !this.torusSubmissionResponse.error;
            this.isTorusSubmitting = false;

            // If successful just close the dialog
            if (this.isTorusSuccessful) {
                this.torusDialogRef.close();
            }
            else {
                if (!model.retryable) {
                    this.submissionService.submitTorusFailure(model.requestId, false, model.errorMessage).subscribe();
                }
            }

            this.isRequestSubmitting = false;
            // Due to some UI weirdness, detect changes again to ensure the UI appropriately updates
            this.detectChanges();
        });
    }

    ngOnDestroy() {
        this.ngUnsub.next();
        this.ngUnsub.complete();
    }

    private setupCrmIdentifiers() {
        this.crmIdentifiers$ = this.crmIdentifierService.select(
            fromCrmIdentifier.CRMIDENTIFIERS
        );
        this.crmLoaded$ = this.crmIdentifierService.select(
            fromCrmIdentifier.LOADED
        );
        this.crmLoading$ = this.crmIdentifierService.select(
            fromCrmIdentifier.LOADING
        );

        // if there is a CRM Identifier in the route, let's query it.
        this.route.queryParamMap
            .pipe(takeUntil(this.ngUnsub))
            .subscribe((params: ParamMap) => {
                if (params.has('crmRequestId')) {
                    const crmRequestId = params.get('crmRequestId');
                    this.lensRequestCreatorForm.addControl(
                        'crmIdentifier',
                        new FormControl(crmRequestId)
                    );
                    this.crmIdentifierService
                        .getCrmRequestData(crmRequestId).pipe(
                            first())
                        .subscribe();
                }
            });
    }

    private setupMsProperties() {
        this.msProperties$ = this.msPropertiesService.getMicrosoftPropertiesData();
        this.msLoaded$ = this.msPropertiesService.select(fromMsProperties.LOADED);
        this.msLoading$ = this.msPropertiesService.select(fromMsProperties.LOADING);
    }

    private setupProcessingRegions() {
        this.processingRegions$ = this.regionService.getExchangeRegions();
        this.processingRegionsLoaded$ = this.regionService.select(fromMsProperties.LOADED);
        this.processingRegionsLoading$ = this.regionService.select(fromMsProperties.LOADING);
    }

    goBack(stepper: MatStepper) {
        stepper.previous();
    }

    goForward(stepper: MatStepper) {
        setTimeout(() => { stepper.next(); }, 200);
    }

    canOverrideJurisdictionRule() {
        return this.requestType == 'realTime' && (this.msProperty == "Skype" || this.msProperty == "Teams");
    }

    updateOverrideJurisdiction() {
        this.overrideJurisdictionRestriction = !this.overrideJurisdictionRestriction;
    }

    transformIdentifiers(identifiers: { [id: string]: any[] } ): { [id: string]: IdentifierModel[] } {
        const transformedIdentifiers: { [id: string]: IdentifierModel[] } = {};
        for (const id in identifiers) {
            if (identifiers.hasOwnProperty(id)) {
                transformedIdentifiers[id] = identifiers[id].map(identifier => {
                        if(typeof(identifier) === 'string'){
                            return {
                                identifier: identifier,
                                customerTenantType: CustomerTenantType.Unspecified
                            };
                        }
                        else
                            return identifier;
                    }
                )
            };
        }

        return transformedIdentifiers;
    }

    prepareRequest(): NewRequest {
        const newRequest = new NewRequest();
        newRequest.crmTicketNumber = this.crmIdentifier;
        newRequest.separateOutputPerIdentifier = this.identifiersControl
            .get('separateFilePerIdentifier').value;
        newRequest.identifiers = this.transformIdentifiers(this.crmIdentifiers);
        newRequest.startDate = this.lensRequestCreatorForm.get('dates').get('startDate')
            .value as Date;
        newRequest.endDate = this.lensRequestCreatorForm.get('dates').get('endDate')
            .value as Date;
        newRequest.microsoftProperty = this.msProperty;
        newRequest.requestType = this.requestType;
        newRequest.capabilities = this.lensRequestCreatorForm.get('capabilities').value;
        newRequest.jurisdiction = this.lensRequestCreatorForm.get('jurisdiction').get('jurisdiction').value;
        newRequest.processingRegion = this.lensRequestCreatorForm.get('regions').get('processingRegion').value;
        newRequest.emailFilter = this.lensRequestCreatorForm.get('emailFilter').get('emailFilter').value;
        newRequest.searchRegions = this.getSearchRegions();
        newRequest.overrideJurisdictionRestriction = this.overrideJurisdictionRestriction;
        return newRequest;
    }

    submitRequest() {
        this.isTorusSubmitting = false;
        this.isTorusSuccessful = false;
        this.torusSubmissionResponse = undefined;
        this.isRequestSubmitting = true;
        const requestToSubmit = this.prepareRequest();
        const logSubmitType = requestToSubmit.microsoftProperty + ' ' + requestToSubmit.requestType;
        this.aiLogService.logInformation('Submitting ' + logSubmitType + ' request to the Compliance API.', {
            crmTicketNumber: this.crmIdentifier
        });

        const useExchangeEndpoint = this.msProperty.toLowerCase() === 'exchange' && (this.requestType.toLowerCase() === 'preservation' || this.requestType.toLowerCase() === 'historical');

        const endpoint = useExchangeEndpoint ? environment.newExchangeRequestUrl : environment.newRequestUrl;

        this.submissionService
            .postNewRequestModel(requestToSubmit, endpoint)
            .pipe(takeUntil(this.ngUnsub))
            .subscribe(response => {
                this.isRequestSubmitting = false;

                // Take an extra step of submitting Torus if this is an Exchange request
                if (this.isExchangeMsProperty()) {
                    this.submitExchangeTorus(requestToSubmit, response);
                }
            });
    }

    redirectUserToPostSubmission() {
        this.router.navigate(['request-status-summary'], { queryParams: { microsoftProperty: this.msProperty } });
    }

    isExchangeMsProperty() {
        return this.msProperty && this.msProperty.toLowerCase() === 'exchange';
    }

    getConsumerEnterpriseOptions(msProperties: MicrosoftProperty[]): ConsumerEnterpriseOpts {
        if(this.msProperty && this.requestType){
            const selected = msProperties.find(x => x.name.toLowerCase() == this.msProperty.toLowerCase());

             if(selected){
                 return selected.consumerEnterpriseOpts.find(x => x.requestType.toLowerCase() == this.requestType.toLowerCase());
             }
        }

        return undefined;
    }

    showRequestResults() {
        // Used to show the request-results component. We want to show in the following cases:
        //  - Show if request is submitting
        //  - Show is this is not an Exchange request
        //  - Show if Torus is done submitting and it was a successful Torus request
        return this.isRequestSubmitting || !this.isExchangeMsProperty() || (!this.isTorusSubmitting && this.isTorusSuccessful);
    }

    showTorusError() {
        // Used to show Torus error
        return this.torusSubmissionResponse && this.torusSubmissionResponse.error;
    }

    isIdentifiersNextDisabled() {
        const isDisabled = (this.identifiersControl !== null && this.identifiersControl.invalid)
            || (this.datesControl !== null && this.datesControl.invalid)
            || (this.isExchangeMsProperty() && this.regionControl !== null && this.regionControl.invalid);

        return isDisabled;
    }

    // Forces change detected at this container level after a very short delay.
    // This is used for edge cases resulting in components updated after async events complete &&
    // after Angular's change detection runs.
    detectChanges() {
        setTimeout(() => { this.ref.detectChanges(); }, 100);
    }

    private submitExchangeTorus(request: NewRequest, lensRequestId: string, ) {
        // Set Torus submitting flag for UI use
        this.isTorusSubmitting = true;

        // Configure dialog with the appropriate options and data. Don't let users close as this will
        // be showing incrementing counters for staging container (and sometimes shadow accounts)
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = 'flex';
        dialogConfig.autoFocus = true;
        dialogConfig.data = {request: request, lensRequestId: lensRequestId};
        dialogConfig.disableClose = true;

        // All of the UI logic is contained within the TorusSubmittingDialogComponent
        this.torusDialogRef = this.dialog.open(TorusSubmittingDialogComponent, dialogConfig);
        this.torusDialogRef.afterClosed().subscribe((canceled: boolean) => {
            if (canceled) {
                this.submissionService.submitTorusFailure(lensRequestId, true, 'User canceled Torus request.').subscribe();
            }
        });
    }

    private getSearchRegions(): string[] {
        const regionForm = (this.lensRequestCreatorForm.get('regions') as FormGroup);
        var keys = Object.keys(regionForm.controls);
        return keys.filter((key) => key != 'processingRegion' && key != 'searchRegions')
    }
}
