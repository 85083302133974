

const baseUri = 'https://apim-lens-dev.azure-api.net/complianceportalapi/api';
const authClientId='dc6055e0-d8aa-41cc-b121-02a8f1986cd8';

export const environment = {
  baseUri: baseUri,
  authClientId: authClientId,
  production: true,
  envName: 'azuredev',
  appInsightsConfig: {
    key: 'b5a069e8-5023-4a98-8cb9-2eff98eeb093' // AME Dev appinsights
  },
  eventProcessor:
  {
    accountStatusPath: 'api/eventprocessor/api/AccountLookupStatus', // POST
    requestStatusPath: 'api/eventprocessor/api/request-status-data', // GET
    requestStatusPagedPath: 'api/eventprocessor/api/request-status-data/paged', // POST
    skypeRequestDetailsPath: 'api/eventprocessor/api/request-status-data/skype', // GET
    skypeStatusPath: 'api/eventprocessor/api/SkypeStatus', // GET
    exchangeRequestDetailsUrl: 'api/eventprocessor/api/request-status-data/exchange', // GET
  },
  accountLookupUrl: 'api/account-lookup',
  skypeAccountLookupUrl: 'api/skype-account-lookup',
  capabilitiesUrl: 'api/capabilities',
  crmRequestUrl: 'api/crmRequest',
  dataFieldsUrl: 'api/data-fields',
  ipLookupUrl: 'api/ip-lookup',
  menuItemsUrl: 'api/menu-items',
  microsoftPropertiesUrl: 'api/microsoft-properties',
  newRequestUrl: 'api/new-request',
  newExchangeRequestUrl: 'api/new-exchange-request',
  newRequestDatePickersUrl: 'api/new-request-date-picker-data',
  extendRequestUrl: 'api/modify-request/extend',
  unsubscribeRequestUrl: 'api/modify-request/unsubscribe',
  deliveryReviewUrl: 'api/DeliveryReview',
  torusStatusUrl: 'api/torus-status',
  processingRegionsUrl: 'api/regions/getavailableregions',
  torusFailureUrl: 'api/torus-failure',

  webApiConfig: {
    serverUrl: 'https://apim-lens-dev.azure-api.net/complianceportalapi/'
  },

  azureADConfig: {
    // Name of the CORP Azure AD tenant.
    tenantName: 'microsoft.onmicrosoft.com',

    // Client ID from the LENS Compliance Portal Dev' application registration.
    applicationId: 'dc6055e0-d8aa-41cc-b121-02a8f1986cd8'
  },

  exchangeTorusConfig: {
    workflowVersion: "",

    // the default sas token expiration periods (in hours) for torus processing
    realtimeTorusSasTokenExpiration: 6,
    preservationTorusSasTokenExpiration: 336,
    searchTorusSasTokenExpiration: 336
  },

  exchangeConfig: {
    minimumTimespanFromExpirationForExtensionInHours: 6
  },
  
  scopes: [
    'openid',
    'profile',
    'email',
    `${authClientId}/.default`
  ]
};
