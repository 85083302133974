import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SkypeDetailsStatus } from 'src/app/models/skype-details-status.model';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { ExchangeStatusDetails } from '../models/exchange-status-details.model';
import { PagedRequestStatusSummaryRequest } from '../models/paged-request-status-summary-request.model';
import { PagedRequestStatusSummary } from '../models/paged-request-status-summary.model';
import { RequestStatusSummary } from '../models/request-status-summary.model';
import { SkypeRequestStatus } from '../models/skype-request-status.model';

export enum RequestStatusEnum {
  Submitted,
  InProgress,
  Completed,
  Error,
  PartiallyCompleted,
  Expired,
  Canceled
}

@Injectable()
export class RequestStatusService {
  constructor(
    protected baseService: BaseService
  ) { }

  getSummaryDetails() {
    return this.baseService
      .get<RequestStatusSummary[]>(
        `${environment.webApiConfig.serverUrl}${environment.eventProcessor.requestStatusPath}`
      ).pipe(catchError(error => this.baseService.handleError(error)));
  }

  getPagedSummaryDetails(request: PagedRequestStatusSummaryRequest) {
    return this.baseService
      .post<PagedRequestStatusSummary>(
        `${environment.webApiConfig.serverUrl}${environment.eventProcessor.requestStatusPagedPath}`,
        request
      ).pipe(catchError(error => this.baseService.handleError(error)));
  }

  getExchangeDetails(id) {
    return this.baseService
      .get<ExchangeStatusDetails>(
        `${environment.webApiConfig.serverUrl}${environment.eventProcessor.exchangeRequestDetailsUrl}/${id}`
      ).pipe(catchError(error => this.baseService.handleError(error)));
  }

  getSkypeDetails(id) {

    return this.baseService
      .get<SkypeRequestStatus>(
        `${environment.webApiConfig.serverUrl}${environment.eventProcessor.skypeRequestDetailsPath}/${id}`
      ).pipe(catchError(error => this.baseService.handleError(error)));
  }

  getSkypeContentDetails(parentJobId): Observable<Array<SkypeDetailsStatus>> {
    return this.baseService
      .get<Array<SkypeDetailsStatus>>(
        `${environment.webApiConfig.serverUrl}${environment.eventProcessor.skypeStatusPath}?parentJobId=${parentJobId}`
      ).pipe(catchError(error => this.baseService.handleError(error)));
  }
}
