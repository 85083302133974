import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { from, Observable, throwError } from 'rxjs';

import { AiLoggingService } from './ai-logging.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class BaseService implements OnDestroy {

  get Authenticated(){
    return this.isAuthenticated();
  }

  constructor(
    private http: HttpClient,
    private msalService: MsalService,
    private aiLogService: AiLoggingService,
    private dialog: MatDialog
  ) {
    this.aiLogService.pushCloudRoleName();
    this.aiLogService.operationStart();
  }

  private readonly unsubscribe$ = new Subject<void>(); 
  private headers = new HttpHeaders({
                      "Access-Control-Allow-Credentials": "true",
                      "Content-Type": "application/json"
                    });

  // START STANDARD OPERATIONS
  public get<T>(url: string): Observable<any> {
    return this.http.get<T>(url, {headers: this.headers});
  }

  public post<T>(url: string, body: any): Observable<any> {
      return this.http.post<T>(url, body, {headers: this.headers});
  }

  public logOut(){
    this.msalService.logoutPopup({mainWindowRedirectUri: "/home"});
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  // END STANDARD OPERATIONS

  // HELPERS & ACCESSORS
  public getFullName(){
    return this.msalService.instance.getActiveAccount().username;
  }

  private isAuthenticated() {
    return this.msalService.instance.getActiveAccount() != null;
  }

  public handleError(error: HttpErrorResponse) {
    return this.handleCustomError(error, '');
  }

  public handleCustomError(error: HttpErrorResponse, customErrorMessage: string) {
    let fullErrorMessage: string;
    let displayedErrorMessage: string = "<b>Error:</b> ";
    let retryable: boolean  = false; // default to a non-retryable error.
    let messageOnly: boolean = false;

    if (customErrorMessage.length > 0) {
      fullErrorMessage = customErrorMessage;
      retryable = false;
      displayedErrorMessage = customErrorMessage;
      messageOnly = true;
    }
    else if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      fullErrorMessage = error.error.message;
      retryable = true;
      displayedErrorMessage += 'An unexpected network error occurred!'
    }
    else {
      // The backend returned an unsuccessful response code. Log the full error and do some switching to figure out what to display:
      fullErrorMessage = `Backend returned code ${error.status} from ${error.url}, ` + `body was: ${error.error}`;

      switch (error.status) {
        case 0: {
          // CORS error - misconfiguration on our part.
          displayedErrorMessage += 'It looks like there may be a configuration issue with the Compliance Portal API - possible causes are CORS mismatch or invalid SSL certificate.'
          break;
        }
        case 400: {
          // Request rejected by the server
          displayedErrorMessage += 'It looks like you your request has been rejected.  Please ensure that you have selected the correct identifiers and options.'
          break;
        }
        case 401: {
          // User is not authenticated: Inform them.
          displayedErrorMessage += 'It looks like you don\'t have access to this site. Please validate that you\'ve onboarded to the appropriate groups.'
          break;
        }
        case 404 : {
          // Unexpected error that is not expected to succeed.
          displayedErrorMessage += 'It looks like you\'re trying to access something that doesn\'t yet exist.'
          break;
        }
        default : {
          // Handle all others as potentially retryable errors.
          displayedErrorMessage += 'An unexpected response was received from the Compliance Portal API.'
          retryable = true;
          break;
        }
      }
    }

    // Create the rest of the error message
    if (!messageOnly) {
      if (retryable) {
        displayedErrorMessage += '<br><br>This error may be transient - please retry this request.';
      }
      else {
        displayedErrorMessage += '<br><br>This request is not expected to succeed on retry.';
      }
      displayedErrorMessage += '<br><br>If you need to escalate this error, please create a ticket @ <a href="https://aka.ms/LENS-E-ICM-Escalation-Ticket">https://aka.ms/LENS-E-ICM-Escalation-Ticket</a> with this error information.';
    }

    // Log the full error to both AI and the console
    this.logError(fullErrorMessage);
    console.log(fullErrorMessage);
    console.log(error);

    // Open an error dialog to inform the user.
    this.dialog.open(DialogComponent, {
        width: 'flex',
        data: displayedErrorMessage});

    // return an ErrorObservable with a user-facing error message
    return throwError(displayedErrorMessage);
  }

  /**
   * Logs navigation info to App Insights.
   */
  logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number): void {
    this.aiLogService.logPageView(name);
  }

  /**
   * Logs an event to App Insights
   */
  logEvent(name: string, properties?: any, measurements?: any): void {
    this.aiLogService.logEvent(name, properties, measurements);
  }

  /**
   * Logs an informational trace to App Insights
   */
  logInformation(message: string, properties?: any): void {
    this.aiLogService.logInformation(message, properties);
  }

  /**
   * Logs a warning trace to App Insights
   */
  logWarning(message: string, properties?: any): void {
    this.aiLogService.logWarning(message, properties);
  }

  /**
   * Logs an error trace to App Insights
   */
  private logError(message: string, properties?: any): void {
    this.aiLogService.logError(message, properties);
  }

  /**
   * Gets the operation Id
   */
  public getOperationId(): string {
    return this.aiLogService.operationId;
  }

  /**
   * Sets the operation Id
   */
  public setOperationId(operationId: string): void {
    this.aiLogService.operationId = operationId;
  }

  /**
   * Gets the operation name
   */
  public getOperationName(): string {
    return this.aiLogService.operationName;
  }

  /**
   * Sets the operation name
   */
  public setOperationName(operationName: string): void {
    this.aiLogService.operationName = operationName;
  }

  /**
   * Starts an end-to-end operation (for telemetry correlation)
   */
  public operationStart(): void {
    this.aiLogService.operationStart();
  }

  /**
   * Clears or resets an end-to-end operation (for telemetry correlation)
   */
  public operationClear(): void {
    this.aiLogService.operationClear();
  }


}
