<div id="page">
  <div id="type-selector">
    <div *ngFor="let icon of icons">
      <div id="{{icon.serverName}}" (click)="select(icon.serverName)">
        <div [class.selected]="selected(icon.serverName) && dirty">
          <fa-icon class="fa-4x" [icon]="icon.icon"></fa-icon>
          <div class="type-label">{{icon.displayName}}</div>
        </div>
      </div>
    </div>
  </div>
</div>