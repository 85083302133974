import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AiLoggingService } from '../../../shared/services/ai-logging.service';

@Component({
  selector: 'jurisdiction-picker',
  templateUrl: './jurisdiction-picker.component.html',
  styleUrls: ['./jurisdiction-picker.component.scss']
})
export class JurisdictionPickerComponent implements OnInit {
  @Input()
  parent: FormGroup;

  @Input()
  set requestType(_: string) {
    this._requestType = _;
    if (this.form && this._availableJurisdictions) {
      this.shouldDisplayUpdate();
    }
  }
  @Input()
  msProperty: string;

  get isSkypeEurope(): boolean {
    return (
      (this.msProperty.toLowerCase() == 'skype' || this.msProperty.toLowerCase() == 'teams') &&
        this._requestType.toLowerCase() == 'realtime'
    );
  }

  form: FormGroup;
  jurisdiction: string;
  _requestType: string;
  @Input() set availableRegions(availableRegions: {[key:string]: string[]}){
    if(availableRegions){
      this._availableJurisdictions = Object.keys(availableRegions);
      if(this._requestType){
        this.shouldDisplayUpdate();
      }
    }
  };

  _availableJurisdictions: string[];

  constructor(
    private aiLogService: AiLoggingService
  ) {}

  ngOnInit() {    
    this.setupForm();
    if (this.parent) {
      this.parent.addControl('jurisdiction', this.form);
    }

    this.aiLogService.logEvent("Load JuridictionPicker component");  
  }

  private setupForm() {
    this.form = new FormGroup({
      jurisdiction: new FormControl(this.jurisdiction, Validators.required)
    });
  }

  shouldDisplay() {
    return this.isSkypeEurope;
  }

  shouldDisplayUpdate() {
    if (this.shouldDisplay()) {
      // If Skype Europe, we set Skype Europe jurisdiction regions, and default to Belgium.
      this.form.patchValue({
        jurisdiction: this._availableJurisdictions[0]
      });
    } else {
      // If the user navigates back to a form combination that does not require a jurisdiction, remove any prior selection.
      this.form.reset();
    }
  }
}
