
import {pluck, share, distinctUntilChanged,  catchError, map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { BehaviorSubject ,  Observable } from "rxjs";

import { BaseService } from "../../shared/services/base.service";
import { MicrosoftProperty } from "../models/microsoft-property.model";


export enum StateSelectors {
  MICROSOFTSPROPERTIES = "msProperties",
  LOADING = "loading",
  LOADED = "loaded"
}

export interface State {
  msProperties: MicrosoftProperty[];
  loaded: boolean;
  loading: boolean;
}

const state: State = {
  msProperties: [],
  loaded: false,
  loading: false
};

@Injectable()
export class MicrosoftPropertiesService {
  private subject = new BehaviorSubject<State>(state);
  store = this.subject
    .asObservable().pipe(
    distinctUntilChanged(),
    share(),);

  constructor(protected baseService: BaseService) {}

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  public getMicrosoftPropertiesData(): Observable<MicrosoftProperty[]> {
    if (state.msProperties.length > 0) {
      return this.store.pipe(pluck(StateSelectors.MICROSOFTSPROPERTIES));
    } else {
      // update the state to loading
      const value = this.subject.value;
      this.subject.next({ ...value, loading: true });
      return this.baseService
        .get(
          environment.webApiConfig.serverUrl +
            environment.microsoftPropertiesUrl
        )
        .pipe(
          map((response: MicrosoftProperty[]) => {
            // update the state to loaded, store properties for later to reduce future calls this session
            const value = this.subject.value;     
            this.subject.next({
              ...value,
              msProperties: response,
              loading: false,
              loaded: true
            });
            return response;
          }),
          catchError(error => this.baseService.handleError(error))
        );
    }
  }
}
