<form [formGroup]="form">

  <mat-grid-list cols="3" rowHeight="35px">
    <mat-grid-tile *ngFor="let capability of _capabilities" [colspan]="1" [rowspan]="1">
      <div class="text-inside-grid">
        <mat-checkbox        
        [checked]="isSelected(capability)"        
        [disabled]="isDisabled(capability)"        
        (change)="updateFormCapability(capability)"
        >
        {{capability.displayName}}
        </mat-checkbox>
      </div>
    </mat-grid-tile>
  </mat-grid-list> 
  <div class="button-top-margin" *ngIf="nestedCapabilities">
    <button mat-raised-button color="primary" (click)="updateFormCapabilityAll(true)">Select All</button>
    <button mat-raised-button color="primary" (click)="updateFormCapabilityAll(false)">Select None</button>
  </div>

  <mat-error *ngIf="form.errors && dirty">Please select at least one capability.</mat-error>   
  
</form>