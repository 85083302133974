<div class="router-outlet">
    <mat-horizontal-stepper [linear]="true" #stepper>
        <mat-step [stepControl]="msPropertyControl">
            <ng-template matStepLabel>Microsoft Property</ng-template>
            <microsoft-properties [parent]="lensRequestCreatorForm" [msProperties]="msProperties$ | async" [loading]="msLoading$ | async"
                [loaded]="msLoaded$ | async" (selectionMade)="goForward(stepper)">
            </microsoft-properties>
        </mat-step>

        <mat-step [stepControl]="requestTypeControl">
            <ng-template matStepLabel>Request Type</ng-template>
            <type-selection [parent]="lensRequestCreatorForm" [msProperty]="msProperty" [msProperties]="msProperties$ | async"
                (selectionMade)="goForward(stepper)"></type-selection>
        </mat-step>

        <mat-step [stepControl]="identifiersControl">
            <ng-template matStepLabel>Identifiers</ng-template>

            <datepicker [parent]="lensRequestCreatorForm" [requestType]="requestType" [msProperty]="msProperty"></datepicker>

            <jurisdiction-picker [parent]="lensRequestCreatorForm" [requestType]="requestType" [msProperty]="msProperty" [availableRegions]="regions">                
            </jurisdiction-picker>

            <div class="region-picker-wrapper">
                <region-picker [parent]="lensRequestCreatorForm" [requestType]="requestType" [msProperty]="msProperty" [skypeEuropeProcessingRegions] = "regions" 
                    [exchangeProcessingRegions] = "processingRegions$ | async" [loading]="processingRegionsLoading$ | async">
                </region-picker>
            </div>

            <div class="override-jurisdiction-wrapper">
                <mat-checkbox *ngIf="canOverrideJurisdictionRule()" (change)="updateOverrideJurisdiction()">Override Jurisdiction Restriction Rule</mat-checkbox> 
            </div>

            <email-filter [parent]="lensRequestCreatorForm" [requestType]="requestType" [msProperty]="msProperty">
            </email-filter>

            <identifiers [parent]="lensRequestCreatorForm" [crmIdentifiers]="crmIdentifiers$ | async" [crmLoaded]="crmLoaded$ | async"
                [crmLoading]="crmLoading$ | async" [selectedMsProperty]="msProperty" [requestType]="requestType" [consumerEnterpriseOptions]="getConsumerEnterpriseOptions(msProperties$ | async)"></identifiers>
            <div [ngClass]="'button bottom-button'">
                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" [disabled]="isIdentifiersNextDisabled()" matStepperNext>Next</button>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Capabilities</ng-template>

            <capabilities-selector [parent]="lensRequestCreatorForm" [selectedMsProperty]="msProperty"
                [selectedRequestType]="requestType" (formChanges)="detectChanges()">
            </capabilities-selector>

            <br>
            <div [ngClass]="'button bottom-button'">
                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" [disabled]="capabilitiesControl?.invalid" matStepperNext>Next</button>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Review submission</ng-template>
            <request-review [parent]="lensRequestCreatorForm"></request-review>

            <div [ngClass]="'button bottom-button'">
                <button mat-raised-button color="primary" matStepperPrevious>Back</button>
                <button mat-raised-button color="primary" (click)="submitRequest()" matStepperNext>Submit</button>
            </div>
        </mat-step>

        <mat-step>
            <ng-template matStepLabel>Done</ng-template>
            <request-results *ngIf="showRequestResults()" 
            [crmTicketNumber]="crmIdentifier" [submissionStatus]="submissionStatus$" (redirectUser)="redirectUserToPostSubmission()">
            </request-results>
            <div *ngIf="showTorusError()">The Torus submission failed. Please go back to the "Review Submission" step and submit again.</div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
