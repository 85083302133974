import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppInsights } from 'applicationinsights-js'

@Injectable()
export class AiLoggingService {

    private CloudRoleName: string = "CompliancePortal-UI";
    private queuedOperationId: string = null;
    private queuedOperationName: string = null;

    private config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.appInsightsConfig.key,
        disableCorrelationHeaders: false
    };

    constructor() {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }
    }

    get operationId() {
        if (typeof AppInsights.context !== 'undefined')
        {
           return AppInsights.context.operation.id;
        }
        else
        {
            return this.queuedOperationId;
        }
    }

    get operationName() {
        if (typeof AppInsights.context !== 'undefined')
        {
           return AppInsights.context.operation.name;
        }
        else
        {
            return this.queuedOperationName;
        }
    }

    set operationId(operationId: string) {
        if (typeof AppInsights.context !== 'undefined')
        {
            AppInsights.context.operation.id = operationId;
        }
        else
        {
            this.pushOperationId(operationId);
        }
    }

    set operationName(operationName: string) {
        if (typeof AppInsights.context !== 'undefined')
        {
            AppInsights.context.operation.name = operationName;
        }
    }

    logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
        AppInsights.trackPageView(name,
            url,
            properties,
            measurements,
            duration);
    }

    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name,
            properties,
            measurements);
    }

    logInformation(message: string, properties?: any) {
        AppInsights.trackTrace(message,
            properties,
            1);
    }

    logWarning(message: string, properties?: any) {
        AppInsights.trackTrace(message,
            properties,
            2);
    }

    logError(message: string, properties?: any) {
        AppInsights.trackTrace(message,
            properties,
            3);
    }

    operationStart()
    {
        this.operationId = this.newGuid();
    }

    operationClear()
    {
        this.operationId = null;
        this.queuedOperationId = null;
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
            return v.toString(16);
        });
    }

    pushCloudRoleName()
    {
        if (typeof AppInsights.queue !== undefined && AppInsights.queue instanceof Array)
        {
            AppInsights.queue.push(() => {
                AppInsights.context.addTelemetryInitializer((envelope) => {
                  envelope.tags["ai.cloud.role"] = this.CloudRoleName;
                })
            });
        }
    }

    pushOperationId(operationId: string)
    {
        if (operationId != null && typeof AppInsights.queue !== undefined && AppInsights.queue instanceof Array)
        {
            AppInsights.queue.push(() => {
                AppInsights.context.operation.id = operationId;
            });

            this.queuedOperationId = operationId;
        }
    }

    // We can add more as we like.
}
