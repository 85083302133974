import { AccountLookupAlias } from './account-lookup-alias.model';

export class AccountLookupDetails {
  id: number;
  account: string;
  status: string;
  exists: boolean;
  isConsumer: boolean;
  isCustomerLockbox: boolean;
  dataCenterLocations: string[];
  ipLogins: string[];
  identifierAlias: string;
  isComplete: boolean;
  accountType: string;
  lockboxStatus: string;
  enableIpLookups: boolean;
  statusMessage: string;
  hasPreservations: boolean;
  aliases: Array<AccountLookupAlias>;
  isPrimary: boolean;
  forceEnterprise: boolean;
  flagBothConsumerAndEnterprise: boolean;
  isArchiveProvisioned: boolean;
  hasSoftDeletedMailbox: boolean;
  isEnterpriseAndConsumer: boolean;

  static fromJSONArray(array: Array<any>): AccountLookupDetails[] {
    console.log(array);
    return array.map(obj => new AccountLookupDetails(obj));
  }

  constructor(obj: Object) {
    this.id = JSON.parse(obj['id']);
    this.account = obj['smtpAddress'];
    this.status = obj['status'];
    this.exists = JSON.parse(obj['exists']);
    this.isConsumer = JSON.parse(obj['isConsumer']);
    this.isCustomerLockbox = JSON.parse(obj['isLockbox']);
    this.dataCenterLocations = obj['dataCenterLocations'];
    this.ipLogins = obj['ipLogins'];
    this.identifierAlias = obj['identifierAlias'];
    this.isComplete = obj['status'] == 'Completed';
    this.accountType = this.isConsumer !== undefined ? this.isConsumer ? 'Consumer' : 'Enterprise' : 'n/a';
    this.lockboxStatus = this.isCustomerLockbox !== undefined ? this.isCustomerLockbox ? 'True' : 'False' : 'n/a';
    this.enableIpLookups = this.isConsumer;
    this.statusMessage = obj['statusMessage'];
    this.hasPreservations = obj['hasPreservations'];
    this.aliases = obj['aliases'].map(val => new AccountLookupAlias(val)) as Array<AccountLookupAlias>;
    this.isPrimary = JSON.parse(obj['isPrimary']);
    this.forceEnterprise = JSON.parse(obj['forceEnterprise']);
    this.flagBothConsumerAndEnterprise = JSON.parse(obj['flagBothConsumerAndEnterprise']);
    this.isArchiveProvisioned = obj['isArchiveProvisioned'];
    this.hasSoftDeletedMailbox = obj['hasSoftDeletedMailbox'];
    this.isEnterpriseAndConsumer = obj['isEnterpriseAndConsumer'];
  }
}