import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { BaseService } from '../shared/services/base.service';

@Injectable()
export class RequireAuthGuard implements CanActivate {
  private homePage: string = '/home';

  constructor(
    private baseService: BaseService, 
    private router: Router
    ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      if (this.baseService.Authenticated) {
        return true;
      } else {
        this.router.navigate([this.homePage], {queryParams: {returnUrl: state.url}});
        return false;
      }
    } catch (ex) {
      this.router.navigate([this.homePage], {queryParams: {returnUrl: state.url}});
      return false;
    }
  }
}
