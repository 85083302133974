<form [formGroup]="form" *ngIf="isExchange">
    <div class="async-control-wrapper">
        <mat-form-field class="region-picker">
            <mat-select placeholder="Processing Region" [(value)]="processingRegion" formControlName="processingRegion" (valueChange)="setDefaultSearchRegions($event)" ngDefaultControl>
                <mat-option *ngFor="let region of _displayExchangeProcessingRegions" [value]="region.RegionName">
                    {{region.RegionDescription}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-progress-spinner class="async-control-spinner" *ngIf="loading" diameter="20" mode="indeterminate"></mat-progress-spinner>
    </div>

    <div *ngIf="captureSearchRegions()">
        <small style="color:gray;">Preservation Regions to Include in Search</small>
        <mat-grid-list cols="5" rowHeight="35px">
            <mat-grid-tile *ngFor="let region of _displayExchangeProcessingRegions" [colspan]="1" [rowspan]="1">
                <div class="text-inside-grid">
                    <mat-checkbox [checked]="isSelected(region)" (change)="updateFormRegion(region)">{{region.RegionDescription}}</mat-checkbox>
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</form>

<mat-list *ngIf="showAltRegions">
    <small style="color:gray;">Alternate Processing Regions</small>
    <mat-list-item class="mat-select ng-tns-c31-11 ng-untouched ng-pristine ng-valid ng-star-inserted" *ngFor="let region of processingRegions">{{region}}
    </mat-list-item>
    <br>
</mat-list>