import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { TorusSubmittingDialogData } from 'src/app/models/torus-submitting-dialog-data.model';
import { TorusSubmissionService } from 'src/app/services/torus-submission.service';
import { ExchangeTorusReadyStatus } from 'src/app/models/exchange-torus-ready-status.model';
import * as _ from 'lodash';
import { timer, from } from 'rxjs';
import { concatMap, filter, take, timeout } from 'rxjs/operators';
import { TorusProxyScriptResponse } from 'src/app/models/torus-proxy-script-response.model';
import { AiLoggingService } from 'src/app/shared/services/ai-logging.service';
import { TorusErrorDialogComponent } from '../torus-error-dialog/torus-error-dialog.component';
import { ProxyScriptRequest } from 'dauthservice-new';
import { TorusErrorDialogData } from 'src/app/models/torus-error-dialog-data.model';
import { TorusSubmissionResponse } from 'src/app/models/torus-submission-response.model';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
    selector: 'torus-submitting-dialog',
    templateUrl: './torus-submitting-dialog.component.html',
    styleUrls: ['./torus-submitting-dialog.component.scss']
})
export class TorusSubmittingDialogComponent implements OnInit {
    public stagingContainersReadyCount: number = 0;
    public shadowAccountsReadyCount: number = 0;
    public torusPayloadHashReadyCount: number = 0;
    public totalItemCount: number;
    public message: string;
    public polling: boolean = true;
    public submitting: boolean = false;
    public error: boolean = false;
    public isRealTime: boolean;
    public isAccountLookup: boolean;
    public isPreservation: boolean;
    public isHistorical: boolean;
    public isDetask: boolean = false;
    public isExtend: boolean = false;
    public isRetry: boolean = false;
    public showShadowAccountMessage: boolean;
    private retryDialogRef: MatDialogRef<DialogComponent>;

    private historicalTargetIdentifierId: number;

    isTorusReady = (val: ExchangeTorusReadyStatus[]) => {
        // Get count of staging containers + SAS URI in the current iteration of checking ready state
        this.stagingContainersReadyCount = _.size(_.filter(val, (item: ExchangeTorusReadyStatus) =>
            item.accountIdentifier && item.stagingLocationUri));
        let ready: boolean = this.stagingContainersReadyCount === this.totalItemCount;

        // Get count of torusPayloadHash in the current iteration of checking ready state
        this.torusPayloadHashReadyCount = _.size(_.filter(val, (item: ExchangeTorusReadyStatus) =>
            item.torusPayloadHash));
        const torusPayloadHashReady = this.torusPayloadHashReadyCount === this.totalItemCount;
        // Ensure torus payload hashes are ready as well
        ready = ready && torusPayloadHashReady;

        if (this.isRealTime) {
            // Get count of shadow accounts in the current iteration of checking ready state
            this.shadowAccountsReadyCount = _.size(_.filter(val, (item: ExchangeTorusReadyStatus) =>
                item.shadowAccount));
            const shadowAccountsReady = this.shadowAccountsReadyCount === this.totalItemCount;

            // Ensure shadow accounts are ready as well
            ready = ready && shadowAccountsReady;
        }

        return ready;
    };

    isTorusResponseSuccess = (val: any) => {
        let error: boolean = val.stack && val.message;
        return !error;
    };

    constructor(
        private dialogRef: MatDialogRef<TorusSubmittingDialogComponent>,
        private torusService: TorusSubmissionService,
        private dialog: MatDialog,
        private aiLogService: AiLoggingService,
        @Inject(MAT_DIALOG_DATA) public data: TorusSubmittingDialogData
    ) {
    }

    ngOnInit() {
        // Ensure message is undefined on init so our UI looks correct.
        this.message = undefined;
        this.isRealTime = this.data.request && this.data.request.requestType && this.data.request.requestType.toLowerCase() === 'realtime';
        this.isPreservation = this.data.request && this.data.request.requestType && this.data.request.requestType.toLowerCase() === 'preservation';
        this.isHistorical = this.data.request && this.data.request.requestType && this.data.request.requestType.toLowerCase() === 'historical';
        this.isAccountLookup = !this.data.request;
        this.isDetask = this.data.request && this.data.request.isDetask;
        this.isExtend = this.data.request && this.data.request.isExtend;
        this.showShadowAccountMessage = this.isRealTime && !this.isDetask && !this.isExtend;

        // Identifiers are grouped by identifier type - reduce them and accumulate the count of each group to find
        // the total count of identifiers.
        if (this.isAccountLookup) {
            // we are now making two requests for each identifier - 1 criminal and 1 enterprise - so double the identifier count
            this.totalItemCount = this.data.identifierCount * 2;
        } else {
            this.totalItemCount = _.reduce(this.data.request.identifiers, function (memo, item) {
                return memo + _.size(item);
            }, 0);
        }

        // After 0 seconds, execute the flow through the pipes below
        timer(0 * 1000, 5 * 1000)
            // Step 1) Get ready status depending on what type of Torus request we are submitting
            .pipe(concatMap(() => {
                // These methods will return back the containers,
                // SAS URIs, and (if applicable) shadow accounts. The results will be passed into the 'isTorusReady'
                // method which will decide if the pre-requisites have been met to submit the Torus request.
                if (!this.isAccountLookup) {
                    return from(this.torusService.getExchangeReadyStatus(this.data.lensRequestId));
                } else {
                    return from(this.torusService.getAccountLookupReadyStatus(this.data.lensRequestId));
                }
            }))
            // Step 2) Determine ready status based on the ready status from Step 1.
            //         Timeout after 10 minutes. Once isTorusReady returns true, we advance to the next pipe.
            .pipe(filter(this.isTorusReady), timeout(600 * 1000))
            // Step 3) Take the first result (either a success or an error)
            .pipe(take(1))
            // Step 4) Handle the results of our operation. If there was any error or a timeout,
            //         we'll hit the error handler, otherwise we will hit success.
            .subscribe(result => this.exchangeReadySuccess(result),
                error => this.exchangeReadyError(error));

        this.torusService.torusObservable$.subscribe(response => {
            if (response.error){
                // Error could be retryable, passing in all required arguments to handle retryable errors
                this.torusError(response.errorMessage, response.retryable, response.request, response.requestType, response.requestId);
            }
        });
    }

    private exchangeReadySuccess(torusReadyStatus: ExchangeTorusReadyStatus[]) {
        this.polling = false;
        this.submitting = true;

        if (this.isAccountLookup) {
            this.torusService.accountLookup(Number.parseInt(this.data.lensRequestId), torusReadyStatus)
                .pipe(take(1))
                .subscribe((response: any) => this.handleTorusResponse(response));
        }
        else {
            if (this.isRealTime) {
                let includeContent, includeHeaders, includeDrafts, includeIpLogins, isDisabled: boolean;
                includeContent = this.data.request.capabilities.fullContent !== undefined;
                includeHeaders = this.data.request.capabilities.subHeaders !== undefined || includeContent;
                includeDrafts = includeContent;
                includeIpLogins = this.data.request.capabilities.ipLogins !== undefined;
                isDisabled = this.data.request && this.data.request.isDetask !== undefined && this.data.request.isDetask;

                this.torusService.realTime(this.data.lensRequestId, torusReadyStatus, includeContent, includeHeaders,
                    includeDrafts, includeIpLogins, isDisabled, new Date(this.data.request.endDate))
                    .pipe(take(1))
                    .subscribe((response: any) => this.handleTorusResponse(response));
            }
            if (this.isPreservation || this.isHistorical) {
              let includeContent, includeDeviceInfo, includeIpLogins, includeCalendar, includeContacts: boolean;
              includeCalendar = this.data.request.capabilities.calendar !== undefined;
              includeContacts = this.data.request.capabilities.contacts !== undefined;
              includeDeviceInfo = this.data.request.capabilities.mobileDevice !== undefined;
              includeIpLogins = this.data.request.capabilities.mailboxIpLogin !== undefined;
              includeContent =
                (this.data.request.capabilities.fullMessageContent !== undefined ||
                  this.data.request.capabilities.fullHeaderOnly !== undefined ||
                  this.data.request.capabilities.partialHeaderOnly !== undefined);

              if (this.isPreservation){
              this.torusService.preservation(this.data.lensRequestId, torusReadyStatus, includeContent, includeDeviceInfo,
                includeIpLogins, includeCalendar, includeContacts, this.isPreservation, new Date(this.data.request.startDate),
                new Date(this.data.request.endDate))
                .pipe(take(1))
                .subscribe((response: any) => this.handleTorusResponse(response));
              }
              if (this.isHistorical){
                this.torusService.historical(this.data.lensRequestId, torusReadyStatus, includeContent, includeDeviceInfo,
                  includeIpLogins, includeCalendar, includeContacts, this.isPreservation, new Date(this.data.request.startDate),
                  new Date(this.data.request.endDate))
                  .pipe(take(1))
                  .subscribe((response: any) => this.handleTorusResponse(response));
                }
            }
        }
    }

    private handleTorusResponse(response: any) {
        this.submitting = false;

        if (this.isRetry) {
            this.isRetry = false;
            this.retryDialogRef.close();
        }

        if (!(response.message && response.stack)) {
            this.torusSuccess(response);
        }
    }

    private torusSuccess(response: TorusProxyScriptResponse) {
        this.dialogRef.disableClose = false;

        // capture the torus response
        if (this.isAccountLookup) {
            this.torusService.postAccountLookupSubmittedStatus(+this.data.lensRequestId, response)
                .pipe(take(1))
                .subscribe((result: any) => console.log(result));
        }
        else {
            if (this.isRealTime || this.isPreservation || this.isHistorical) {
                this.torusService.postExchangeSubmittedStatus(this.data.lensRequestId, response)
                    .pipe(take(1))
                    .subscribe((result: any) => console.log(result));
          }
        }

        // if we are detasking or extending from the details dialog, let the user know
        if (this.isDetask || this.isExtend) {
            this.message = 'Torus submission has succeeded.  Click the "Details" link to review.';
        }
    }

    private torusError(errorMessage: string, retryable: boolean, request: ProxyScriptRequest = null, requestType: string = null, requestId: string = null) {
        // Configure dialog with the appropriate options and data
        const dialogConfig = new MatDialogConfig();
        let dialogData = new TorusErrorDialogData();
        
        dialogData.errorMessage = errorMessage;
        
        // Properties to support retryable errors
        dialogData.retryable = retryable; 
        dialogData.request = request;
        dialogData.requestType = requestType;
        dialogData.requestId = requestId;

        dialogConfig.width = 'flex';
        dialogConfig.autoFocus = true;
        dialogConfig.data = dialogData;

        // All of the UI logic is contained within the TorusSubmittingDialogComponent
        const errorDiag = this.dialog.open(TorusErrorDialogComponent, dialogConfig);

        errorDiag.afterClosed().subscribe((canceled: boolean) => {
            this.dialogRef.close(canceled);
        });

        errorDiag.componentInstance.torusRetryableError$.subscribe(data => {
            errorDiag.close();
            this.dialog.closeAll();
            this.aiLogService.logInformation('Retrying Torus submission due to retryable error thrown', {
                errorMessage: errorMessage,
                requestType: data.requestType});
            this.handleTorusRetryableError(data);
        });
    }

    private handleTorusRetryableError(data: TorusErrorDialogData) {
        if (data.retryable && data.request != null) {
            this.isRetry = true;

            this.retryDialogRef = this.dialog.open(DialogComponent, {
                width: 'flex',
                data: 'Retrying submission to Torus ...'});

            this.torusService.submitProxyScriptRequest(data.request, data.requestType, data.requestId)
            .pipe(take(1))
            .subscribe((response: any) => this.handleTorusResponse(response));
        }
    }

    private exchangeReadyError(error: any) {
        this.polling = false;
        this.error = true;
        this.dialogRef.disableClose = false;

        switch (error.name) {
            case 'TimeoutError':    // Timed out waiting for storage containers or shadow accounts
                this.message = 'The system did not create a storage container' + (this.isRealTime ? ' or shadow account ' : ' ') + 'in the expected amount of time. Please try again.';
                break;
            default:
                this.message = 'An unknown error occurred. ' + error.name + ': ' + error.message;
                break;
        }

        this.aiLogService.logError(error);
        const subjectModel = new TorusSubmissionResponse();
        subjectModel.error = true;
        subjectModel.errorMessage = this.message;
        subjectModel.retryable = false;
        this.torusService.torusSource.next(subjectModel);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
