import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import * as fromRoot from 'src/app/reducers';
import { BaseService } from '../../shared/services/base.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  constructor(
    private store: Store<fromRoot.State>,
    private baseService: BaseService,
    private msalBroadcastService: MsalBroadcastService,
    private route: ActivatedRoute,
    private router: Router
    ) {
    }

  ngOnInit() {
    this.baseService.operationClear();
    this.baseService.logPageView('Home');
    this.postLogin();
  }

  private postLogin(){
    //Post login activities after a successful login
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
    )
    .subscribe((result: EventMessage) => {
      // Casting payload as AuthenticationResult to access account
      const payload = result.payload as AuthenticationResult;
      if(payload.account !== null){
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      }
    });
  }

  // Logout Method
  public logout() {
    this.baseService.logOut();
  }

  getIsUserAuthenticated(): boolean {
    return this.baseService.Authenticated;
  }
}
