import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { emailValidator } from '../../validators/email.validators';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'email-filter',
  templateUrl: './email-filter.component.html',
  styleUrls: ['./email-filter.component.scss']
})
export class EmailFilterComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() requestType: string;
  @Input() msProperty: string;

  form: FormGroup;
  emailFilter: string = '';

  constructor(
    private aiLogService: AiLoggingService
  ) {
  }

  ngOnInit() {
    this.setupForm();
    if (this.parent) {
      this.parent.addControl('emailFilter', this.form);
    }

    this.aiLogService.logEvent("Load EmailFilter component");
  }

  get emailFilterControl() {
    return this.form.get('emailFilter');
  }
  shouldDisplay() {
     return (this.msProperty.toLowerCase() === 'exchange' && this.requestType.toLowerCase() !== 'realtime');
  }

  private setupForm() {
    this.form = new FormGroup({
      emailFilter: new FormControl(this.emailFilter, { validators: emailValidator() })
    });
  }
}
