import { Injectable } from "@angular/core";
import { MenuItem } from "src/app/models/menu-item.model";
import { BaseService } from "src/app/shared/services/base.service";
import { environment } from "src/environments/environment";
import { Observable ,  of } from "rxjs";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class MenuItemService {
  constructor(protected baseService: BaseService) {}

  public getMenuItemData(): Observable<MenuItem[]> {
    return this.baseService
      .get(environment.webApiConfig.serverUrl + environment.menuItemsUrl)
      .pipe(
        map(response => response),
        catchError(error => this.baseService.handleError(error))
      );
  }
}
