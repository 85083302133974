import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faCommentDots, faPaperclip, faRedo, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { ResubmitModel } from 'src/app/lens-request/models/resubmit.model';
import { RequestSubmitterService } from 'src/app/lens-request/services/request-submitter.service';
import { RequestStatusSummary } from 'src/app/models/request-status-summary.model';
import { SkypeDetailsStatus } from 'src/app/models/skype-details-status.model';
import { RequestStatusEnum, RequestStatusService } from 'src/app/services/request-status.service';
import { Subject, timer, throwError, of } from 'rxjs';
import { repeatWhen, takeUntil, delay, catchError, take } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
@Component({
  selector: 'app-skype-content-details',
  templateUrl: './skype-content-details.component.html',
  styleUrls: ['./skype-content-details.component.scss']
})
export class SkypeContentDetailsComponent implements OnInit {
  // Set when the dialog gets called
  public reqStatusSummary: RequestStatusSummary;

  // Icons
  faCommentDots: IconDefinition;
  faPaperclip: IconDefinition;
  faRedo: IconDefinition;
  faCheckCircle: IconDefinition;
  faTimesCircle: IconDefinition;
  faWindowClose: IconDefinition;

  // Subjects and Observables
  ngUnsub: Subject<void> = new Subject<void>();
  refreshDetail$  = new Subject<void>();
  refresh$ = this.refreshDetail$.asObservable();

  // Properties
  skypeContentDetails: Array<SkypeDetailsStatus> = [];
  displayTimeForIcons: number = 5000;
  loading: boolean;
  panelOpenState = false;
  resubmitting: Map<number, boolean> = new Map<number, boolean>();
  showSuccess: Map<number, boolean> = new Map<number, boolean>();
  showFailure: Map<number, boolean> = new Map<number, boolean>();

  constructor(
    public dialogRef: MatDialogRef<SkypeContentDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private requestService: RequestStatusService,
    private requestSubmitService: RequestSubmitterService,
    private clipboardService: ClipboardService,
    private snackBar: MatSnackBar
  ) {
    this.faCommentDots = faCommentDots;
    this.faPaperclip = faPaperclip;
    this.faRedo = faRedo;
    this.faTimesCircle = faTimesCircle;
    this.faCheckCircle = faCheckCircle;
    this.faWindowClose = faWindowClose;
  }

  hasChild = (_: number, node) => !!node.children && node.children.length > 0;

  get totalIdentifiers() {
    if (this.skypeContentDetails) {
      return this.skypeContentDetails.length;
    }
  }

  get identifiersComplete() {
    if (this.skypeContentDetails) {
      let count = 0;
      this.skypeContentDetails.forEach(detail =>
        detail.status == RequestStatusEnum.Completed ? count++ : count
      );
      return count;
    }
  }

  ngOnInit() {
    this.loading = true;
    if(this.reqStatusSummary && this.reqStatusSummary.id){
      this.requestService
        .getSkypeContentDetails(this.reqStatusSummary.id)
        .pipe(takeUntil(this.ngUnsub), repeatWhen(() => this.refresh$))
        .subscribe({
          next: (data: Array<SkypeDetailsStatus>) => {
            this.skypeContentDetails = data;
            this.panelOpenState = true;
            this.loading = false;
          }
        });
    }
  }

  getOverallProgress(){
    if(this.totalIdentifiers > 0){
      const answer =  this.identifiersComplete / this.totalIdentifiers * 100;
      return answer;
    }
    return 0;
  }

  getDetailProgress(detail: SkypeDetailsStatus) {
    // Currently, this should never happen as we don't show details when it's just Submitted
    if(detail.status == RequestStatusEnum.Submitted){
      return 0;
    }
    // Done is done! Sometimes all conversations complete without results, so we can't rely on the below clause
    if(detail.status == (RequestStatusEnum.Completed || RequestStatusEnum.PartiallyCompleted)){
      return 100;
    }
    // Sets bar to red.
    if(detail.status == (RequestStatusEnum.Error || RequestStatusEnum.PartiallyCompleted)){
      return 110;
    }
    // This makes up roughly 80% of the work
    if(detail.skypeConversations && detail.skypeConversations.length > 0){
      const progress = detail.skypeConversations.filter(c => c.status == (RequestStatusEnum.Completed || RequestStatusEnum.Error)).length /  detail.skypeConversations.length;
      return progress * 80;
    }
    //They're about to be done
    if(detail.submitGeoUploadStart){
      return 95;
    }
    // they're at least past conversation gathering
    if(detail.reportGenerationStart){
      return 85
    }
    // they're at least in progress but have no conversations or those conversations have no results
    return 10;
  }

  refresh(){
    this.loading = true;
    this.refreshDetail$.next();
  }

  resubmit(detail: SkypeDetailsStatus){
    // Create submission
    const resubmission: ResubmitModel = {
      parentJobId: Number(this.reqStatusSummary.id),
      subJobId: detail.id,
      identifier: detail.identifier
    }

    // Reset any statuses in the UI
    this.showSuccess.set(detail.id, false);
    this.showFailure.set(detail.id, false);

    // Show spinner
    this.resubmitting.set(detail.id, true);

    // Send request
    this.requestSubmitService
    .postResubmitRequest(resubmission)
    .pipe(
      takeUntil(this.ngUnsub),
      catchError(() => {
        // turn off spinner, show the failure icon
        this.resubmitting.set(detail.id, false);
        this.showFailure.set(detail.id, true);
        return of("Unable to process request.")
      }))
      .subscribe(() => {
        // turn off the spinner, show the success icon
        this.resubmitting.set(detail.id, false);
        this.showSuccess.set(detail.id, true);
        // trigger a refresh after briefly showing the success icon
        timer(this.displayTimeForIcons).pipe(take(1)).subscribe(() => {
          this.showSuccess.set(detail.id, false);
          this.refreshDetail$.next()
        })
      })
  }

  copyDetails(details: string){
    this.clipboardService.copyFromContent(details);

    this.snackBar.open('Details Copied!', 'Ctrl+V to paste', {
      duration: 3000
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
