import { createSelector } from 'reselect';
import { tassign } from 'tassign';
import { RequestStatusSummary } from 'src/app/models/request-status-summary.model';
import * as requestStatusSummary from 'src/app/actions/request-status-summary';

export interface IRequestStatusState {
  requestStatusSummaryArray: RequestStatusSummary[];
}

export const initialState: IRequestStatusState = {
  requestStatusSummaryArray: []
};

export function reducer(state = initialState, action: requestStatusSummary.Actions): IRequestStatusState {
  switch (action.type) {

    // Add a list of constants to the existing list of constants.
    case requestStatusSummary.ADDED: return addedRequestStatus(state, action);
    case requestStatusSummary.DELETED: return deletedRequestStatus(state, action);
    case requestStatusSummary.REFRESHED: return refreshedRequestStatus(state, action);
    default:
      return state;
  }
}

function addedRequestStatus(state, action) {
  return tassign(state, {
    requestStatusSummaryArray: [...state.requestStatusSummaryArray, ...action.payload]
  } as IRequestStatusState);
}

function refreshedRequestStatus(state, action): IRequestStatusState {
  return tassign(state, {
    requestStatusSummaryArray: [...action.payload]
  } as IRequestStatusState);
}

function deletedRequestStatus(state, action): IRequestStatusState {
  return tassign(state, {
    requestStatusSummaryArray: []
  } as IRequestStatusState);
};

export const getRequestStatusItems = (state: IRequestStatusState) => state.requestStatusSummaryArray;
