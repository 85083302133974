import { PagedRequestStatusSummary } from 'src/app/models/paged-request-status-summary.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { CollectionViewer, DataSource } from '../../../node_modules/@angular/cdk/collections';
import { PagedRequestStatusSummaryRequest } from '../models/paged-request-status-summary-request.model';
import { RequestStatusSearch } from '../models/request-status-search.model';
import { RequestStatusSummary } from '../models/request-status-summary.model';
import { RequestStatusService } from '../services/request-status.service';

export class RequestStatusSummaryDataSource
  implements DataSource<RequestStatusSummary> {
  public statusCount: number;
  private summarySubject = new BehaviorSubject<RequestStatusSummary[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  public loading$ = this.loadingSubject.asObservable();

  constructor(private statusService: RequestStatusService) {}

  connect(
    collectionViewer: CollectionViewer
  ): Observable<RequestStatusSummary[]> {
    return this.summarySubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.summarySubject.complete();
    this.loadingSubject.complete();
  }

  loadData(
    sort: string,
    search: RequestStatusSearch,
    page: number,
    size: number
  ) {
    this.loadingSubject.next(true);

    this.statusService
      .getPagedSummaryDetails(this.buildRequestObject(sort, search, page, size))
      .pipe(finalize(() => this.loadingSubject.next(false)))
      .subscribe((summary: PagedRequestStatusSummary) => {
        this.statusCount = summary.totalItems;
        this.summarySubject.next(summary.statusSummaries);
      });
  }

  buildRequestObject(
    requestedSort: string,
    requestedSearch: RequestStatusSearch,
    requestedPage: number,
    requestedSize: number
  ): PagedRequestStatusSummaryRequest {
    const request: PagedRequestStatusSummaryRequest = {
      sort: requestedSort,
      search: requestedSearch,
      page: requestedPage,
      size: requestedSize
    };

    return request;
  }
}
