import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from "@azure/msal-angular";
import { AddAction as AddMenuItemsAction } from "src/app/actions/menu-item";
import { MenuItem } from "src/app/models/menu-item.model";
import * as fromRoot from "src/app/reducers";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { BaseService } from "../../shared/services/base.service";
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit{
  menuItems$: Observable<MenuItem[]>;
  private readonly _destroying$ = new Subject<void>();
  public fullName$ = new BehaviorSubject<string>('');
  public expirationTime: Date;

  constructor(
    private store: Store<fromRoot.State>,
    private baseService: BaseService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {
  }

  ngOnInit(): void {
    this.login();
    this.menuItems$ = this.store.select(fromRoot.getMenuItems);
  }

  public login():void{
      //Attempt login only if there isn't already a MSAL login session in progress
      this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        if (this.msalService.instance.getAllAccounts().length === 0){
          this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
        }
        if(this.msalService.instance.getActiveAccount() !== null){
          this.postLoginActivities();
        }
      })

      //Post login activities after a successful login
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        // Casting payload as AuthenticationResult to access account
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        //Continue with post login activities
        this.postLoginActivities();
      });
      
      //Log login failures 
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      )
      .subscribe((result: EventMessage) => {
        this.baseService.logInformation(
          "Failed Login: ",
          {
            details:
              "User = " +
              this.fullName$
          }
        )
      });
  }

  public postLoginActivities():void{
    // Load the menu data only if the user is already authenticated.
    if (this.getIsUserAuthenticated()) {
      // If the session came from an AAD login, restore the captured URL to get any query parameters that might have been lost.
      // We that it's not an iframe to make sure it's not an silent token refresh - we don't want the burden of redirecting in that case.

      // log the successful login
      this.baseService.logInformation(
        "Successful Login: ",
        {
          details:
            "User = " +
            this.fullName$
        }
      )

      if (
        window.self == window.top &&
        window.sessionStorage.getItem("loginUrl")
      ) {
        var redirectUrl = window.sessionStorage.getItem("loginUrl");
        this.baseService.logInformation(
          "Redirecting to saved URL after AAD authentication",
          {
            details:
              "A redirect URL was saved before AAD authentication. Redirecting to " +
              redirectUrl
          }
        );
        window.location.href = redirectUrl;
        window.sessionStorage.removeItem("loginUrl");
      }
       
      this.fullName$.next(this.msalService.instance.getActiveAccount().username);

      // Load the menu item data into the @ngrx/store once the user is authenticated.
      // TODO: Move this to where the user is authenticated - robernst, 06/28/2017.
      this.store.dispatch(new AddMenuItemsAction());
    } else {
      // We want to capture any query parameters that would be lost on login - so store it to the session state, and restore it after authentication.
      window.sessionStorage.setItem("loginUrl", window.location.href);
      this.baseService.logInformation("Authenticating user via AAD.", {});
    }
  }

  public logOut(): void {
    this.baseService.logOut();
  }

  getIsUserAuthenticated(): boolean {
    return this.baseService.Authenticated;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
