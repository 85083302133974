import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from 'src/app/shared/services/base.service';
import { environment } from 'src/environments/environment';
import { CollectionPoint, CollectionPointWithDataState, CollectionPointWithSas } from '../models/collection-point.model';

@Injectable()
export class CollectionPointService {
    constructor(protected baseService: BaseService) { }

    getCollectionPointInfo(request: string): Observable<CollectionPointWithSas[]> {
        return this.baseService
            .get(`${environment.webApiConfig.serverUrl}api/collection-point/getCollectionPointSasUris/${request}`)
            .pipe(catchError(error => this.handleError(error, request)));
    }

    getCollectionPoints(request: string): Observable<CollectionPoint[]> {
        return this.baseService
            .get(`${environment.webApiConfig.serverUrl}api/collection-point/getcollectionpoints/${request}`)
            .pipe(catchError(error => this.baseService.handleError(error)));
    }

    getDataState(request: CollectionPoint[]) : Observable<CollectionPointWithDataState[]>{
        return this.baseService
            .post(`${environment.webApiConfig.serverUrl}api/collection-point/getCollectionPointsDataState/`, request)
            .pipe(catchError(error => this.baseService.handleError(error)));
    }

    handleError(error: HttpErrorResponse, requestId: string) {
        if (error.status === 404) {
            return this.baseService.handleCustomError(error, `CRM Request <b>${requestId}</b> was not found or request was not accessible due to security level.`);
        }
        else {
            return this.baseService.handleError(error);
        }
    }
}
