import { Action } from '@ngrx/store';
import { RequestStatusSummary } from 'src/app/models/request-status-summary.model';
import { Injectable } from '@angular/core';

export const ADD     = '[RequestStatusSummary] Add';
export const ADDED   = '[RequestStatusSummary] Added';
export const DELETE  = '[RequestStatusSummary] Delete';
export const DELETED  = '[RequestStatusSummary] Deleted';
export const REFRESH = '[RequestStatusSummary] Refresh';
export const REFRESHED = '[RequestStatusSummary] Refreshed';

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class AddAction implements Action {
  readonly type = ADD;

  // Trigger the HTTP request that is going to fetch the data from the backend.
  // Then call the Added action to dispatch that data to the store.
}

export class AddedAction implements Action {
  readonly type = ADDED;

  constructor(public payload: RequestStatusSummary[]) { }
}

export class DeleteAction implements Action {
  readonly type = DELETE;
}

export class DeletedAction implements Action {
  readonly type = DELETED
}

export class RefreshAction implements Action {
  readonly type = REFRESH;

}

export class RefreshedAction implements Action {
  readonly type = REFRESHED;

  constructor(public payload: RequestStatusSummary[]) { }
}

export type Actions
  = AddAction
  | AddedAction
  | DeleteAction
  | DeletedAction
  | RefreshAction
  | RefreshedAction;
