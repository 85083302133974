import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TorusErrorDialogData } from 'src/app/models/torus-error-dialog-data.model';
import { Subject, Observable } from 'rxjs';

@Component({
  selector: 'torus-error-dialog',
  templateUrl: './torus-error-dialog.component.html',
  styleUrls: ['./torus-error-dialog.component.scss']
})
export class TorusErrorDialogComponent {

  private torusRetryableError = new Subject<TorusErrorDialogData>();
  public torusRetryableError$ = this.torusRetryableError.asObservable();

  constructor(
    public dialogRef: MatDialogRef<TorusErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TorusErrorDialogData
    ) { }

  onNoClick(): void {
    this.dialogRef.close(this.data.retryable);
  }

  retryTorusSubmission(data: TorusErrorDialogData)
  {
    this.torusRetryableError.next(data);
  }

  flagUserCanceled() {
    this.dialogRef.close(this.data.retryable);
  }
}
