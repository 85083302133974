import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MicrosoftProperty } from '../../models/microsoft-property.model';
import { RequestType } from '../../models/request-type.model';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'

@Component({
  selector: 'type-selection',
  templateUrl: './type-selection.component.html',
  styleUrls: ['./type-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TypeSelectionComponent implements OnInit {
  @Input() parent;
  @Input() msProperties: MicrosoftProperty[];
  @Input()
  set msProperty(property: string) {
    this.requestTypesToDisplay(property);
  }

  @Output() selectionMade = new EventEmitter();

  dirty: boolean = false;
  form: FormControl;
  icons: RequestType[];
  
  constructor(
    private aiLogService: AiLoggingService
  ) {}

  ngOnInit() {
    this.form = new FormControl('', Validators.required);
    // if we were given a parent, let's attach to it.
    if (this.parent) {
      this.parent.addControl('requestType', this.form);
    }

    this.aiLogService.logEvent("Load TypeSelector component");  
  }

  requestTypesToDisplay(property: string) {;
    this.icons = [];
    // Handles edge case - they chose value C from A, B C,
    // went back and selected a property that only has Value A, C, so we want to reset this
    if (this.form) {
      this.form.setValue('');
    }
    if (property) {
      const reqTypes = this.msProperties
        .find(msProperty => msProperty.name === property)
        .requestTypes.forEach(reqType =>
          this.icons.push(RequestType.getIcon(reqType))
      );
    }
  }

  select(selection: string) {
    this.dirty = true;
    this.form.setValue(selection);
    this.selectionMade.emit();
  }

  selected(name: string) {
    return this.form.value === name;
  }
}
