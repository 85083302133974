import { MailboxItemType, ProcessingRegionEnum } from 'src/app/services/delivery-review.service';
import { PreservationRequest } from './preservation-request.model';

export enum EmailContentType {
  None,
  PartialHeaderOnly,
  FullHeaderOnly,
  FullMessageContent
}

export class DeliveryReview {
  countMessagesContainingEmailFilterAddress: Date;
  countOutputMailboxItemTypes: Record<MailboxItemType, number>;
  countTotalOutputBlobs: number;
  crmTicketNumber: string;
  outputCriminalPreservations: Array<PreservationRequest>;
  outputHasCalendarContent: boolean;
  outputHasContactContent: boolean;
  outputHasConversationHistoryContent: boolean;
  outputHasJournalContent: boolean;
  outputHasMailboxLoginContent: boolean;
  outputHasMessageContent: boolean;
  outputHasMismatchedEmailContentLevels: boolean;
  outputHasMobileDeviceContent: boolean;
  outputHasNotesContent: boolean;
  outputHasRegistrationContent: boolean;
  outputHasTasksContent: boolean;
  outputProcessingRegion: ProcessingRegionEnum;
  outputTimestampEarliest: Date;
  outputTimestampLatest: Date;
  requestedCalendarContent: boolean;
  requestedContactContent: boolean;
  requestedConversationHistoryContent: boolean;
  requestedEmailContentLevel: EmailContentType;
  requestedEmailFilter: boolean;
  requestedJournalContent: boolean;
  requestedMailboxLoginContent: boolean;
  requestedMessageContent: boolean;
  requestedMobileDeviceContent: boolean;
  requestedNotesContent: boolean;
  requestedProcessingRegion: ProcessingRegionEnum;
  requestedRegistrationContent: boolean;
  requestedSearchRegions: Array<ProcessingRegionEnum>;
  requestedTasksContent: boolean;
  requestedTimeStampEnd: Date;
  requestedTimestampStart: Date;
  searchId: number;
  validationContainerSizeInBytes: number;
  validationContainerUri: string;
  validationDateTime: Date;

  constructor(obj: Object){
    this.countMessagesContainingEmailFilterAddress = obj['CountMessagesContainingEmailFilterAddress'];
    this.countOutputMailboxItemTypes = obj['CountOutputMailboxItemTypes'];
    this.countTotalOutputBlobs = obj['CountTotalOutputBlobs'];
    this.crmTicketNumber = obj['CrmTicketNumber'];
    this.outputCriminalPreservations = obj['OutputCriminalPreservations'].map(val => new PreservationRequest(val)) as Array<PreservationRequest>;
    this.outputHasCalendarContent = obj['OutputHasCalendarContent'];
    this.outputHasContactContent = obj['OutputHasContactContent'];
    this.outputHasConversationHistoryContent = obj['OutputHasConversationHistoryContent'];
    this.outputHasJournalContent = obj['OutputHasJournalContent'];
    this.outputHasMailboxLoginContent = obj['OutputHasMailboxLoginContent'];
    this.outputHasMessageContent = obj['OutputHasMessageContent'];
    this.outputHasMismatchedEmailContentLevels = obj['OutputHasMismatchedEmailContentLevels'];
    this.outputHasMobileDeviceContent = obj['OutputHasMobileDeviceContent'];
    this.outputHasNotesContent = obj['OutputHasNotesContent'];
    this.outputHasRegistrationContent = obj['OutputHasRegistrationContent'];
    this.outputHasTasksContent = obj['OutputHasTasksContent'];
    this.outputProcessingRegion = obj['OutputProcessingRegion'];
    this.outputTimestampEarliest = obj['OutputTimestampEarliest'];
    this.outputTimestampLatest = obj['OutputTimestampLatest'];
    this.requestedCalendarContent = obj['RequestedCalendarContent'];
    this.requestedContactContent = obj['RequestedContactContent'];
    this.requestedConversationHistoryContent = obj['RequestedConversationHistoryContent'];
    this.requestedEmailContentLevel = obj['RequestedEmailContentLevel'];
    this.requestedEmailFilter = obj['RequestedEmailFilter'];
    this.requestedJournalContent = obj['RequestedJournalContent'];
    this.requestedMailboxLoginContent = obj['RequestedMailboxLoginContent'];
    this.requestedMessageContent = obj['RequestedMessageContent'];
    this.requestedMobileDeviceContent = obj['RequestedMobileDeviceContent'];
    this.requestedNotesContent = obj['RequestedNotesContent'];
    this.requestedProcessingRegion = obj['RequestedProcessingRegion'];
    this.requestedRegistrationContent = obj['RequestedRegistrationContent'];
    this.requestedSearchRegions = obj['RequestedSearchRegions'].map(val => ProcessingRegionEnum[val]) as Array<ProcessingRegionEnum>;
    this.requestedTasksContent = obj['RequestedTasksContent'];
    this.requestedTimeStampEnd = obj['RequestedTimestampEnd'];
    this.requestedTimestampStart = obj['RequestedTimestampStart'];
    this.searchId = obj['SearchId'];
    this.validationContainerSizeInBytes = obj['ValidationContainerSizeInBytes'];
    this.validationContainerUri = obj['ValidationContainerUri'];
    this.validationDateTime = obj['ValidationDateTime'];
  }
}
