import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as menuItem from 'src/app/actions/menu-item';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';



@Injectable()
export class MenuItemEffects {
  @Effect() menuItems$: Observable<Action> = this.actions$.pipe(
    ofType(menuItem.ADD),
    switchMap(() => this.menuItemService.getMenuItemData()),
    map(data => new menuItem.AddedAction(data)),
    catchError(() => of(new menuItem.AddedAction([])))
  );

  // We keep all HTTP requests located in the MenuItemService and inject it into this service so that
  // it may be called from elsewhere, too.
  constructor(
    private actions$: Actions,
    private menuItemService: MenuItemService
  ) {}
}
