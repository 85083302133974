import { Action } from '@ngrx/store';
import { MenuItem } from 'src/app/models/menu-item.model';

export const ADD    = '[MenuItem] Add';
export const ADDED  = '[MenuItem] Added';
export const DELETE = '[MenuItem] Delete';

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class AddAction implements Action {
  readonly type = ADD;

  // Trigger the HTTP request that is going to fetch the data from the backend.
  // Then call the Added action to dispatch that data to the store.
}

export class AddedAction implements Action {
  readonly type = ADDED;

  constructor(public payload: MenuItem[]) { }
}

export class DeleteAction implements Action {
  readonly type = DELETE;
}

export type Actions
  = AddAction
  | AddedAction
  | DeleteAction;
