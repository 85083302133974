import { ActionReducer, combineReducers } from '@ngrx/store';
import { compose, ActionReducerMap } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { createSelector } from 'reselect';
import * as fromRouter from '@ngrx/router-store';

import * as fromMenuItems from './menu-items';
import * as fromRequestStatusSummary from './request-status-summary';
import * as fromUiState from './uiState';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  menuItems: fromMenuItems.State;
  requestStatusItems: fromRequestStatusSummary.IRequestStatusState;
  router: fromRouter.RouterReducerState;
  uiState: fromUiState.State;
}

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
export const reducers: ActionReducerMap<State> =  {
  menuItems: fromMenuItems.reducer,
  requestStatusItems: fromRequestStatusSummary.reducer,
  router: fromRouter.routerReducer,
  uiState: fromUiState.reducer
};

export const getUiState = (state: State) => state.uiState;

export const getRequestStatusItemsState = (state: State) => state.requestStatusItems;
export const getRequestStatusItems = createSelector(getRequestStatusItemsState, fromRequestStatusSummary.getRequestStatusItems);

/**
 * Menu Items Reducers
 */
export const getMenuItemsState = (state: State) => state.menuItems;
export const getMenuItems = createSelector(getMenuItemsState, fromMenuItems.getMenuItems);