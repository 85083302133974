import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';

import { Capability } from '../../models/capability.model';
import {
  AtLeastOneBoxChecked,
  AtLeastOneInGroup
} from '../../validators/lens-request.validators';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'

@Component({
  selector: 'capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CapabilitiesComponent implements OnInit {
  @Input()
  set parent(parentForm: FormGroup) {
    this.form = parentForm;
  }
  @Input()
  set capabilities(capabilities: Capability[]) {
    this._capabilities = capabilities;
    this.updateFormPreSelectedCapability(capabilities);
  }
  @Input() nestedCapabilities;
  @Input() set selectedMsProperty(msProperty: string) {
    this._selectedMsProperty = msProperty;
  }

  @Input() set selectedRequestType(requestType: string) {
    this._selectedRequestType = requestType;
  }

  _selectedMsProperty: string;
  _selectedRequestType: string;
  form: FormGroup;
  _capabilities: Capability[];
  dirty: boolean = false;

  constructor(
    private aiLogService: AiLoggingService
  ) {
  }

  ngOnInit() {
    if (!this.form) {
      this.form = new FormGroup({}, AtLeastOneInGroup);
    }

    this.aiLogService.logEvent("Load Capabilities component");
  }

  addControl(key: string) {
    if (this.nestedCapabilities) {
      this.form.addControl(key, new FormArray([], AtLeastOneBoxChecked));
    } else {
      this.form.addControl(key, new FormArray([new FormControl(key)]));
    }
  }

  // Anytime a new set of capabilities is fed through, we "wipe" the existing controls for the
  // formerly selected msProperty. Then we populate as normal
  updateFormPreSelectedCapability(capabilities: Array<Capability>) {
    if (capabilities) {
      capabilities.map(capability => {
        if (capability.isSelected && !capability.isDisabled) {
          this.updateFormCapability(capability);
        }
      });
    }
  }

  capabilityExistsInForm(capabilityName: string): boolean {
    const selectedCapabilities = _.keys(this.form.value);
    return selectedCapabilities.indexOf(capabilityName) > -1;
  }

  isSelected(capability: Capability) {
    const keys = _.keys(this.form.value);
    return keys.indexOf(capability.serverName) > -1;
  }

  updateFormCapability(capability: Capability) {
    this.dirty = true;
    const key = capability.serverName;
    const capabilityInForm = this.capabilityExistsInForm(capability.serverName);
    if (!capabilityInForm) {
      this.addControl(key);
    } else {
      this.form.removeControl(key);
    }
  }

  updateFormCapabilityAll(selectAll: boolean) {
    if (selectAll) {
      this._capabilities.map(capability => {
        if (
          !capability.isDisabled &&
          !this.capabilityExistsInForm(capability.serverName)
        ) {
          this.addControl(capability.serverName);
        }
      });
    } else {
      this._capabilities.map(capability => {
        if (
          !capability.isDisabled &&
          this.capabilityExistsInForm(capability.serverName)
        ) {
          this.form.removeControl(capability.serverName);
        }
      });
    }
  }

  isDisabled(capability: Capability) {
    if (this.nestedCapabilities) {
      return capability.isDisabled;
    } else {
      if (this._selectedMsProperty && this._selectedMsProperty.toLowerCase() === 'exchange') {
        if (this._selectedRequestType && this._selectedRequestType.toLowerCase() === 'realtime') {
          switch (capability.serverName) {
            // If Full Content is selected, disabled subheaders
            case 'subHeaders': {
              const disabled =  this.form.get('fullContent') || this.form.get('drafts');
              return disabled;
            }
            case 'fullContent': {
              return this.form.get('subHeaders');
            }
            // If Subheaders/PRTT is selected, disable drafts
            case 'drafts': {
              return this.form.get('subHeaders');
            }
            default: {
              return capability.isDisabled;
            }
          }
        }
        else if (this._selectedRequestType &&
          (this._selectedRequestType.toLowerCase() === 'preservation' || this._selectedRequestType.toLowerCase() === 'historical')) {
          switch (capability.serverName) {
            case 'fullMessageContent': {
              const disabled =  this.form.get('fullHeaderOnly') || this.form.get('partialHeaderOnly');
              return disabled;
            }
            case 'fullHeaderOnly': {
              const disabled =  this.form.get('fullMessageContent') || this.form.get('partialHeaderOnly');
              return disabled;
            }
            // If Subheaders/PRTT is selected, disable drafts
            case 'partialHeaderOnly': {
              const disabled =  this.form.get('fullMessageContent') || this.form.get('fullHeaderOnly');
              return disabled;
            }
            default: {
              return capability.isDisabled;
            }
          }
        }
      }
    }
  }
}
