import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { IdentifierInfo } from '../../models/identifier-info.model';
import { AtLeastOneIdentifier, ContainsEmailIdentifier } from '../../validators/lens-request.validators';
import { AccountLookupService } from '../../../services/account-lookup.service';
import { AiLoggingService } from '../../../shared/services/ai-logging.service';
import { Router } from '@angular/router';
import { faArrowDown, faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { CustomerTenantTypeEnum } from 'src/app/services/delivery-review.service';
import { ConsumerEnterpriseOpts } from '../../models/consumer-enterprise-opts';
import { environment } from 'src/environments/environment';
import { emailIdentifier } from '../../validators/identifier.regex';
import { CustomerTenantType } from '../../models/new-request.model';

@Component({
  selector: "identifiers",
  templateUrl: './identifiers.component.html',
  styleUrls: ['./identifiers.component.scss']
})
export class IdentifiersComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input()
  set crmIdentifiers(identifiers: { [key: string]: Array<IdentifierInfo> }) {
    if (Object.keys(identifiers).length > 0) {
      this._crmIdentifiers = identifiers;
      this.loaded = true;
      this.keys = _.keys(identifiers);
      
      // if there are selected identifiers, let's push them to the form
      this.updateFormIdentifiers(identifiers);
    }
  }
  @Input()
  set requestType(_: string) {
    this._requestType = _;
    // since different request types are building the form array differently, 
    // we have to reset if the type changes
    this.updateFormIdentifiers(this._crmIdentifiers);
  }
  @Input() crmLoaded: boolean;
  @Input() crmLoading: boolean;
  @Input() consumerEnterpriseOptions: ConsumerEnterpriseOpts
  
  @Input()
  set selectedMsProperty(_: string) {
    this._msProperty = _;
  }

  @Input()
  set showConsumerEnterpriseOptions(_: boolean){
    this._showConsumerEnterpriseOptions = _;
  }
  get showConsumerEnterpriseOptions(): boolean{
    return this._showConsumerEnterpriseOptions;
  }

  form: FormGroup;
  defaultSeparateFileSelection: boolean;
  _crmIdentifiers: { [key: string]: Array<IdentifierInfo> };
  dirty: boolean = false;
  loaded: boolean = false;
  selectAll: { [key: string]: boolean } = {};
  selectAllConsumer: { [key: string]: boolean } = {};
  selectAllEnterprise: { [key: string]: boolean } = {};
  keys: string[];
  _requestType: string;
  _msProperty: string;
  _showConsumerEnterpriseOptions: boolean;
  faArrowDown: IconDefinition;
  faTimes: IconDefinition;

  // Lodash shortcuts used in template
  objectKeys = _.keys;
  isNull = _.isNull;

  get crmIdentifiersGroup() {
    let control = this.form.get('crmIdentifiers') as FormGroup;
    return control ? control : null;
  }

  get separateFilePerIdentifierControl(): FormControl {
    let control = this.form.get('separateFilePerIdentifier') as FormControl;
    return control ? control : null;
  }
  
  get consumerOptionsEnabled() {
    return  this.consumerEnterpriseOptions && this.consumerEnterpriseOptions.enableConsumer;
  }

  get enterpriseOptionsEnabled() {
    return this.consumerEnterpriseOptions && this.consumerEnterpriseOptions.enableEnterprise;
  }

  get mutuallyExclusiveOptionsEnabled() {
    return this.consumerEnterpriseOptions && this.consumerEnterpriseOptions.mutuallyExclusive;
  }

  get isExchange(): boolean {
    return (this._msProperty ? this._msProperty : '').toLowerCase() == 'exchange';
  }

  get isSkype(): boolean {
    return (this._msProperty ? this._msProperty : '').toLowerCase() == 'skype';
  }

  get isTeams(): boolean {
    return (this._msProperty ? this._msProperty : '').toLowerCase() == 'teams';
  }

  get identifierErrors() {
    const errors = this.form.controls['crmIdentifiers'].errors;
    return !errors ? {} : errors;
  }

  constructor
  (
    private accountLookupService: AccountLookupService,
    private router: Router,
    private aiLogService: AiLoggingService
  ) {
    this.faArrowDown = faArrowDown;
    this.faTimes = faTimes;
  }

  ngOnInit() {
    this.defaultSeparateFileSelection = false;
    this.form = new FormGroup({
      crmIdentifiers: new FormGroup({}, [AtLeastOneIdentifier]),  
      separateFilePerIdentifier: new FormControl(
        this.defaultSeparateFileSelection
      )
    });
    // if we're given a parent, let's attach our form to it.
    if (this.parent) {
      this.parent.addControl('identifiers', this.form);
    }

    this.aiLogService.logEvent('Load Identifiers component');
  }

  showSeparator() {
    if (this._msProperty){
      switch (this._msProperty.toLocaleLowerCase()) {
        case 'skype':
          // If it's Skype Europe, we want to hide it. Right now, that's inferred by Skype and Realtime
          if (this._requestType.toLocaleLowerCase() == 'realtime') {
            this.separateFilePerIdentifierControl.setValue(this.defaultSeparateFileSelection);
            return false;
          }
          else{
            return true;
          }
        case 'exchange':
          // If they selected Exchange Historical, we need to set the separateFilePerIdentifier to FALSE
          // and disable the option to select it.
          if (this._requestType.toLowerCase() === 'historical') {
            this.separateFilePerIdentifierControl.setValue(false);
            return false;
          } else {
            // If they selected any other type of Exchange request, we need to set the separateFilePerIdentifier to true
            // and disable the option to select it.
            this.separateFilePerIdentifierControl.setValue(true);
            return false;
          }
        default:
          // If they navigate away and decide to do anything else, we want to set this back to being enabled.
          return true;
      }
    }
    return true;
  }

  isSelected(identifierType, identifierValue: IdentifierInfo) {
    return (
      (this.crmIdentifiersGroup.get(identifierType) as FormArray).value.indexOf(
        identifierValue.id
      ) > -1
    );
  }

  isGroupSelected(identifierType): boolean {
    return this.selectAll[identifierType];
  }

  toggleSelectionForIdentifier(
    keyToUpdate: string,
    valueToUpdate: IdentifierInfo
  ) {
    if (this.identifierIsSelectable(valueToUpdate.id)) {
      if (!this.consumerEnterpriseOptions) {
        this.dirty = true;
        const arrayToUpdate: FormArray = this.crmIdentifiersGroup.get(
          keyToUpdate
        ) as FormArray;
        const indexToUpdate = arrayToUpdate.value.indexOf(valueToUpdate.id);

        if (indexToUpdate < 0) {
          arrayToUpdate.push(new FormControl(valueToUpdate.id));
          let selectableCount = _.countBy(this._crmIdentifiers[keyToUpdate], (id) => this.identifierIsSelectable(id.id));
          if (arrayToUpdate.length === selectableCount.true) {
            this.selectAll[keyToUpdate] = true;
          }
        } else {
          arrayToUpdate.removeAt(indexToUpdate);
          this.selectAll[keyToUpdate] = false;
        }
      }
    }
  }

  toggleSelectionForAllIdentifiers(keyToUpdate: string) {
    this.dirty = true;
    const identifiers = this._crmIdentifiers[keyToUpdate];
    const arrayToUpdate: FormArray = this.crmIdentifiersGroup.get(
      keyToUpdate
    ) as FormArray;

    let selectableCount = _.countBy(identifiers, (id) => this.identifierIsSelectable(id.id));

    if (selectableCount.true != arrayToUpdate.length) {
      identifiers.map(id => {
        if (!this.isSelected(keyToUpdate, id)) {
          if (this.identifierIsSelectable(id.id)) {
            arrayToUpdate.push(new FormControl(id.id));
          }
        }
        this.selectAll[keyToUpdate] = true;
      });
    } else {
      identifiers.map(id => {
        if (this.isSelected(keyToUpdate, id)) {
          let index = arrayToUpdate.value.indexOf(id.id);
          arrayToUpdate.removeAt(index);
        }
        this.selectAll[keyToUpdate] = false;
      });
    }
  }

  // pushes observed identifiers from the Input() into the form
  updateFormIdentifiers(identifiers: { [key: string]: Array<IdentifierInfo> }) {
    const keys = _.keys(identifiers);
    keys.forEach(key => {
      let arrayToUpdate = this.crmIdentifiersGroup.get(key) as FormArray;
      if (arrayToUpdate) {
        arrayToUpdate.clear();
      }
      // should really only happen on the first iteration of an identifierType
      if (!arrayToUpdate) {
        this.crmIdentifiersGroup.addControl(key, new FormArray([]));
        arrayToUpdate = this.crmIdentifiersGroup.get(key) as FormArray;
        this.selectAll[key] = false;
      }
      identifiers[key].map(idInfo => {
        if (this.identifierIsSelectable(idInfo.id))
        {
          // Only push to this array if the IdentifierInfo is already selected (it shouldn't be)
          if(idInfo.isSelected){
            if (this.consumerEnterpriseOptions) {
              arrayToUpdate.push(new FormGroup({ identifier: new FormControl(idInfo.id), customerTenantType: new FormControl(CustomerTenantTypeEnum.Consumer) }));
            } else {
              arrayToUpdate.push(new FormControl(idInfo.id))
            }
          }
        } else {
          idInfo.isConsumerSelected = false;
          idInfo.isEnterpriseSelected = false;
        }
      });
    });   
  }

  showAccountLookupButton(): boolean {
    return (
      this._msProperty.toLowerCase() === 'exchange'
    );
  }

  disableAccountLookupButton(identifierType): boolean {
    return (this.crmIdentifiersGroup.get(identifierType) as FormArray).length === 0;
  }

  sendAccountLookup(identifierType) {
    const unique = (value, index, self) => {
      return self.indexOf(value) === index
    }

    const proceed = confirm('Performing an account lookup will cancel this request.\r\n\r\nDo you wish to proceed?');
    if (proceed) {
      var crmRequestId = this.parent.get('crmIdentifier').value;
      const identifiersToLookup = this.consumerEnterpriseOptions 
        ? (this.crmIdentifiersGroup.get(identifierType) as FormArray).value
              .map(a => a.identifier)
              .filter(unique)
              .join('\r\n')
        : (this.crmIdentifiersGroup.get(identifierType) as FormArray).value
              .join('\r\n');

      this.accountLookupService.setParameters(crmRequestId, identifiersToLookup, this._msProperty);

      this.router.navigate(['account-lookup']);
    }
  }

  isConsumerGroupSelected(identifierType): boolean {
    let selectableCount = _.countBy(this._crmIdentifiers[identifierType], (id) => this.identifierIsSelectable(id.id, CustomerTenantTypeEnum.Consumer));
    let selectedCount = (this.consumerEnterpriseOptions.mutuallyExclusive)
      ? _.countBy(this._crmIdentifiers[identifierType], (id) => id.customerTenantType == CustomerTenantTypeEnum.Consumer)
      : _.countBy(this._crmIdentifiers[identifierType], (id) => id.isConsumerSelected);
    return selectedCount.true === selectableCount.true;
  }

  isEnterpriseGroupSelected(identifierType): boolean {
    let selectableCount = _.countBy(this._crmIdentifiers[identifierType], (id) => this.identifierIsSelectable(id.id, CustomerTenantTypeEnum.Enterprise));
    let selectedCount = (this.consumerEnterpriseOptions.mutuallyExclusive)
      ? _.countBy(this._crmIdentifiers[identifierType], (id) => id.customerTenantType == CustomerTenantTypeEnum.Enterprise)
      : _.countBy(this._crmIdentifiers[identifierType], (id) => id.isEnterpriseSelected);
    return selectedCount.true === selectableCount.true;
  }

  toggleDataSourceSelectionForIdentifier(identifierType, identifier: IdentifierInfo, customerTenantType: CustomerTenantTypeEnum) {
    if (this.identifierIsSelectable(identifier.id, customerTenantType)) {
      this.dirty = true;
      const arrayToUpdate: FormArray = this.crmIdentifiersGroup.get(identifierType) as FormArray;
      const item = this._crmIdentifiers[identifierType].find(x => x.id == identifier.id);
      
      if(this.consumerEnterpriseOptions.mutuallyExclusive){
        this.toggleRadioSelectionForIdentifier(identifierType, identifier, customerTenantType, arrayToUpdate, item);
      }
      else{
        this.toggleCheckboxSelectionForIdentifier(identifierType, identifier, customerTenantType, arrayToUpdate, item);
      }
    }
  }

  toggleRadioSelectionForIdentifier(identifierType, identifier: IdentifierInfo, customerTenantType: CustomerTenantTypeEnum,
    arrayToUpdate: FormArray, item: IdentifierInfo) {
    const indexToUpdate = _.findIndex(arrayToUpdate.value, function(x) { return x['identifier'] == item.id });

    if(indexToUpdate < 0){
      arrayToUpdate.push(new FormGroup({ identifier: new FormControl(item.id), customerTenantType: new FormControl(customerTenantType) }));
    }
    else{
      if(customerTenantType != CustomerTenantTypeEnum.Unspecified)
        arrayToUpdate.value[indexToUpdate].customerTenantType = customerTenantType;
      else
        arrayToUpdate.removeAt(indexToUpdate);
    }
  }

  toggleCheckboxSelectionForIdentifier(identifierType, identifier: IdentifierInfo, customerTenantType: CustomerTenantTypeEnum,
    arrayToUpdate: FormArray, item: IdentifierInfo) {
    const indexToUpdate = _.findIndex(arrayToUpdate.value, function(x) { return x['identifier'] == item.id && x['customerTenantType'] == customerTenantType });

    if (indexToUpdate < 0) {
      arrayToUpdate.push(new FormGroup({ identifier: new FormControl(item.id), customerTenantType: new FormControl(customerTenantType) }));
    } else {
      arrayToUpdate.removeAt(indexToUpdate);
    } 
  }

  toggleDataSourceSelectionForAllIdentifiers(keyToUpdate: string, customerTenantType: CustomerTenantTypeEnum) {
    this.dirty = true;
    const identifiers = this._crmIdentifiers[keyToUpdate];
    const arrayToUpdate: FormArray = this.crmIdentifiersGroup.get(keyToUpdate) as FormArray;
    const selectedCount = _.filter(arrayToUpdate.value, ['customerTenantType', customerTenantType]).length;

    let selectableCount = _.countBy(identifiers, (id) => this.identifierIsSelectable(id.id, customerTenantType));

    if(this.consumerEnterpriseOptions.mutuallyExclusive){
      this.toggleRadioSelectionForAllIdentifiers(keyToUpdate, customerTenantType, arrayToUpdate, identifiers);
    }
    else{
      this.toggleCheckboxSelectionForAllIdentifiers(keyToUpdate, customerTenantType, arrayToUpdate, identifiers);
    }
  }

  toggleCheckboxSelectionForAllIdentifiers(keyToUpdate: string, customerTenantType: CustomerTenantTypeEnum, 
    arrayToUpdate: FormArray, identifiers: IdentifierInfo[]) {
    const selectedCount = _.filter(arrayToUpdate.value, ['customerTenantType', customerTenantType]).length;

    let selectableCount = _.countBy(identifiers, (id) => this.identifierIsSelectable(id.id, customerTenantType));

    if (selectableCount.true != selectedCount) {
      identifiers.map(id => {
        if (this.identifierIsSelectable(id.id, customerTenantType)){
          id.customerTenantType = customerTenantType;
          if (customerTenantType == CustomerTenantTypeEnum.Consumer) { id.isConsumerSelected = true };
          if (customerTenantType == CustomerTenantTypeEnum.Enterprise) { id.isEnterpriseSelected = true };
          let index = _.findIndex(arrayToUpdate.value, function(x) { return x['identifier'] == id.id && x['customerTenantType'] == customerTenantType});
          if (index < 0) {
            arrayToUpdate.push(new FormGroup({ identifier: new FormControl(id.id), customerTenantType: new FormControl(customerTenantType) }));
          }
        }
      });
    } else {
      identifiers.map(id => {
        if (customerTenantType == CustomerTenantTypeEnum.Consumer) { id.isConsumerSelected = false };
        if (customerTenantType == CustomerTenantTypeEnum.Enterprise) { id.isEnterpriseSelected = false };
        let index = _.findIndex(arrayToUpdate.value, function(x) { return x['identifier'] == id.id && x['customerTenantType'] == customerTenantType});
        if (index >= 0) {
          arrayToUpdate.removeAt(index);
        }
      });
    }
  }

  toggleRadioSelectionForAllIdentifiers(keyToUpdate: string, customerTenantType: CustomerTenantTypeEnum, 
    arrayToUpdate: FormArray, identifiers: IdentifierInfo[]) {
      const selectedCount = _.filter(arrayToUpdate.value, ['customerTenantType', customerTenantType]).length;

      let selectableCount = _.countBy(identifiers, (id) => this.identifierIsSelectable(id.id, customerTenantType));

      if (selectableCount.true != selectedCount) {
        identifiers.map(id => {
          if (this.identifierIsSelectable(id.id, customerTenantType)){
            id.customerTenantType = customerTenantType;
            if (customerTenantType == CustomerTenantTypeEnum.Consumer) { id.isConsumerSelected = true } else {id.isConsumerSelected = false };
            if (customerTenantType == CustomerTenantTypeEnum.Enterprise) { id.isEnterpriseSelected = true } else {id.isEnterpriseSelected = false };
            if (customerTenantType == CustomerTenantTypeEnum.Unspecified) { id.isConsumerSelected = false; id.isEnterpriseSelected = false; }
            let index = _.findIndex(arrayToUpdate.value, function(x) { return x['identifier'] == id.id });
            if (index < 0) {
              arrayToUpdate.push(new FormGroup({ identifier: new FormControl(id.id), customerTenantType: new FormControl(customerTenantType) }));
            }
            else{
              arrayToUpdate.value[index].customerTenantType = customerTenantType;
            }
          }
        });
    }
    else{
      identifiers.map(id => {
        id.customerTenantType = CustomerTenantTypeEnum.Unspecified;
        id.isConsumerSelected = false;
        id.isEnterpriseSelected = false;
      });

      arrayToUpdate.clear();
    }
  }

  clearAllIdentifiers(keyToUpdate: string){
    const identifiers = this._crmIdentifiers[keyToUpdate];
    const arrayToUpdate: FormArray = this.crmIdentifiersGroup.get(keyToUpdate) as FormArray;

    identifiers.map(id => {
      id.customerTenantType = CustomerTenantTypeEnum.Unspecified;
      id.isConsumerSelected = false;
      id.isEnterpriseSelected = false;
    });

    arrayToUpdate.clear();
  }

  identifierIsSelectable(identifier: string, type: CustomerTenantTypeEnum = CustomerTenantTypeEnum.Unspecified) {
    if (this._msProperty.toLocaleLowerCase() == "exchange") {
      return emailIdentifier(identifier);
    }
    else if (this.isSkype || this.isTeams){
      if(type == CustomerTenantTypeEnum.Consumer)
        return !emailIdentifier(identifier);
    }

    return true;
  }

  disableSelectAllNoneButton(identifierType) {
    const identifiers = this._crmIdentifiers[identifierType];
    const selectableCount = _.countBy(identifiers, (id) => this.identifierIsSelectable(id.id));

    return selectableCount.true === undefined || selectableCount.true === 0;
  }
}
