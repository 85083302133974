import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { routes } from 'src/app/app.routes';
import { RequireAuthGuard } from 'src/app/authentication/require-auth.guard';
import { AppComponent } from 'src/app/containers/app/app.component';
import { MenuItemEffects } from 'src/app/effects/menu-item';
import { RequestStatusSummaryEffects } from 'src/app/effects/request-status-summary';
import { reducers } from 'src/app/reducers';
import { AccountLookupService } from 'src/app/services/account-lookup.service';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { RequestStatusSummaryService } from 'src/app/services/request-status-summary.service';
import 'hammerjs';
import { environment } from '../environments/environment';
import { AccountLookupComponent } from './components/account-lookup/account-lookup.component';
import { HomeComponent } from './components/home/home.component';
import { IpLookupDetailsComponent } from './components/ip-lookup-details/ip-lookup-details.component';
import { LayoutComponent } from './components/layout/layout.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { RequestStatusDetailsComponent } from './components/request-status-details/request-status-details.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { LensRequestModule } from './lens-request/lens-request.module';
import { IpLookupService } from './services/ip-lookup.service';
import { SharedModule } from './shared/shared.module';
import { RequestStatusService } from './services/request-status.service';
import { AiLoggingService } from "./shared/services/ai-logging.service";
import { AiLoggingInterceptor } from './shared/http/ai-logging-interceptor.class';
import { RequestStatusSummaryComponent } from './components/request-status-summary/request-status-summary.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { SkypeContentDetailsComponent } from './components/lens-request/components/skype-content-details/skype-content-details.component';
import { TorusErrorDialogComponent } from "./components/torus-error-dialog/torus-error-dialog.component";
import { TorusSubmissionService } from "./services/torus-submission.service";
import { TorusSubmittingDialogComponent } from "./components/torus-submitting-dialog/torus-submitting-dialog.component";
import { DeliveryReviewComponent } from './components/delivery-review/delivery-review.component';
import { DeliveryReviewService } from './services/delivery-review.service';
import { CollectionPointComponent } from './components/collection-point/collection-point.component';
import { CollectionPointService } from './services/collection-point.service';
import { PreservationReviewComponent } from './components/preservation-review/preservation-review.component';
import { PreservationReviewService } from './services/preservation-review.service';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalService, MsalModule, MSAL_INSTANCE, MsalBroadcastService, MsalRedirectComponent, MsalGuard, MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor} from '@azure/msal-angular';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureADConfig.applicationId,
      authority: "https://login.microsoftonline.com/organizations",
      redirectUri: window.location.protocol + "//" + window.location.host + "/home",
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
        loggerCallback: (level, message, containsPii) => {
          console.log(message);	
        }
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.baseUri, environment.scopes);
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AccountLookupComponent,
    HomeComponent,
    LayoutComponent,
    NavItemComponent,
    RequestStatusDetailsComponent,
    SidenavComponent,
    ToolbarComponent,
    IpLookupDetailsComponent,
    RequestStatusSummaryComponent,
    DialogComponent,
    SkypeContentDetailsComponent,
    TorusErrorDialogComponent,
    TorusSubmittingDialogComponent,
    DeliveryReviewComponent,
    PreservationReviewComponent,
    CollectionPointComponent
  ],
  imports: [
    HttpClientModule,
    MsalModule,
    LensRequestModule,
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    SharedModule.forRoot(),
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([MenuItemEffects, RequestStatusSummaryEffects]),
    !environment.production
      ? StoreDevtoolsModule.instrument({ maxAge: 50 })
      : []
  ],
  providers: [
    AiLoggingService,
    RequireAuthGuard,
    MenuItemService,
    RequestStatusSummaryService,
    AccountLookupService,
    TorusSubmissionService,
    IpLookupService,
    RequestStatusService,
    DeliveryReviewService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AiLoggingInterceptor,
      multi: true
    },
    PreservationReviewService,
    CollectionPointService,
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  entryComponents: [ IpLookupDetailsComponent, RequestStatusDetailsComponent, DialogComponent, TorusErrorDialogComponent, TorusSubmittingDialogComponent, SkypeContentDetailsComponent, DeliveryReviewComponent, PreservationReviewComponent ]
})
export class AppModule {
  constructor() {}
}
