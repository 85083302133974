<div mat-dialog-content id="details"> 
  <fa-icon
    class="box-icon"
    matDialogClose
    [icon]="faWindowClose"
    size="2x"
    pull="right"
    (click)="closeDialog()"
  ></fa-icon>
  <fa-icon
    class="box-icon"
    [icon]="faRedo"
    size="2x"
    pull="right"
    (click)="refresh()"
  ></fa-icon>
  <h2>ID: {{ reqStatusSummary.id }}</h2>
  <h4>Status: {{ reqStatusSummary.currentStatus }}</h4>
  <ng-container *ngIf="reqStatusSummary.requestStartDate && reqStatusSummary.requestEndDate">
    <h4>Requested Dates: {{reqStatusSummary.requestStartDate | date: 'shortDate':'UTC'}} {{reqStatusSummary.requestStartDate | date: 'shortTime':'UTC'}}  through {{reqStatusSummary.requestEndDate | date: 'shortDate':'UTC'}} {{reqStatusSummary.requestEndDate | date: 'shortTime':'UTC'}}</h4>
  </ng-container>
  <h4 *ngIf="!loading">
    <span>
      {{ identifiersComplete }} of {{ totalIdentifiers }} identifiers completed
      <progress
      class="description-item"
      id="progressBar"
      max="100"
      value="{{ getOverallProgress() }}"
    ></progress>
    </span>
  </h4>  
  <div *ngIf="loading">Loading Details ...</div>
  <mat-accordion *ngIf="!loading" >
    <!-- Parent Node - Identifiers -->
    <mat-expansion-panel
      *ngFor="let skypeContentDetail of skypeContentDetails">
      <mat-expansion-panel-header>        
        <mat-panel-title class="identifier-title-outer-container">
          <div class="identifier-title-inner-container">
            <div class="identifier-title-item" >
                Identifier {{skypeContentDetail.identifier}}
            </div>       
            <div class="parent-title-item">
              <div class="parent-animation-container">
                <div class="parent-animation-item">
                    <mat-progress-spinner
                    mode="indeterminate" 
                    *ngIf="resubmitting.get(skypeContentDetail.id) == true"
                    diameter="20">
                  </mat-progress-spinner>      
                  <fa-icon 
                    *ngIf="showFailure.get(skypeContentDetail.id) == true" 
                    [icon]="faTimesCircle" 
                    matTooltip="Unable to process request"
                    class="failure"></fa-icon>
                    <fa-icon 
                    *ngIf="showSuccess.get(skypeContentDetail.id) == true" 
                    [icon]="faCheckCircle" 
                    class="parent-title-item success"></fa-icon>
                </div>
              </div>            
            </div>  
          </div>
        </mat-panel-title>
        <mat-panel-description>
          <div class="identifer-description-container">
            <ng-container [ngSwitch]="skypeContentDetail.status">
              <div *ngSwitchCase="0" class="description-item">
                <mat-chip-list>
                  <mat-chip class="mat-chip-status">
                    Submitted
                  </mat-chip>                
                </mat-chip-list>
              </div>
              <div *ngSwitchCase="1" class="description-item">
                <mat-chip-list>
                  <mat-chip color='accent' selected class="mat-chip-status">
                    In Progress
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div *ngSwitchCase="2" class="description-item">
                <mat-chip-list>
                  <mat-chip color='primary' selected class="mat-chip-status">
                    Completed                        
                  </mat-chip>
                </mat-chip-list>
              </div>
              <div *ngSwitchCase="3" class="description-item">
                <mat-chip-list>
                  <mat-chip color='warn' selected class="mat-chip-status">
                    Error
                  </mat-chip>                  
                </mat-chip-list>
              </div>
              <div *ngSwitchCase="4" class="description-item">
                <mat-chip-list>
                  <mat-chip color='warn' selected class="mat-chip-status">
                    Partially Completed                        
                  </mat-chip>  
                </mat-chip-list>
              </div>
              <div *ngSwitchCase="5" class="description-item">
                <mat-chip-list>
                  <mat-chip color='warn' selected class="mat-chip-status">
                    Expired                        
                  </mat-chip>                   
                </mat-chip-list>
              </div>
              <div *ngSwitchCase="5" class="description-item">
                <mat-chip-list>
                  <mat-chip color='warn' selected class="mat-chip-status">
                    Cancelled                     
                  </mat-chip>                   
                </mat-chip-list>
              </div>
            </ng-container>   
            <div class="description-item">
              <progress            
              class="description-item"
              id="progressBar"
              max="100"
              value="{{ getDetailProgress(skypeContentDetail) }}"
              ></progress>
            </div>      
            <div class="description-item">
              Last Updated:
              {{ skypeContentDetail.lastUpdated | date: 'medium' }}
            </div>        
            <div class="description-item">
              <button 
              *ngIf="skypeContentDetail.status == (3 || 4)"
              mat-raised-button color="accent" 
              matTooltip="Resubmit this identifier"
              (click)="resubmit(skypeContentDetail)">Resubmit
              </button>
            </div>        
            <div class="description-item">
              <ng-container *ngIf="skypeContentDetail.statusMessage">
                <mat-chip 
                  color='accent' 
                  matTooltip="{{skypeContentDetail.statusMessage}}"
                  (click) = "copyDetails(skypeContentDetail.statusMessage)"
                  selected>
                  Copy details                     
                </mat-chip>     
              </ng-container>
            </div>    
          </div>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Nested Node - Conversations -->
      <ng-template matExpansionPanelContent>
        <mat-expansion-panel      
          *ngFor="let skypeConversation of skypeContentDetail.skypeConversations; index as i;">
          <mat-expansion-panel-header>
            <mat-panel-title class="conversation-title-outer-container">
              <div class="conversation-title-inner-container">
                <div class="identifier-title-item">
                  Conversation {{i + 1}}
                </div>                    
              </div>          
            </mat-panel-title>
            <mat-panel-description>
              <div class="conversation-description-container">
                <div class="description-item">
                  <!-- space -->
                </div>
                <ng-container [ngSwitch]="skypeConversation.status">
                  <div *ngSwitchCase="0" class="description-item">
                    Submitted
                  </div>
                  <div *ngSwitchCase="1" class="description-item">
                    <mat-chip color='accent' selected>
                      In Progress
                    </mat-chip>
                  </div>
                  <div *ngSwitchCase="2" class="description-item">
                      <mat-chip color='primary' selected>
                        Completed                        
                      </mat-chip>
                  </div>
                  <div *ngSwitchCase="3" class="description-item">
                      <mat-chip color='warn' selected>
                        Error
                      </mat-chip>                  
                  </div>
                  <div *ngSwitchCase="4" class="description-item">
                      <mat-chip color='warn' selected>
                        Partially Completed                        
                      </mat-chip>  
                  </div>
                  <div *ngSwitchCase="5" class="description-item">
                      <mat-chip color='warn' selected>
                          Expired                        
                      </mat-chip>                   
                  </div>
                  <div *ngSwitchCase="5" class="description-item">
                      <mat-chip color='warn' selected>
                          Cancelled                     
                      </mat-chip>                   
                  </div>
                </ng-container>     
                <div class="description-item">
                  <mat-chip-list >
                    <div class="custom-mat-chip-list-wrapper">
                      <mat-chip [color]="'primary'" selected matTooltip="Messages downloaded">
                        <div class="inline">
                          {{ skypeConversation.messageCount }}
                        </div>
                        <div class="inline">
                          <fa-icon [icon]="faCommentDots"></fa-icon>
                        </div>
                      </mat-chip>
                    <mat-chip [color]="'primary'" selected matTooltip="Attachments downloaded"> 
                      <div class="inline">
                        {{ skypeConversation.attachmentsCount }}
                      </div>
                      <div class="inline" >
                        <fa-icon [icon]="faPaperclip"></fa-icon>
                      </div>
                    </mat-chip>
                  </div>
                  </mat-chip-list>  
                </div>            
                <div class="description-item-small-space">
                    <!-- space -->
                  </div>    
                <div class="description-item"  matTooltip="Last time this conversation was updated.">
                    <div>
                      Last Conversation Update:
                    </div>
                    <div>
                      {{ skypeConversation.lastUpdated | date: 'medium' }}
                    </div>
                </div>    
                <div class="description-item">
                  <ng-container *ngIf="skypeConversation.statusMessage">
                    <mat-chip 
                      color='accent' 
                      matTooltip="{{skypeConversation.statusMessage}}"
                      (click) = "copyDetails(skypeConversation.statusMessage)"
                      selected>
                      Copy details                     
                    </mat-chip>     
                  </ng-container>
                </div>    
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>    
          <ng-template matExpansionPanelContent>
            <div>
              Conversation with
              <b>
                {{skypeConversation.skypeConversationId}}
              </b>
            </div>
            <div>
              <div class="inline">
                Conversation collection started {{ skypeConversation.jobStartTime | date:'medium' }}
              </div>
              <div class="inline" *ngIf="skypeConversation.jobEndTime">
                  and ended {{ skypeConversation.jobEndTime | date:'medium' }}
              </div>
            </div>
            <div *ngIf="skypeConversation.messagesStartTime">
              <div class="inline">
                Message collection started {{ skypeConversation.messagesStartTime | date:'medium' }}
              </div>
              <div class="inline" *ngIf="skypeConversation.messagesEndTime">
                  and ended {{ skypeConversation.messagesEndTime | date:'medium' }}
              </div>
              <div class="description-item"  matTooltip="Timestamp of the last message downloaded. This works backwards from your end date towards your start date.">
                  Last Message Timestamp:
                  {{ skypeConversation.lastMessageTime | date: 'medium' }}
              </div>                
            </div>
            <div *ngIf="skypeConversation.attachmentsDownloadStart">
              <div class="inline">
                Attachment collection started {{ skypeConversation.attachmentsDownloadStart | date:'medium' }}
              </div>
              <div class="inline" *ngIf="skypeConversation.attachmentsDownloadEnd">
                  and ended {{ skypeConversation.attachmentsDownloadEnd | date:'medium' }}
              </div>
            </div>
          </ng-template>   
        </mat-expansion-panel>
        <!-- End Nested Node - Conversations -->
    </ng-template>
    </mat-expansion-panel>
    <!-- End Parent Node - Identifiers -->
  </mat-accordion>
</div>
