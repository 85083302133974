export class DataField {
  serverName: string;
  displayName: string;
  isSelected: boolean;
  isDisabled: boolean
  type: string;

  constructor(obj: Object, typeToKeyBy: string){
    this.serverName = obj['FieldName'];
    this.displayName = obj['DisplayName'];
    this.isSelected = obj['IsSelected'];
    this.isDisabled = obj['IsDisabled'];
    this.type = typeToKeyBy;
  }

  static fromJSONArray(array: Array<DataField>, type: string) : Array<DataField> {    
    return  array.map(obj => new DataField(obj, type));
  }
}
