
import {pluck, distinctUntilChanged,  catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { BehaviorSubject ,  Observable } from 'rxjs';

import { BaseService } from '../../shared/services/base.service';
import { IdentifierInfo } from '../models/identifier-info.model';
import { CustomerTenantTypeEnum } from 'src/app/services/delivery-review.service';

export enum StateSelectors {
  CRMIDENTIFIERS = "crmIdentifiers",
  LOADING = "loading",
  LOADED = 'loaded'
}

export interface State {
  crmIdentifiers: { [key: string]: Array<IdentifierInfo>};
  loading: boolean;
  loaded: boolean;
}

const state: State = {
  crmIdentifiers: {},
  loading: false,
  loaded: false
};

@Injectable()
export class CrmIdentifierService {
  private subject = new BehaviorSubject<State>(state);
  store = this.subject
    .asObservable().pipe(
    distinctUntilChanged())

  constructor(protected baseService: BaseService) {
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  public getCrmRequestData(crmRequestId: string): Observable<any> {
    // Set loading to true    
    const values = this.subject.value;
    this.subject.next({
      ...values,
      loading: true,
    });     

    return this.baseService
      .get(
        environment.webApiConfig.serverUrl +
        environment.crmRequestUrl + "/" + crmRequestId
      )
      .pipe(            
        // set results as the crmIdentifers state
        tap(response => {
          
          // we cycle through so each "Type" becomes the key, and the values are the identifiers
          let loadedIdentifiers = _.reduce(response, (accumulator, iteratee) => {
            (accumulator[iteratee.Type] || (accumulator[iteratee.Type] = [])).push(new IdentifierInfo(iteratee.Value, false, false, false, CustomerTenantTypeEnum.Unspecified)); // NO identifiers are selected by default
            return accumulator;
          }, {});    
          
          // Then we SET this new set key/value to the crmIdentifiers. This does not merge. 
          //TODO: Refactor this to let the user load additional requests
          const values = this.subject.value;
          this.subject.next({
            ...values,
            crmIdentifiers: loadedIdentifiers,
            loading: false,
            loaded: true            
          });
        }),        
        catchError(error => this.baseService.handleError(error))
      );
  }
}
