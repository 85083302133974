import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Status } from '../../services/request-submitter.service';
import { AiLoggingService } from '../../../shared/services/ai-logging.service'

@Component({
  selector: 'request-results',
  templateUrl: './request-results.component.html',
  styleUrls: ['./request-results.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RequestResultsComponent {
  @Input() crmTicketNumber: string;
  @Input() submissionStatus: Observable<Status>;
  @Output() redirectUser = new EventEmitter();

  constructor(
    private aiLogService: AiLoggingService
  ) {
  }

  redirect() {
    this.redirectUser.emit();
  }

  refreshPage() {
      location.reload();
  }
}
