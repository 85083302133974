
import {pluck, distinctUntilChanged,  catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { environment } from '../../../environments/environment';
import { BaseService } from '../../shared/services/base.service';
import { NewRequest } from '../models/new-request.model';
import { ModifyRequest } from 'src/app/models/modify-request.model';
import { ResubmitModel } from '../models/resubmit.model';

export enum StateSelectors {
  NEWREQUESTDATA = 'newRequestData',
  STATUS = 'status'
}

export enum Status {
  Draft,
  Submitting,
  Submitted
}

export interface State {
  newRequestData: NewRequest;
  status: Status;
}

export const state: State = {
  newRequestData: new NewRequest(),
  status: Status.Draft
};

@Injectable()
export class RequestSubmitterService {

  subject = new BehaviorSubject<State>(state);
  store = this.subject.asObservable().pipe(distinctUntilChanged());

  constructor(private baseService: BaseService) {}

  public select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  public postNewRequestModel(newRequest: NewRequest, endpoint: string): Observable<any> {
    this.updateStatus(Status.Submitting);
    return this.baseService
      .post(
        environment.webApiConfig.serverUrl + endpoint,
        newRequest
      )
      .pipe(
        map(response => {
          this.updateStatus(Status.Submitted);
          return response || [];
        }),
        catchError(error => this.baseService.handleError(error))
      );
  }

  public postResubmitRequest(resubmitModel: ResubmitModel): Observable<any>{
    return this.baseService
    .post(environment.webApiConfig.serverUrl + 'api/modify-request/resubmit', resubmitModel)    

  }

  extendRequest(modifyRequest: ModifyRequest): Observable<any> {
    this.updateStatus(Status.Submitting);
    return this.baseService
      .post(
        environment.webApiConfig.serverUrl + environment.extendRequestUrl,
        modifyRequest
      )
      .pipe(
        map(response => {
          this.updateStatus(Status.Submitted);
          return response || [];
        }),
        catchError(error => this.baseService.handleError(error))
      );
  }

  unsubscribeRequest(modifyRequest: ModifyRequest):  Observable<any> {
    this.updateStatus(Status.Submitting);
    return this.baseService
      .post(
        environment.webApiConfig.serverUrl + environment.unsubscribeRequestUrl,
        modifyRequest
      )
      .pipe(
        map(response => {
          this.updateStatus(Status.Submitted);
          return response || [];
        }),
        catchError(error => this.baseService.handleError(error))
      );
  }

  submitTorusFailure(id: string, wasCanceled: boolean, message: string) {
    const body = {
        wasCanceled: wasCanceled,
        message: message
    };

    return this.baseService
        .post(environment.webApiConfig.serverUrl + environment.torusFailureUrl + '/' + id, body)
        .pipe(catchError(error => this.baseService.handleError(error)));
  }

  updateStatus(status: Status) {
    const values = this.subject.value;
    this.subject.next({
      ...values,
      status: status
    });
  }
}
